import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import image2 from "../../assest/images/signup.png";
import image1 from "../../assest/images//thank-you.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Input from "../Input";
import { UserSignUpToNewsletter } from "../../api";

const initialNewsLetterForm = {
  name: "",
  email: "",
  city: "",
  drink: "",
  establishment: "",
};
const SignUpModal = ({ isOpen, onRequestClose, cities }) => {
  const [formValue, setFormValue] = useState(initialNewsLetterForm);
  const [city, setCity] = useState(cities);
  const [error, setError] = useState("");

  useEffect(() => {
    const sortedOptions = [...cities].sort((a, b) =>
      a.label.localeCompare(b.label)
    );
    setCity(sortedOptions);
  }, [cities]);
  const formFields = [
    {
      name: "name",
      label: "Name",
      placeholder: "Enter your name",
    },
    {
      name: "email",
      label: "Email",
      placeholder: "drinks@gmail.com",
    },
    {
      name: "city",
      label: "What city would you like offers from",
      placeholder: "Enter your city name",
    },
    {
      name: "drink",
      label: "Favorite Drink",
      placeholder: "Jack and Cokee",
    },
    {
      name: "establishment",
      label: "Favorite Establishment",
      placeholder: "Fork Boise",
    },
  ];

  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [selectedCity, setSelectedCity] = React.useState("");

  const [showThankYou, setShowThankYou] = useState(false);
  const [showAnimation, setShowAnimation] = useState(false);

  useEffect(() => {
    if (showThankYou) {
      const timer = setTimeout(() => {
        setShowAnimation(true);
      }, 500); // Adjust the delay as needed
      return () => clearTimeout(timer);
    }
  }, [showThankYou]);

  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setError("");
    setFormValue({ ...formValue, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      Name: formValue.name,
      Email: formValue.email,
      City: selectedCity,
      ["Favorite Drink"]: formValue.drink,
      ["Favorite Establishment"]: formValue.establishment,
    };
    // Validate form fields
    const isValid = Object.values(formData).every(
      (value) => value.trim() !== ""
    );
    if (!isValid) {
      setError("Please fill in all fields.");
      return;
    }

    // If the selected city is empty, also set an error
    if (!selectedCity) {
      setError("Please select a city.");
      return;
    }

    try {
      const response = await UserSignUpToNewsletter(formData);
      if (response.status === 200) {
        setError("");
        setFormValue(initialNewsLetterForm);
        setSelectedCity("");
        setShowThankYou(true);
        // onRequestClose();
      }
    } catch (error) {
      // Handle errors, e.g., show an error message
      setError("Failed to submit your request");
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="signUpModalOverlay"
      className="signUpModal bg-darkgreen min-h-[650px] max-h-[800px] rounded-xl max-w-[1085.78px] w-full mx-auto h-[95vh] !p-0"
    >
      {showThankYou ? (
        <div
          className={`flex justify-center items-center h-full fade-in ${
            showAnimation ? "show" : ""
          }`}
        >
          <div className="flex flex-col justify-center items-center gap-y-10">
            <h2 className="text-5xl font-bold leading-[45.12px] text-white-100 text-center">
              Welcome to Happier Happy Hours!
            </h2>
            <p className="text-center text-xl leading-8 font-normal text-white-100 mb-0 max-w-[371px] mx-auto">
              <strong>You're in!</strong> Get ready for exclusive offers and
              insider tips to elevate your happy hour experience.
            </p>
            <button
              onClick={() => {
                onRequestClose();
                setShowThankYou(false);
                setFormValue(initialNewsLetterForm)
                setSelectedCity('')
              }}
              type="button"
              className="px-6 h-[56px] bg-transparent border rounded-full text-lg  leading-10 font-bold max-w-[282.02px] ms-14 flex items-center justify-center bg-white-100 text-bg-main transition-all"
            >
              CLOSE & CHEERS
            </button>
          </div>
          <img
            src={image1}
            alt="alt"
            className="min-h-[650px] h-full max-h-full relative top-0 bottom-0 -right-2"
          />
        </div>
      ) : (
        <div className="flex justify-between h-full">
          <div className="content pt-10 text-white w-full">
            <h2 className="text-5xl font-bold leading-[45.12px] text-white text-center">
              Sip and Save
            </h2>
            <p className="py-2 text-center text-sm font-normal">
              Your Ticket to the Best Happy Hour Deals!
            </p>
            {error && (
              <p className="text-center text-lg tracking-wide font-semibold text-red-800 mb-0">
                {error}
              </p>
            )}
            <form
              onSubmit={handleSubmit}
              className="py-5 flex gap-y-6 flex-col justify-center items-center"
            >
              {formFields.map((field, index) => (
                <div className="flex gap-4 items-center" key={field.name}>
                  <div className="w-11 h-11 rounded-full bg-main-orange flex items-center justify-center text-bg-main font-semibold text-base">
                    {index + 1}
                  </div>
                  <div className="flex flex-col gap-2">
                    <label htmlFor={field.name}>{field.label}</label>
                    {field.name === "city" ? (
                      <div className="relative">
                        <div
                          onClick={() => setDropdownOpen(!dropdownOpen)}
                          className={`cursor-pointer bg-transparent border-white border rounded-full h-14 w-72 pl-5 flex items-center ${
                            selectedCity ? "active-input" : ""
                          }`}
                        >
                          {selectedCity || "Select City"}
                        </div>
                        {dropdownOpen && (
                          <ul className="absolute left-0 mt-2 w-full bg-darkgreen border rounded-2xl shadow-lg py-3 ">
                            {city?.map((option, index) => (
                              <li
                                key={index}
                                onClick={() => {
                                  setSelectedCity(option.label);
                                  setDropdownOpen(false);
                                }}
                                className="cursor-pointer px-4 py-2 hover:text-main-orange"
                              >
                                {option.label}
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    ) : (
                      <Input
                        placeholder={field.placeholder}
                        name={field.name}
                        className={`focus:bg-bg-main ${
                          formValue[field.name] === "" ? " " : "active-input"
                        }`}
                        handleInputChange={handleInputChange}
                        value={formValue[field.name]}
                      />
                    )}
                  </div>
                </div>
              ))}

              <button
                type="submit"
                className="px-6 h-[56px] bg-transparent border rounded-full text-white text-lg  leading-10 font-bold min-w-[282.02px] ms-14 flex items-center justify-center hover:bg-white hover:text-bg-main transition-all"
              >
                SIGN UP
              </button>
            </form>
          </div>
          <div className=" min-w-[512px] h-full">
            <button
              className="w-[36.25px] h-[36.25px] rounded-full border border-white font-thin text-white flex items-center justify-center bg-bg-main absolute right-3  top-4 z-[999]"
              onClick={onRequestClose}
            >
              <FontAwesomeIcon className="shadow-inner" icon={faTimes} />
            </button>
            <img
              src={image2}
              alt="alt"
              className="min-h-[650px] h-full max-h-full relative top-0 bottom-0 -right-2"
            />
          </div>
        </div>
      )}
    </Modal>
  );
};

export default SignUpModal;
