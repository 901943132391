import axios from "axios";
import { config } from "..";
import { baseId, tableIds } from "../../constant";

const apiStateUrl = `https://api.airtable.com/v0/${baseId}/${tableIds.stateTableId}`;

// Fetch states
export const fetchStateData = async () => {
    try {
      const response = await axios.get(apiStateUrl, config);
      const states = response.data.records;
      return states.map((state) => ({
        ...state,
        value: state.fields.State,
        label: state.fields.State,
        id: state.id,
      }));
    } catch (error) {
      console.error("Error fetching city data from Airtable:", error);
    }
  };
  