import oneicon from "./assest/icons/one_icon.svg";
import twoicon from "./assest/icons/two_icon.svg";
import threeicon from "./assest/icons/three_icon.svg";
import fouricon from "./assest/icons/four_icon.svg";
import fiveicon from "./assest/icons/fiveicon.svg";
import naicon from "./assest/icons/na_icon.svg";
import voidicon from "./assest/icons/void.svg";
import dullicon from "./assest/icons/dull.svg";
import steadyicon from "./assest/icons/steady.svg";
import vibranticon from "./assest/icons/vibrant.svg";
import blazeicon from "./assest/icons/blaze.svg";
import mysteryicon from "./assest/icons/mystery.svg";

export const baseId = "applhd5k59uM4LjFB";
// export const tableIds = {
//   cityTableId: "tblA7xF0IB4w7AZZB",
//   promotionsTableId: "tblkVFnDVOWCfkwaS",
//   establishmentsTableId: "tblANhhc5m3rN3jwM",
//   stateTableId: "tblqr5b1uQzrbTWVB",
//   happyHoursId: "tblYF53vrLDOCmGAE",
//   requestedCitiesId: "tblKVhr2WSlbPF2Kc",
//   userSignUpTableId: "tblMGcsm1eYYfp3Al",
//   establishmentUserId: "tbl8w1Q0RsclAtGFi",
//   streetTableId: "tbl5Whv20NPkIpxJS",
//   feedbackTableId: "tblIijZKIlNFj5f1y",
//   claimListingTableId: "tblNgUwjXvjXveA3Q",
//   usersTableId: "tblzbsd48tlchSHBA",
//   supportTableId:"tblofsj8Jr1KbmYsX"
// }
export const tableIds = {
  cityTableId: "City",
  promotionsTableId: "Promotions",
  establishmentsTableId: "Establishments",
  stateTableId: "States",
  happyHoursId: "Happy Hours",
  requestedCitiesId: "Requested Cities",
  userSignUpTableId: "tblMGcsm1eYYfp3Al",
  establishmentUserId: "tbl8w1Q0RsclAtGFi",
  streetTableId: "Streets",
  feedbackTableId: "tblIijZKIlNFj5f1y",
  claimListingTableId: "tblNgUwjXvjXveA3Q",
  usersTableId: "tblzbsd48tlchSHBA",
  supportTableId: "tblofsj8Jr1KbmYsX",
  ratingTableId:'Rating',
  pollTableId:'Poll',
}



export const ratingConfig = {
  1: {
    ratingIcon: oneicon,
    ratingFunIcon: voidicon,
    mainText: "Void",
  },
  2: {
    ratingIcon: twoicon,
    ratingFunIcon: dullicon,
    mainText: "Dull",
  },
  3: {
    ratingIcon: threeicon,
    ratingFunIcon: steadyicon,
    mainText: "Steady",
  },
  4: {
    ratingIcon: fouricon,
    ratingFunIcon: vibranticon,
    mainText: "Vibrant",
  },
  5: {
    ratingIcon: fiveicon,
    ratingFunIcon: blazeicon,
    mainText: "Blaze",
  },
  6: {
    ratingIcon: naicon,
    ratingFunIcon: mysteryicon,
    mainText: "Mystery",
  },
};