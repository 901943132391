import { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import ImageContainer from "../components/ImageContainer";
import feedbackImg from "../assest/images/feedback.png";
import feedbackImgsmall from "../assest/images/feedback-small.png";
import Input from "../components/Input";
import arrowIcon from "../assest/icons/Cheveron.svg";
import {
  FetchRequestCity,
  PostFeedback,
  fetchCityData,
  fetchStateData,
  requestCity,
} from "../api";
import Layout from "../components/Layout";

function Feedback() {
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");;
  const [error, setError] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(false)
  const [formValue, setFormValue] = useState("");

  const handleInputChange = (event) => {
    const value = event.target.value;
    setFormValue(value);
  };

  const LoadCitiesData = async () => {
    try {
      const data = await fetchCityData();
      const transformedData = data.map((record) => ({
        ...record,
        Promotions: record.fields?.Promotions,
        Establishments: record.fields?.Establishments,
      }));

      const defaultCity = data.find(
        (record) => record.fields?.Name === "Boise"
      );

      if (defaultCity) {
        setSelectedCity({
          ...defaultCity,
          label: defaultCity.fields.Name,
          value: defaultCity.fields.Name,
          Establishments: defaultCity.fields.Establishments,
          Promotions: defaultCity.fields.Promotions,
        });
      }

      setCities(transformedData);
    } catch (error) {
      console.error("Error fetching cities:", error);
      setError("Failed to post feedback please try again");
    }
  };

  useEffect(() => {
    LoadCitiesData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    try {
      const formData = { Feedback: formValue }

      const response = await PostFeedback(formData);
      if (response.status === 200) {
        setError("");
        setShowSuccess(true);
        setFormValue("");
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      // Handle errors, e.g., show an error message
      setError("Failed to submit your request");
    }
  };

  return (
    <>
      <Layout>

        <div className="bg-bg-time md:bg-darkgreen rounded-[15px] mx-[15px]">
          <div className="max-w-[1360px] mt-[41px] mb-5 py-[78px] mx-auto min-h-screen h-full">
            <h1 className="text-white text-center font-extrabold text-lg lg:text-[80px] leading-8 lg:leading-[72px]  lg:tracking-tighter header-mob uppercase">
              Feedback
            </h1>

            <div className="flex flex-col-reverse md:flex-row justify-center gap-x-14 w-full pt-12 items-center h-full">
              {!showSuccess ? (
                <div className="lg:pt-8 w-full lg:w-[unset]">
                  <p className="hidden md:block font-medium text-sm lg:text-2xl text-center mb-4 text-white  max-w-[400px] mx-auto">
                    We would love to hear from you!
                  </p>
                  <p className="font-medium text-2xl text-center mb-4 text-red max-w-[400px] mx-auto">
                    {error}
                  </p>
                  <form
                    action=""
                    onSubmit={handleSubmit}
                    className="w-full mx-auto lg:max-w-[500px] py-5 flex gap-y-6 flex-col justify-center items-center text-white px-4"
                  >
                    <div className="w-full">
                      <label htmlFor="feedback" className="mb-4 block text-sm font-semibold md:hidden">Let Us Know!</label>
                      <textarea
                        id="feedback"
                        className="bg-white md:bg-transparent focus:outline-transparent text-black md:text-white border border-white rounded-3xl w-full max-w-[480px] mx-auto px-8 py-5"
                        rows={10}
                        placeholder="How can we improve the app? what offers would you like to see? or did something not work for you? please let us know"
                        onChange={handleInputChange}
                      />
                    </div>

                    <button
                      type="submit"
                      className="px-6 h-[56px] md:bg-transparent  rounded-full text-bg-main md:text-white text-lg leading-10 font-bold w-full lg:max-w-96 flex items-center justify-center hover:bg-white hover:text-bg-main transition-all max-w-full bg-main-orange-50 md:border"
                    >
                      {loading ? (
                        <div role="status">
                          <svg
                            aria-hidden="true"
                            class="inline w-8 h-8 text-gray-200 animate-spin dark:text-white-100 fill-main-orange-50"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                          <span className="ml-2">posting...</span>
                        </div>
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </form>
                </div>
              ) : (
                <div>
                  <h6 className="text-lg lg:text-2xl text-[#fff] leading-9 text-center my-6">Thank you for<br className="hidden lg:block" /> your feedback!</h6>
                </div>
              )}

              <div>
                <p className={`italic md:hidden font-medium text-sm lg:text-2xl text-center mb-4 text-white px-10 max-w-[400px] mx-auto ${showSuccess ? 'hidden' : ''}`}>
                  We would love to hear from you!
                </p>
                <img
                  src={feedbackImg}
                  alt="image"
                  className="object-contain w-fit h-96 hidden lg:block"
                />
                <img
                  src={feedbackImgsmall}
                  alt="image"
                  className="object-contain w-fit max-w-52 mx-auto lg:hidden"
                />
                {/* {showSuccess && <h6 className="text-2xl text-[#fff] leading-9 text-center mb-4">Thank you for<br /> your feedback!</h6>} */}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
export default Feedback;
