import axios from "axios";
import { useEffect, useState } from "react";
import { baseId, tableIds } from "../constant";
import { ratingConfig } from "../constant";
import { apiKey } from "../api";

export const useFetchRating = (name) => {
  const [currentRatingConfig, setCurrentRatingConfig] = useState(ratingConfig[6]);
  const [ratingsData, setRatingsData] = useState({ vibeRating: null, foodRating: null, drinksRating: null });

  useEffect(() => {
    const fetchRating = async () => {
      try {
        // console.log(`Fetching rating for establishment name: ${name}`);
        const response = await axios.get(`https://api.airtable.com/v0/${baseId}/${tableIds.ratingTableId}`, {
          params: {
            filterByFormula: `FIND('${name}', {Establishment})`
          },
          headers: {
            Authorization: `Bearer ${apiKey}`
          }
        });

        if (response.data.records.length === 0) {
          console.log('No records found.');
          return;
        }

        const { fields } = response.data.records[0];
        const { totalRating, vibeRating, foodRating, drinksRating } = fields;

        if (totalRating !== undefined && totalRating !== null) {
          const roundedTotalRating = Math.round(totalRating);
          setCurrentRatingConfig(ratingConfig[roundedTotalRating]);
          setRatingsData({ vibeRating, foodRating, drinksRating });
        }
      } catch (error) {
        console.error('Error fetching rating:', error.response ? error.response.data : error.message);
      }
    };

    fetchRating();
  }, [name]);

  return { currentRatingConfig, ratingsData };
};

export const useFetchEstablishmentData = (isRatingsModalOpen, name) => {
      const [establishmentData, setEstablishmentData] = useState(null);
    
      useEffect(() => {
        if (isRatingsModalOpen) {
          const fetchEstablishmentData = async () => {
            try {
              const response = await axios.get(`https://api.airtable.com/v0/${baseId}/${tableIds.ratingTableId}`, {
                params: {
                  filterByFormula: `FIND('${name}', {Establishment})`
                },
                headers: {
                  Authorization: `Bearer ${apiKey}`
                }
              });
    
              if (response.data.records.length === 0) return;
    
              const { fields, id } = response.data.records[0];
              const { totalRating, vibeRating, foodRating, drinksRating } = fields;
    
              setEstablishmentData({
                id,
                name,
                totalRating: totalRating ? Math.round(totalRating) : 6,
                vibeRating,
                foodRating,
                drinksRating
              });
            } catch (error) {
              console.error('Error fetching establishment data:', error.response ? error.response.data : error.message);
            }
          };
    
          fetchEstablishmentData();
        }
      }, [isRatingsModalOpen, name]);
    
      return establishmentData;
    };