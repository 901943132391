import axios from "axios";
import { config, getStreets, } from "..";
import { baseId, tableIds } from "../../constant";

const apiCityUrl = `https://api.airtable.com/v0/${baseId}/${tableIds.cityTableId}`;
const apiRequestedCitiesUrl = `https://api.airtable.com/v0/${baseId}/${tableIds.requestedCitiesId}`

export const FetchRequestCity = async (data) => {
    try {
        const response = await axios.get(apiRequestedCitiesUrl, config);
        return response.data.records;
    } catch (error) {
        console.error("Error posting data to Airtable:", error);
        throw error; // Re-throw the error to be handled by the calling function
    }
};

// Request City
export const requestCity = async (data) => {
    try {
        const payload = {
            fields: data,
            typecast: true,
        };
        const response = await axios.post(apiRequestedCitiesUrl, payload, config);
        return response;
    } catch (error) {
        console.error("Error posting data to Airtable:", error);
        throw error; // Re-throw the error to be handled by the calling function
    }
};


// Fetch cities
// export const fetchCityData = async () => {
//     try {
//         const cityResponse = await axios.get(apiCityUrl, config);
//         const cities = cityResponse.data.records;

//         // Collect all street IDs from all cities
//         const allStreetIds = cities.flatMap(city => city.fields.Streets);

//         // Assuming your API supports fetching multiple streets by their IDs in a single request
//         // You might need to adjust this part based on your API's actual capabilities
//         const streetsData = await getStreets();

//         // Create a map of street data by ID for easy lookup
//         const streetsById = streetsData.reduce((acc, street) => {
//             acc[street.id] = street;
//             return acc;
//         }, {});

//         // Combine city data with streets data
//         const citiesWithStreets = cities.map(city => ({
//             ...city,
//             value: city.fields.Name,
//             label: city.fields.Name,
//             id: city.id,
//             streets: city.fields.Streets.map(streetId => ({
//                 id: streetId,
//                 name: streetsById[streetId]?.fields.Name,
//                 fields: streetsById[streetId]?.fields
//             })),
//         }));

//         return citiesWithStreets;
//     } catch (error) {
//         console.error("Error fetching city data from Airtable:", error);
//     }
// };
export const fetchCityData = async () => {
    try {
        const apiCityUrl = `https://api.airtable.com/v0/${baseId}/${tableIds.cityTableId}?filterByFormula=AND({Active}="Yes", {Featured}="Yes")`;

        const cityResponse = await axios.get(apiCityUrl, config);
        const cities = cityResponse.data.records;
                
        const streetsData = await getStreets();
        const streetsById = streetsData.reduce((acc, street) => {
            acc[street.id] = street;
            return acc;
        }, {});

        const citiesWithStreets = cities.map(city => ({
            ...city,
            value: city.fields.Name,
            label: city.fields.Name,
            id: city.id,
            streets: city.fields.Streets.map(streetId => ({
                id: streetId,
                name: streetsById[streetId]?.fields.Name,
                fields: streetsById[streetId]?.fields
            })),
        }));
     
        return citiesWithStreets;
    } catch (error) {
        console.error("Error fetching city data from Airtable:", error);
    }
};


const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const fetchCityById = async (cityId, retries = 5, backoff = 300) => {
  const apiCityByIdUrl = `https://api.airtable.com/v0/${baseId}/${tableIds.cityTableId}/${cityId}`;
  try {
    const response = await axios.get(apiCityByIdUrl, config);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 429 && retries > 0) {
      console.warn(`Retrying fetchCityById after ${backoff} ms...`);
      await delay(backoff);
      return fetchCityById(cityId, retries - 1, backoff * 2);
    } else {
      console.error(`Error fetching city by ID ${cityId}:`, error);
      throw error;
    }
  }
};

// Function to fetch cities in batch
export const fetchCities = async () => {
    const apiCityUrl = `https://api.airtable.com/v0/${baseId}/${tableIds.cityTableId}?filterByFormula=AND({Active}="Yes", {Featured}="Yes")`;
    try {
      const response = await axios.get(apiCityUrl, config);
      return response.data.records;
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };