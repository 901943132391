// api.js
import axios from 'axios';
import { baseId, tableIds } from '../../constant';
import { apiKey } from '..';


const apiBaseUrl = `https://api.airtable.com/v0/${baseId}`;

// Fetch all poll records from Airtable
export const fetchAllPolls = async () => {
    try {
        const url = `https://api.airtable.com/v0/${baseId}/${tableIds.pollTableId}`;

        const response = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${apiKey}`,
            },
        });

        return response.data.records; // Return all records from the table
    } catch (error) {
        throw new Error('Error fetching poll records:', error.message);
    }
};

// Fetch poll by establishment ID
export const fetchPollByEstablishmentId = async (establishmentId) => {
    try {
        const records = await fetchAllPolls(); // Fetch all records first
        const poll = records.find((record) =>
            record?.fields?.Establishment && record?.fields?.Establishment.includes(establishmentId)
        );
        return poll || null; // Return the first matching poll, if found
    } catch (error) {
        throw new Error('Error fetching poll by establishment ID:', error.message);
    }
};

export const createPoll = async (pollData) => {
    try {
        const response = await axios.post(`${apiBaseUrl}/${tableIds.pollTableId}`, {
            fields: pollData
        }, {
            headers: {
                Authorization: `Bearer ${apiKey}`,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        throw new Error('Error creating poll:', error.message);
    }
};

export const updatePoll = async (pollId, updateData) => {
    try {
        const response = await axios.patch(`${apiBaseUrl}/${tableIds.pollTableId}/${pollId}`, {
            fields: updateData
        }, {
            headers: {
                Authorization: `Bearer ${apiKey}`,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        throw new Error('Error updating poll:', error.message);
    }
};

export const updateEstablishment = async (establishmentId, updateData) => {
    try {
        const response = await axios.patch(`${apiBaseUrl}/${tableIds.establishmentsTableId}/${establishmentId}`, {
            fields: updateData
        }, {
            headers: {
                Authorization: `Bearer ${apiKey}`,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        throw new Error('Error updating establishment:', error.message);
    }
};
