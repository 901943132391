import { useEffect, useState } from "react";
import { InputWithIcon } from "../components/Input";
import facebook from '../assest/icons/facebook.svg';
import google from '../assest/icons/google.svg';
import signupbannerImage from '../assest/signup.png';
import { useLocation, useNavigate } from 'react-router-dom';

import { Link } from "react-router-dom";
import Layout from "../components/Layout";
import { EmailIcon, PersonIcon, EstablishementIcon, KeyIcon } from "../utils/icons";
import { useDispatch, useSelector } from "react-redux";
import { Signup } from "../redux/slices/userSlices";

import arrow from '../assest/icons/white-arrow.svg';
import { LoadingButton } from "../components/Button";
import { fetchAllEstablishmentsOptions } from "../redux/slices/establishmentsSlice";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { verifyEstablishmentBelongsToTheUser } from "../api/establishment";
import ClaimListingModal from "../components/Popups/ClaimListingModal";

const initialState = {
    email: '',
    name: '',
    establishment: '',
    password: '',
    retypepassword: ''
};

const formFields = [
    {
        name: "email",
        placeholder: "Email",
        icon: <EmailIcon />,
        type: 'text'
    },
    {
        name: "name",
        placeholder: "Name",
        icon: <PersonIcon />,
        type: 'text'
    },
    {
        name: "establishment",
        placeholder: "Establishment",
        icon: <EstablishementIcon />,
        type: 'text'
    },
    {
        name: "password",
        placeholder: "Password",
        icon: <KeyIcon />,
        type: 'password'
    },
    {
        name: "retypepassword",
        placeholder: "Re-Type Password",
        icon: <KeyIcon />,
        type: 'password'
    },
];

function SignUp() {
    const location = useLocation();
    // when redirect from list your bussines.
    const { email, establishment, establishmentId } = location.state || {};

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const user = useSelector((state) => state.auth.user);
    const error = useSelector((state) => state.auth.error); // Adjust the path as necessary


    const { establishmentOption } = useSelector((state) => state.establishments);

    const [formFillErrors, setError] = useState("");
    const [formValue, setFormValue] = useState(initialState);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [loadingEstablishments, setLoadingEstablishments] = useState(true);
    const [claimModalOpen, setClaimModalOpen] = useState(false);

    const handleInputChange = (event) => {
        setError('');
        const name = event.target.name;
        const value = event.target.value;
        setFormValue({ ...formValue, [name]: value });
    };

    const handleAutocompleteChange = (event, newValue) => {
        setFormValue({ ...formValue, establishment: newValue });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if all form fields have values
        const allFieldsFilled = Object.values(formValue).every(field => {
            return typeof field === 'string' ? field.trim() !== '' : field.value.trim() !== '';
        });

        if (!allFieldsFilled) {
            setError("Please fill in all fields.");
            return;
        }

        if (formValue.password !== formValue.retypepassword) {
            setError("Passwords do not match.");
            return;
        }

        setIsSubmitting(true);

        const userData = {
            Email: formValue.email,
            Name: formValue.name,
            Establishments: establishmentId ? [establishmentId] : [formValue.establishment.id],
            Password: formValue.password,
        };

        const establishmentName = formValue.establishment.value;

        dispatch(Signup({ userData, establishmentName }))
            .unwrap()
            .then((response) => {
                console.log(response, 'response');
                navigate('/dashboard');
                setIsSubmitting(false);
            })
            .catch((error) => {
                setIsSubmitting(false);
                console.error("Signup failed:", error);
                setError("Failed to submit your request");
            });
    };

    useEffect(() => {
        if (email && establishment) {
            setFormValue((prev) => ({ ...prev, email, establishment }));
        }
    }, [email, establishment]);

    useEffect(() => {
        setLoadingEstablishments(true);
        dispatch(fetchAllEstablishmentsOptions())
            .then(() => setLoadingEstablishments(false))
            .catch((error) => {
                console.error('Error fetching all establishments:', error);
                setError('Failed to load all establishments');
                setLoadingEstablishments(false);
            });
    }, [dispatch]);

    useEffect(() => {
        if (error) {
            setClaimModalOpen(true)
        }
    }, [error]);

    return (
        <Layout>
            <div className="bg-darkgreen rounded-[15px] mx-[15px] my-10 h-[950px] relative">
                <div className="max-w-[1360px] mt-[41px] mb-5  mx-auto  h-full">
                    <div className="flex justify-center gap-5 w-full pt-12 h-full items-center px-4">
                        <div className="pt-8 md:min-w-[400px] w-full md:w-[unset]">
                            <div className="flex gap-y-2 flex-col">
                                <p className="text-[60px] font-bold leading-[62px] text-white tracking-tighter">
                                    Join Now
                                </p>
                                <span className="text-white text-lg font-semibold leading-8">Boost Your Visibility & Track
                                    <br /> Success with Every Click!</span>
                                <p className="text-white text-base font-normal">100% Free to Sign Up</p>
                            </div>
                            <form
                                onSubmit={handleSubmit}
                                className="max-w-[408px] py-5 flex gap-y-6 flex-col justify-center items-center text-white relative"
                            >
                                <img className="absolute -right-8 -top-[5rem]" src={arrow} alt="arrow" />
                                {formFields.map((field, index) => {
                                    return (
                                        <div key={index} className="flex gap-4 items-center box w-full">
                                            {field.name !== 'establishment' ? (
                                                <InputWithIcon
                                                    type={field.type}
                                                    placeholder={field.placeholder}
                                                    name={field.name}
                                                    handleInputChange={handleInputChange}
                                                    value={formValue[field.name]}
                                                    icon={field.icon}
                                                    formtype="signup"
                                                />
                                            ) : (
                                                <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    placeholder="Enter the Establishment Name"
                                                    options={loadingEstablishments ? [] : establishmentOption}
                                                    loading={loadingEstablishments}
                                                    name={field.name}
                                                    sx={{ width: '100%', background: '#F2EADF', color: '#000', borderRadius: '5px' }}
                                                    onChange={handleAutocompleteChange}
                                                    renderInput={(params) => <TextField  {...params} placeholder="Enter the Establishment Name" />}
                                                />
                                            )}
                                        </div>
                                    );
                                })}

                                {error && <span className="text-red text-bold text-base">{error}</span>}
                                {formFillErrors && <span className="text-red text-bold text-base">{formFillErrors}</span>}
                                <LoadingButton isLoading={isSubmitting}>
                                    Sign Up
                                </LoadingButton>

                                <span>Or</span>

                                <div className="flex gap-x-3">
                                    <div className="bg-white w-12 h-12 rounded-full text-black flex items-center justify-center">
                                        <img src={facebook} alt="" />
                                    </div>
                                    <div className="bg-white w-12 h-12 rounded-full text-black flex items-center justify-center">
                                        <img src={google} alt="" />
                                    </div>
                                </div>

                                <p className="font-normal text-lg text-[#9DAAA8]">Already have an account?  <Link to="/login" className="font-bold text-[#fff]">Login</Link></p>
                            </form>
                        </div>

                        <div className="w-[718px] hidden md:block">
                            <img src={signupbannerImage} alt="image" className="absolute right-0 top-0 h-full w-full max-w-[700px] object-cover rounded-lg" />
                        </div>
                        <ClaimListingModal error={error} open={claimModalOpen} onClose={() => setClaimModalOpen(false)} />
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default SignUp;
