import React from "react";
import { formatDate } from "../utils";

function Date({ date }) {
  const formatedDate = formatDate(date);

  return (
    <div className="w-14 md:w-[154px] h-10 md:h-[66px] relative bg-bg-time rounded-[10px] flex items-center px-2 md:px-3 justify-center">
      <div className="text-center text-stone-200 text-xs md:text-xl font-semibold leading-[25px] whitespace-nowrap">
        {formatedDate}
      </div>
      {/* <div className="left-[20px] top-[21px] w-fit absolute "></div> */}
    </div>
  );
}

export default Date;
