import React from 'react';
import LogoAvatar from "../../assest/icons/dishboardavatar.svg";

const Navbar = ({ cities, title, establishmentDetail }) => {
    const data = {
        overview: 'Overview',
        pubName: 'O’Michaels Pub & Grill',
        address: '780 W Idaho St, Boise, ID 83702'
    };

    // Use optional chaining and nullish coalescing to safely access properties
    const city = cities?.filter(item => item.id === establishmentDetail?.city?.[0]);
    const address = `${establishmentDetail?.address?? ''}, ${city?.[0]?.label?? ''} ID ${establishmentDetail?.zip?? ''}`;

    return (
        <div className='flex justify-between items-center'>
            <p className='text-base font-medium text-white font-Metropolis uppercase'>{title}</p>
            <p className=' font-semibold text-3xl font-Metropolis items-center flex text-white gap-9'>
                {establishmentDetail?.name?? data.pubName} 
                <span className='font-normal text-off-gray text-lg font-Metropolis'>
                    {establishmentDetail? address : data.address}
                </span>
            </p>
            <div className='w-16 h-16'>
                <img src={LogoAvatar} alt="logo" className='' />
            </div>
        </div>
    );
};

export default Navbar;