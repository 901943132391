import React, { useEffect, useState } from "react";
import FilterSelect from "./FilterSelect";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import live from "../assest/icons/live.svg";
import wine from "../assest/icons/wine.svg";
import music from "../assest/icons/music.svg";
import cocktails from "../assest/icons/coctail-glass.svg";
import outdoor from "../assest/icons/outdoor.svg";
import craft from "../assest/icons/craft.svg";
import yardGames from "../assest/icons/yard-games.svg"; // Add the icon for Yard Games
import tv from "../assest/icons/tv.svg"; // Add the icon for TV's
import { useDispatch, useSelector } from "react-redux";
import { setIsLive } from "../redux/slices/EstablishmentsListingSlices";

const FilterCard = ({
  isFilterOpen,
  cities,
  categories,
  closeFilter,
  selectedFilters,
  updateSelectedFilters,
  filteredItems,
  streets,
  resetFilters,
}) => {
  const [streetsData, setStreetsData] = useState([]);

  useEffect(() => {
    if (selectedFilters && selectedFilters.City.length > 0) {
      const filteredCities = cities.filter((city) =>
        selectedFilters.City.includes(city.value)
      );

      const CityRelatedStreets = filteredCities.flatMap((city) =>
        city.streets ? city.streets : []
      );

      const streetsWithValue = CityRelatedStreets.map((street) => ({
        ...street,
        value: street.name,
      }));

      // Filter out duplicates based on the 'value' property
      const uniqueStreets = streetsWithValue.filter(
        (street, index, self) =>
          index === self.findIndex((t) => t.value === street.value)
      );

      setStreetsData(uniqueStreets);
    } else {
      setStreetsData([]);
    }
  }, [selectedFilters, selectedFilters.City]);

  const features = [
    { id: 2, value: "Live Music", displayText: "Live Music", icon: music, alt: "Live Music" },
    { id: 5, value: "Outdoor Seating", displayText: "Outdoor", icon: outdoor, alt: "Outdoor Seating" }, // Changed display text to "Outdoor"
    { id: 6, value: "Cocktails", displayText: "Cocktails", icon: cocktails, alt: "Cocktails" },
    { id: 3, value: "Wine", displayText: "Wine", icon: wine, alt: "Wine" },
    { id: 4, value: "Craft Beer", displayText: "Craft Beer", icon: craft, alt: "Craft Beer" },
    { id: 7, value: "Yard Games", displayText: "Yard Games", icon: yardGames, alt: "Yard Games" }, // Added Yard Games
    { id: 8, value: "TV's", displayText: "TV's", icon: tv, alt: "TV's" }, // Added TV's
  ];
  const { isLive } = useSelector((state) => state.happyHours);

  return (
    <Modal
      isOpen={isFilterOpen}
      onRequestClose={closeFilter}
      overlayClassName="signUpModalOverlay"
      className="signUpModal bg-darkgreen min-h-[378px] max-h-[90vh] overflow-y-scroll rounded-xl max-w-[1085.78px] w-full mx-auto h-[95vh] !p-0"
    >
      <div className="relative w-full min-h-64 rounded-[20px] flex flex-col py-6 px-4 md:p-8 h-full xs:px-0">
        <button
          className=" text-2xl text-center leading-10 font-thin text-white flex items-center justify-center absolute left-5 md:left-8 top-5 md:top-8 z-[999]"
          onClick={closeFilter}
        >
          X
        </button>
        <div className="w-[90%] md:w-full mx-auto flex justify-center items-center px-2 xs:flex-wrap">
          <div className="ml-3 flex justify-center items-start md:items-center text-center font-semibold text-lg lg:text-2xl text-[#fff] xs:flex-wrap">
            Filters {"  "} <span className="mx-2">-</span>
            <p className="font-light text-left text-sm lg:text-lg text-[#fff] ">
              Search Cities, Streets, amenities, or type of place
            </p>
          </div>
        </div>
        <hr className="border border-[#fff] opacity-20 w-full my-4 md:my-6 px-2" />

        <div className="w-full flex gap-y-1 flex-col px-3">
          <h6 className="font-semibold text-xl text-[#fff]">City</h6>
          <p className="text-base font-light text-[#fff]">Select a city</p>
          <FilterSelector
            options={cities}
            title={"City"}
            updateSelectedFilters={updateSelectedFilters}
            selectedFilters={selectedFilters}
          />
          <hr className="border border-[#fff] opacity-20 w-full my-6" />
        </div>

        <div className="w-full flex gap-y-1 flex-col px-3">
          <h6 className="font-semibold text-xl text-[#fff]">Type of place</h6>
          <p className="text-base font-light text-[#fff]">
            Restaurant, bar, cocktail lounge or any type of place
          </p>
          <span className="font-light text-xs text-[#cdcdcd]">
            Select as many as you would like
          </span>

          <FilterSelector
            options={categories}
            title={"Category"}
            updateSelectedFilters={updateSelectedFilters}
            selectedFilters={selectedFilters}
          />
          <hr className="border border-[#fff] opacity-20 w-full my-6" />
        </div>

        <div className="w-full flex gap-y-1 flex-col px-3">
          <h6 className="font-semibold text-xl text-[#fff]">
            Discover Your Happy Place
          </h6>
          <p className="text-sm md:text-base font-light text-white">
            Cocktails, Crafts, Games, and Chords
          </p>
          <span className="hidden md:block font-light text-xs text-[#cdcdcd]">
            Select as many as you would like
          </span>

          <FilterSelector
            options={features}
            title={"Amenities"}
            updateSelectedFilters={updateSelectedFilters}
            selectedFilters={selectedFilters}
            isLive={isLive}
          />
          <hr className="border border-[#fff] opacity-20 w-full my-6" />
        </div>

        <div className="w-full flex gap-y-1 flex-col px-3">
          <h6 className="font-semibold text-xl text-[#fff]">Street(s)</h6>
          <p className="text-base font-light text-[#fff]">
            Find happy hour spots near you or near a specific location
          </p>
          <span className="font-light text-xs text-[#cdcdcd]">
            Select 1 or 2 streets
          </span>

          <FilterSelector
            options={streetsData}
            title={"Streets"}
            updateSelectedFilters={updateSelectedFilters}
            selectedFilters={selectedFilters}
          />
          {/* <hr className="border border-[#fff] opacity-20 w-full my-6" /> */}
        </div>

        <div className="flex xs:py-8  py-4 mt-2 xs:max-w-none w-full xs:w-[100%]  h-full xs:px-2 px-4  max-w-full sticky bottom-0 z-50 bg-darkgreen flex-col border-t-2 border-white border-opacity-50 ">
          {/* <hr className="border border-white  opacity-50 mb-6 w-full max-w-full lg:max-w-none" /> */}
          <div className="flex justify-between">
            <button
              className="text-white h-[40px] px-2 rounded leading-10 hover:text-white transition-all delay-100 font-semibold hover:border-main-orange-50 border border-transparent"
              onClick={resetFilters}
            >
              Clear All
            </button>
            <button
              onClick={closeFilter}
              className=" ml-3 px-4 h-[50px] min-w-[158px] rounded-xl text-[#1E1E1E] rder-main-orange text-lg text-center leading-10 bg-main-orange-50 hover:bg-transparent hover:text-white transition-all delay-100 font-medium hover:border-main-orange-50 border border-main-orange-50 "
            >
              Show {filteredItems} Happy Hours
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FilterCard;

const FilterSelector = ({
  title,
  options,
  updateSelectedFilters,
  selectedFilters,
  isLive,
}) => {
  const handleCheckboxChange = (filterKey, selectedValue, event) => {
    updateSelectedFilters(filterKey, selectedValue, event.target.checked);
  };

  const [filterOptions, setfilterOptions] = useState(options);

  useEffect(() => {
    if (options.length) {
      const sortedOptions = [...options]?.sort((a, b) =>
        a?.value?.localeCompare(b?.value)
      );
      setfilterOptions(sortedOptions);
    } else {
      setfilterOptions(options);
    }
  }, [options]);

  const dispatch = useDispatch();
  return (
    <div className="w-full my-4">
      <div className="grid grid-cols-3 md:flex gap-2 md:gap-3 flex-wrap">
        {filterOptions.length === 0 && title === "Streets" && (
          <p className="text-main-orange-50 font-medium text-sm">
            To view Streets please select a city
          </p>
        )}
        {filterOptions.length === 0 && title === "Category" && (
          <p className="text-main-orange-50 font-medium text-sm">Loading ...</p>
        )}
        {title !== "Amenities" ? (
          filterOptions.map((item) => (
            <label
              key={item.value}
              className={`px-2 md:px-8 py-4 text-center leafing-1 font-normal text-sm items-center justify-center border rounded-xl cursor-pointer hover:border-[#FBBF00] hover:opacity-100 hover:border-1 hover:text-white hover:bg-[#111F24] ${
                selectedFilters && selectedFilters[title]?.includes(item.value)
                  ? "bg-yellow-500 text-bg-main font-semibold opacity-100 border-transparent"
                  : "bg-gray-700 opacity-50 text-white "
              }`}
            >
              <input
                type="checkbox"
                value={item}
                className="hidden"
                id={title === "Category" ? item.value : item.id}
                checked={
                  selectedFilters &&
                  selectedFilters[title]?.includes(item.value)
                }
                onChange={(event) => {
                  handleCheckboxChange(title, item.value, event);
                }}
              />
              {item.value}
            </label>
          ))
        ) : (
          <>
            <label
              className={`px-2 md:px-4 py-2 leafing-1 text-center font-normal text-base flex flex-col gap-y-2 items-center justify-center border rounded-xl cursor-pointer hover:opacity-100 text-white transition-all delay-100 ${
                isLive
                  ? "opacity-100 font-semibold text-bg-main "
                  : "bg-gray-700  opacity-50"
              }`}
            >
              <img src={live} className="w-6 h-6" alt={"live now"} />
              <input
                type="checkbox"
                value={"Live Now"}
                className="hidden"
                checked={isLive}
                onChange={(event) => {
                  dispatch(setIsLive(event.target.checked));
                }}
              />
              Live Now!
            </label>
            {filterOptions.map((item) => (
              <label
                key={item.value}
                className={`px-2 md:px-8 py-4 leafing-1 text-center font-normal text-sm flex flex-col gap-y-2 items-center justify-center border rounded-xl cursor-pointer hover:opacity-100 text-white transition-all delay-100 ${
                  selectedFilters &&
                  selectedFilters[title]?.includes(item.value)
                    ? "opacity-100 font-semibold bg-[#FBBF00] text-[#111F24] border-[#FBBF00] "
                    : "bg-gray-700  opacity-50"
                }`}
              >
                <img
                  src={item.icon}
                  className={`w-6 h-6 transition-all ${
                    selectedFilters &&
                    selectedFilters[title]?.includes(item.value)
                      ? "filter brightness-0" // Applies a black filter when selected
                      : ""
                  }`}
                  alt={item.alt}
                />
                <input
                  type="checkbox"
                  value={item}
                  className="hidden"
                  id={item.value}
                  checked={
                    selectedFilters &&
                    selectedFilters[title]?.includes(item.value)
                  }
                  onChange={(event) => {
                    handleCheckboxChange(title, item.value, event);
                  }}
                />
                {item.value}
              </label>
            ))}
          </>
        )}
      </div>
    </div>
  );
};
