import React, { useState } from "react";
import arrowIcon from "../assest/icons/Cheveron.svg";

function CarouselButton({ className, onClick }) {
  return (
    <div
      onClick={onClick}
      className="cursor-pointer w-[50px] h-[50px] justify-center items-center gap-2.5 inline-flex"
    >
      <div className="w-[50px] h-[50px] relative">
        <div className=" w-[50px] h-[50px] left-0 top-0 absolute rounded-full border border-stone-200" />
        <div
          className={`hover:bg-white w-[49px] h-[49px] bg-gray md:bg-transparent rounded-full flex items-center justify-center slider-button ${className}`}
        >
          <svg
            width="12"
            height="8"
            viewBox="0 0 12 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              y="2.13477"
              width="3"
              height="8"
              transform="rotate(-45 0 2.13477)"
              fill="#FFECD7"
            />
            <rect
              x="6.40088"
              y="7.07104"
              width="3"
              height="7"
              transform="rotate(-135 6.40088 7.07104)"
              fill="#FFECD7"
            />
          </svg>
        </div>
      </div>
    </div>
  );
}

export default CarouselButton;
