import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import StepperIndicator from "../../Stepper/StepperIndicator";
import {
  CreateEstablishmentUser,
  createEstablishment,
  createHappyHour,
  createStreet,
} from "../../../api";
import { storage } from "../../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import {
  Step4,
  UploadCoverPhoto,
  UploadMenu,
  UploadProfile,
  Done,
  GetStartedScreen
} from "./componets";
import { isUserAlreadySignIn } from "../../../api/users";

const AddEstasblishementDetail = ({ businesses, onSubmit, cities }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [getStarted, setGetStarted] = useState(false);
  const [done, setDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadMenuProgress, setUploadMenuProgress] = useState(0);
  const [uploadCoverProgress, setUploadCoverProgress] = useState(0);
  const [showGif, setShowGif] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    profile: { uploadedImage: null },
    coverPhoto: { uploadedImage: null },
    menu: { previewUrl: null, previewType: null },
    step4: { website: "", phoneNumber: "" },
  });

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    // Example validation for businessName
    if (currentStep === 4 && !formData.step4.phoneNumber) {
      newErrors.step4 = true;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Returns true if no errors
  };

  // const [isCityAvailable, setIsCityAvailable] = useState(false);

  const handleNext = () => {
    if (validateForm()) {
      if (currentStep === 4) {
        onSubmit(formData);
        // update the localstorage to store the values temporily for api errors and stuff
        localStorage.setItem(
          "establishmentDetail",
          JSON.stringify({ ...businesses, estasblishementDetails: formData })
        );
        setDone(true);
      } else {
        setCurrentStep((prevStep) => prevStep + 1);
      }
    }
  };

  // const handleSubmit = async () => {
  //   setDisableButton(true);
  //   setLoading(true);

  //   const { businessDetails, estasblishementDetails, HappyHours } = businesses;

  //   // Prepare the user detail
  //   const userDetail = {
  //     Name: businessDetails.user.name,
  //     Email: businessDetails.user.email,
  //     Role: businessDetails.user.role.value,
  //   };

  //   const attachmentMenuObject = [
  //     {
  //       url: estasblishementDetails?.menu?.previewUrl,
  //     },
  //   ];

  //   const attachmentProfileObject = [
  //     {
  //       url: estasblishementDetails?.profile?.uploadedImage,
  //     },
  //   ];

  //   const attachmentCoverPhotoObject = [
  //     {
  //       url: estasblishementDetails?.coverPhoto?.uploadedImage,
  //     },
  //   ];
  //   // const cityId =
  //   //   cities.find((city) => city.value === businessDetails.businessAddress.city)
  //   //     ?.id || null;

  //   // Prepare the establishment data
  //   const establishmentData = {
  //     Name: businessDetails.businessName.name,
  //     category: businessDetails.BusinessCategory.categories,
  //     Amenities: businessDetails.BusinessAmenities.amenities,
  //     categoryUserInput: businessDetails.BusinessCategory.custom,
  //     // amenitiesUserInput: businessDetails.BusinessAmenities.custom,
  //     ["Happy Hours"]: HappyHours,
  //     Website: estasblishementDetails?.step4?.website,
  //     Email: businessDetails.user.email,
  //     ["Phone Number"]: estasblishementDetails?.step4?.phoneNumber,
  //     crossStreets: businessDetails?.crossStreets || null,
  //     menu: attachmentMenuObject,
  //     ["profile-image"]: attachmentProfileObject,
  //     ["cover-image"]: attachmentCoverPhotoObject,
  //     zip: businessDetails.businessAddress.zip,
  //     state: [businessDetails.businessAddress.state.id],
  //     city: [businessDetails.businessAddress.city.id],

  //     // state: ["reckRdmu3cIjPX4IZ"],
  //     // ["street 2"]: businessDetails.businessAddress.street,
  //     ["street 1"]: businessDetails.businessAddress.street,
  //   };

  //   if (businessDetails.BusinessCategory.custom) {
  //     establishmentData.categoryUserInput =
  //       businessDetails.BusinessCategory.custom;
  //   }
  //   // if (businessDetails.BusinessAmenities.custom) {
  //   //   establishmentData.amenitiesUserInput =
  //   //     businessDetails.BusinessAmenities.custom;
  //   // }
  //   try {
  //     // Step 1: Create Happy Hours and collect their IDs
  //     const happyHourIds = [];
  //     for (const day in HappyHours) {
  //       const offers = HappyHours[day];
  //       for (const offer of offers) {
  //         const payload = {
  //           "Happy Hour Offer": offer.offer,
  //           "Happy Hour Day": [day],
  //           "Start Time": offer.start,
  //           "End Time": offer.end,
  //         };
  //         const response = await createHappyHour(payload);
  //         happyHourIds.push(response.id); // Assuming the response contains the ID of the created Happy Hour
  //       }
  //     }

  //     let crossStreetsResponse = null;
  //     let crossStreetsResponse2 = null;

  //     if (establishmentData.crossStreets) {
  //       const streetPayload = {
  //         Name: establishmentData.crossStreets.street,
  //         City: [businessDetails.businessAddress.city.id],
  //       };
  //       const streetPayload2 = {
  //         Name: establishmentData.crossStreets.street2,
  //         City: [businessDetails.businessAddress.city.id],
  //       };

  //       crossStreetsResponse = await createStreet(streetPayload);
  //       crossStreetsResponse2 = await createStreet(streetPayload2);
  //     }

  //     if (!crossStreetsResponse === 200) {
  //       return;
  //     }
  //     if (!crossStreetsResponse2 === 200) {
  //       return;
  //     }

  //     const crossStreets = [
  //       crossStreetsResponse.data.id,
  //       crossStreetsResponse2.data.id,
  //     ];

  //     // return;
  //     // Step 2: Update the establishment data with the Happy Hour IDs
  //     establishmentData["Happy Hours"] = happyHourIds;
  //     establishmentData["crossStreets"] = crossStreets;
  //     // establishmentData["crossStreets"] = [crossStreetsResponse.id];
  //     // Step 3: Create the establishment and get its ID
  //     const establishmentResponse = await createEstablishment(
  //       establishmentData
  //     );

  //     const establishmentId = establishmentResponse.id; // Assuming the response contains the ID of the created establishment

  //     // Step 4: Prepare the user data with the establishment ID
  //     const newEstablishmentUser = {
  //       ...userDetail,
  //       Establishment: [establishmentId], // Include the array with the establishment ID
  //       // Created: new Date().toISOString(),
  //       // Add other fields as necessary
  //     };

  //     // Step 5: Update the EstablishmentUser table with the new data
  //     const response = await CreateEstablishmentUser(newEstablishmentUser);
  //     console.log("Establishment user created successfully:", response);
  //     if (response.status === 200) {
  //       navigate("/");
  //     }
  //     // Handle success, e.g., navigate to another page or show a success message
  //   } catch (error) {
  //     console.error("Error creating establishment user:", error);
  //     setLoading(false);
  //     setDisableButton(false);
  //     // Handle error, e.g., show an error message
  //   }
  // };

  const handleSubmit = async () => {
    // Store the "businesses" object in localStorage
    localStorage.setItem("businesses", JSON.stringify(businesses));

    setDisableButton(true);
    setLoading(true);

    const { businessDetails, estasblishementDetails, HappyHours } = businesses;

    // Prepare the user detail
    const userDetail = {
      Name: businessDetails.user.name,
      Email: businessDetails.user.email,
      Role: businessDetails.user.role.value,
    };

    const attachmentMenuObject = [
      {
        url: estasblishementDetails?.menu?.previewUrl,
      },
    ];

    const attachmentProfileObject = [
      {
        url: estasblishementDetails?.profile?.uploadedImage,
      },
    ];

    const attachmentCoverPhotoObject = [
      {
        url: estasblishementDetails?.coverPhoto?.uploadedImage,
      },
    ];

    // Prepare the establishment data
    const establishmentData = {
      Name: businessDetails.businessName.name,
      category: businessDetails.BusinessCategory.categories,
      Amenities: businessDetails.BusinessAmenities.amenities,
      categoryUserInput: businessDetails.BusinessCategory.custom,
      ["Happy Hours"]: HappyHours,
      Website: estasblishementDetails?.step4?.website,
      Email: businessDetails.user.email,
      ["Phone Number"]: estasblishementDetails?.step4?.phoneNumber,
      crossStreets: businessDetails?.crossStreets || null,
      menu: attachmentMenuObject,
      ["profile-image"]: attachmentProfileObject,
      ["cover-image"]: attachmentCoverPhotoObject,
      zip: businessDetails.businessAddress.zip,
      state: [businessDetails.businessAddress.state.id],
      city: [businessDetails.businessAddress.city.id],
      ["street 1"]: businessDetails.businessAddress.street,
    };

    if (businessDetails.BusinessCategory.custom) {
      establishmentData.categoryUserInput =
        businessDetails.BusinessCategory.custom;
    }

    try {
      // Step 1: Create Happy Hours and collect their IDs
      const happyHourIds = [];
      for (const day in HappyHours) {
        const offers = HappyHours[day];
        for (const offer of offers) {
          const payload = {
            "Happy Hour Offer": offer.offer,
            "Happy Hour Day": [day],
            "Start Time": offer.start,
            "End Time": offer.end,
          };
          const response = await createHappyHour(payload);
          happyHourIds.push(response.id); // Assuming the response contains the ID of the created Happy Hour
        }
      }

      let crossStreetsResponse = null;
      let crossStreetsResponse2 = null;

      if (establishmentData.crossStreets) {
        const streetPayload = {
          Name: establishmentData.crossStreets.street,
          City: [businessDetails.businessAddress.city.id],
        };
        const streetPayload2 = {
          Name: establishmentData.crossStreets.street2,
          City: [businessDetails.businessAddress.city.id],
        };

        crossStreetsResponse = await createStreet(streetPayload);
        crossStreetsResponse2 = await createStreet(streetPayload2);
      }

      if (!crossStreetsResponse === 200) {
        return;
      }
      if (!crossStreetsResponse2 === 200) {
        return;
      }

      const crossStreets = [
        crossStreetsResponse.data.id,
        crossStreetsResponse2.data.id,
      ];

      // Step 2: Update the establishment data with the Happy Hour IDs
      establishmentData["Happy Hours"] = happyHourIds;
      establishmentData["crossStreets"] = crossStreets;

      // Step 3: Create the establishment and get its ID
      const establishmentResponse = await createEstablishment(
        establishmentData
      );
      const establishmentId = establishmentResponse.id; // Assuming the response contains the ID of the created establishment

      // Step 4: Prepare the user data with the establishment ID
      const newEstablishmentUser = {
        ...userDetail,
        Establishment: [establishmentId], // Include the array with the establishment ID
      };

      // Step 5: Update the EstablishmentUser table with the new data
      const response = await CreateEstablishmentUser(newEstablishmentUser);
      console.log("Establishment user created successfully:", response);

      if (response.status === 200) {
        // Check if the user is already signed in
        const userExists = await isUserAlreadySignIn(
          businessDetails.user.email
        );
        if (userExists) {
          navigate("/login", {
            state: {
              email: businessDetails.user.email,
              establishment: businessDetails.businessName.name,
              establishmentId: establishmentId
            },
          });
        } else {
          navigate("/signup", {
            state: {
              email: businessDetails.user.email,
              establishment: businessDetails.businessName.name,
              establishmentId: establishmentId
            },
          });
        }
      }
    } catch (error) {
      console.error("Error creating establishment user:", error);
      setLoading(false);
      setDisableButton(false);
      // Handle error, e.g., show an error message
    }
  };

  const handleFileUpload = (event, step) => {
    const file = event.target.files[0];
    if (!file) return;
    setShowGif(true);
    // Initialize progress to 0
    setUploadProgress(0); // Initialize progress to 0, not 1

    const storageRef = ref(storage, `files/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Calculate progress percentage
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // Update progress state
        setUploadProgress(progress);
      },
      (error) => {
        // Handle unsuccessful uploads
        console.error("Upload failed:", error);
        setShowGif(false); // Hide the GIF if there's an error
      },
      () => {
        // Handle successful uploads on complete
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setFormData((prevData) => ({
            ...prevData,
            [step]: { ...prevData[step], uploadedImage: url },
          }));
          setShowGif(false); // Hide the GIF after successful upload
        });
      }
    );
  };

  const handleFileUploadCoverPhoto = (event, step = "coverPhoto") => {
    const file = event.target.files[0];
    if (!file) return;
    setUploadCoverProgress(20); // Start with 20% progress
    setShowGif(true);
    const storageRef = ref(storage, `files/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    // Simulate progress update over time
    let progressInterval = setInterval(() => {
      setUploadCoverProgress((prevProgress) => {
        if (prevProgress >= 380) {
          // Stop the interval once it reaches 380%
          clearInterval(progressInterval);
          return prevProgress;
        }
        return prevProgress + 5; // Increment progress by 1%
      });
    }, 100); // Update every 100ms

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // No need to calculate progress from snapshot here
      },
      (error) => {
        // Handle unsuccessful uploads
        clearInterval(progressInterval); // Clear the interval on error
        setShowGif(false);
        console.error("Upload failed:", error);
      },
      () => {
        // Handle successful uploads on complete
        clearInterval(progressInterval); // Clear the interval on success
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setFormData((prevData) => ({
            ...prevData,
            [step]: { ...prevData[step], uploadedImage: url },
          }));
          setShowGif(false); // Hide the GIF after successful upload
          setUploadCoverProgress(380);
        });
      }
    );
  };

  const handleFileUploadMenu = (event) => {
    const file = event.target.files[0];
    if (!file) return;
    setShowGif(true);
    setUploadCoverProgress(1);
    const storageRef = ref(storage, `files/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Calculate progress percentage
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadMenuProgress(progress);
      },
      (error) => {
        // Handle unsuccessful uploads
        setShowGif(false);
        console.error("Upload failed:", error);
      },
      () => {
        // Handle successful uploads on complete
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setFormData((prevData) => ({
            ...prevData,
            menu: {
              ...prevData.menu,
              previewUrl: url,
              previewType: file.type,
            },
          }));
          setShowGif(false);
        });
      }
    );
  };

  const handleInputChange = (event, step, field) => {
    setFormData((prevData) => ({
      ...prevData,
      [step]: { ...prevData[step], [field]: event.target.value },
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      step4: false,
    }));
  };

  const renderForm = () => {
    switch (currentStep) {
      case 1:
        return (
          <UploadProfile
            formData={formData.profile}
            handleNext={handleNext}
            handleFileUpload={(event) => handleFileUpload(event, "profile")}
            uploadedImage={formData.profile.uploadedImage}
            uploadProgress={uploadProgress}
            showGif={showGif}
          />
        );
      case 2:
        return (
          <UploadCoverPhoto
            formData={formData.coverPhoto}
            handleNext={handleNext}
            handleFileUpload={handleFileUploadCoverPhoto}
            uploadedImage={formData.coverPhoto.uploadedImage}
            uploadProgress={uploadCoverProgress}
            showGif={showGif}
          />
        );
      case 3:
        return (
          <UploadMenu
            formData={formData.menu}
            handleNext={handleNext}
            handleFileUpload={handleFileUploadMenu}
            previewUrl={formData.menu.previewUrl}
            previewType={formData.menu.previewType}
            uploadProgress={uploadMenuProgress}
            showGif={showGif}
          />
        );
      case 4:
        return (
          <Step4
            formData={formData.step4}
            handleNext={handleNext}
            handleInputChange={handleInputChange}
            error={errors.step4}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen !h-fit xl:h-screen bg-darkgreen rounded-[15px] xs:mx-2 mx-[15px] my-8">
      <div className="px-0 py-8 flex flex-col text-white p-6 rounded h-full items-center justify-center min-h-screen md:min-h-[unset]">
        {!getStarted ? (
          <GetStartedScreen setGetStarted={setGetStarted} />
        ) : (
          <>
            {!done ? (
              <div className="max-w-[962.28px] mx-auto px-4 py-8 flex flex-col text-white p-6 rounded h-full items-center justify-center">
                <div className="text-center">
                  <h1 className="text-3xl font-bold lg:text-[60px] leading-[72px] tracking-tighter">
                    Business Profile
                  </h1>
                  <p className="text-sm font-medium lg:text-2xl tracking-tighter">
                    Complete Your Profile, Elevate Your Visibility
                  </p>
                </div>
                <StepperIndicator
                  steps={[1, 2, 3, 4]}
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                />
                {/* <Stepper >
        {renderForm()}
      </Stepper> */}

                <div className="mb-20">{renderForm()}</div>

                <div className="flex justify-between mt-4">
                  {/* {currentStep > 1 && (
            <button
              onClick={handlePrev}
              className="bg-gray-300 text-gray-700 px-4 py-2 rounded"
            >
              Previous
            </button>
          )} */}
                  {/* {currentStep < 3 && ( */}
                  {/* <button
                onClick={handleNext}
                className="bg-main-orange text-white px-4 py-2 rounded"
              >
                Next
              </button> */}
                  {/* )} */}
                </div>
              </div>
            ) : (
              <Done
                cityName={businesses.businessDetails.businessAddress.city}
                // isCity={isCityAvailable}
                handleSubmit={handleSubmit}
                disableButton={disableButton}
                loading={loading}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default AddEstasblishementDetail;


