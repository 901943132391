import React from "react";
import img from "../../assest/icons/delete.svg";
import Modal from "react-modal";

function ConfirmationModal({ open, onClose, handleDelete }) {
  return (
    <Modal
      isOpen={open}
      onRequestClose={onClose}
      overlayClassName=" delete-overlay"
      className=" max-w-[440px] h-[300px] bg-[#fff] mx-auto translate-y-1/2 rounded p-8 flex items-center justify-center"
    >
      <div className="flex flex-col justify-center items-center space-y-6 relative ">
        <button onClick={onClose} className="absolute -top-0 -right-0 text-[#2E3A48] text-4xl"> &times;</button>
        <img src={img} className="w-16 h-16" alt="" />
        <div className="flex flex-col space-y-1 text-center">
          <h6 className="font-medium text-xl text-[#212B36]">
            Delete <strong>Happy Hour</strong> offer?
          </h6>
          <span className="text-sm font-light text-[#596570]">
            Are you sure you would like to delete your offer?
          </span>
        </div>

        <div className="flex space-x-2">
          <button onClick={onClose} className="h-12 w-44 border border-black rounded text-black">
            Cancel
          </button>
          <button onClick={handleDelete} className="h-12 w-44 bg-red text-white border border-transparent rounded">
            Delete
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default ConfirmationModal;
