import React, { useState } from "react";
import { useIsMobile } from "../hooks";

function SearchInput({ onChange, value, menuview = false }) {
  // State to track if the input is focused
  const [isFocused, setIsFocused] = useState(false);

  // Handler for input focus
  const handleFocus = () => {
    setIsFocused(true);
  };

  // Handler for input blur
  const handleBlur = () => {
    setIsFocused(false);
  };

  // Conditional styling for the SVG based on the focus state
  const svgFillColor = !isFocused ? "#FFECD7" : "#D2973D";

  const isMobileView = useIsMobile();
  return (
    <div className="relative search-input-wrapper w-full md:w-fit ">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="absolute left-3 md:left-5 top-1/2 -translate-y-1/2"
        role="img"
        aria-label="Search Icon"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 11C5 7.691 7.691 5 11 5C14.309 5 17 7.691 17 11C17 14.309 14.309 17 11 17C7.691 17 5 14.309 5 11ZM20.707 19.293L17.312 15.897C18.365 14.543 19 12.846 19 11C19 6.589 15.411 3 11 3C6.589 3 3 6.589 3 11C3 15.411 6.589 19 11 19C12.846 19 14.543 18.365 15.897 17.312L19.293 20.707C19.488 20.902 19.744 21 20 21C20.256 21 20.512 20.902 20.707 20.707C21.098 20.316 21.098 19.684 20.707 19.293Z"
          fill={svgFillColor} // Use the conditional fill color
        />
        <mask
          id="mask0_1087_9753"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="3"
          y="3"
          width="19"
          height="18"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5 11C5 7.691 7.691 5 11 5C14.309 5 17 7.691 17 11C17 14.309 14.309 17 11 17C7.691 17 5 14.309 5 11ZM20.707 19.293L17.312 15.897C18.365 14.543 19 12.846 19 11C19 6.589 15.411 3 11 3C6.589 3 3 6.589 3 11C3 15.411 6.589 19 11 19C12.846 19 14.543 18.365 15.897 17.312L19.293 20.707C19.488 20.902 19.744 21 20 21C20.256 21 20.512 20.902 20.707 20.707C21.098 20.316 21.098 19.684 20.707 19.293Z"
            fill={svgFillColor}
          />
        </mask>
        <g mask="url(#mask0_1087_9753)">
          <rect width="24" height="24" fill={svgFillColor} />
        </g>
      </svg>
      <input
        type="text"
        placeholder={
          !isMobileView
            ? "Search by name, street & type of place"
            : "Search by name, street or place"
        }
        className={`search-input bg-transparent border-[0.5px] border-[#FFECD7] rounded-full text-sm md:text-xl font-light  pl-10 lg:pl-[56px] w-full max-w-[680px] lg:w-[680px] !outline-none placeholder:text-wrap pr-3 py-3 leading-8  pb-3 h-16 md:h-[unset] md:py-6 placeholder:text-base ${
          menuview ? "xs:pt-0" : ""
        }`}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={onChange}
        value={value}
      />
    </div>
  );
}

export default SearchInput;
