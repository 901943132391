import React from "react";
import close from "../../assest/icons/closearrow.svg"
const Success = ({onClose}) => {
  return (
    <div className="w-[869px] h-[405px] bg-darkgreen py-7 px-7 rounded-[50px] mx-auto">
      <div className="flex justify-center items-center flex-col h-[80%]">
       <h2 className="text-5xl font-bold text-[#fff]">Thank You!</h2>
       <p className="text-4xl font-normal text-[#fff]">We will get back to you within 24hrs</p>
        <button onClick={onClose} className="mt-4 w-[98px] h-11 bg-main-orange-50 rounded-[50px] text-sm font-semibold">
          Close
        </button>
      </div>
    </div>
  );
};

export default Success;
