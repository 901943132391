// Lightbox.js

import React from "react";
import DisplayPDF from "./PDFViewer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
const Lightbox = ({ establishment, isOpen, onClose, file, filetype }) => {
  const isImage = filetype.includes("image");
  return (
    <div
      className={`fixed inset-0 bg-black bg-opacity-50 justify-center items-center z-50 ${
        isOpen ? "flex" : "hidden"
      } w-screen`}
    >
      <div className="bg-bg-main p-4 max-w-5xl w-full rounded-3xl py-4 border border-[#FFFFFF] border-opacity-50">
        <div className="flex justify-end">
          <button
            className="w-[36.25px] h-[36.25px] rounded-full border border-white font-thin text-white flex items-center justify-center hover:bg-darkgreen "
            onClick={onClose}
          >
            <FontAwesomeIcon className="shadow-inner" icon={faTimes} />
          </button>
        </div>

        <div className="flex flex-col items-center justify-center py-4 pb-10">
          <p className="text-[29px] font-semibold text-white ">
            {establishment.name}
          </p>
          <p className="text-base font-normal text-off-gray ">
            {establishment.street}, {establishment.city}, {establishment.state}{" "}
            {establishment.zip}
          </p>
        </div>
        {/* Your lightbox content goes here */}
        {!isImage ? (
          <DisplayPDF filePath={file} />
        ) : (
          <img src={file} alt="menu" />
        )}
      </div>
    </div>
  );
};

export default Lightbox;
