import React from "react";
const DashboardAvatar = ({ imageUrl, title, pubName, month }) => {
  return (
    <div className="flex gap-4 w-[338px]">
      <div className="bg-bg-main w-12 h-12 rounded-full items-center flex justify-center">
        <img src={imageUrl} />
      </div>
      <div>
        <p className="text-[10px] font-medium font-Metropolis text-dark-silver">
          {title}
        </p>
        <h2 className="font-bold text-xl font-Metropolis text-white w-[173px]">
          {pubName}
        </h2>
      </div>
      <p className="text-dark-silver text-base font-semibold font-Mtropolis mt-[-3px]">
        {month}
      </p>
    </div>
  );
};

export default DashboardAvatar;
