import React from "react";
import alert from "../assest/icons/megaphone.svg";
import Button from "./Button";
import Time from "./Time";
function OfferDetailsOverlay({ show, title, desc, startTime, endTime }) {
  return (
    <div
      data-popover
      id="popover-user-profile"
      role="tooltip"
      className={`hidden absolute right-[-400px] top-[-12.5rem] z-[9999]  md:inline-block w-64  text-sm transition-opacity duration-300
       bg-bg-main text-white border-main-orange  border min-w-[500px] rounded-lg shadow-sm pt-4 pb-6 px-3 text-left ${
         !show ? "invisible opacity-0" : "opacity-100 visible"
       }`}
    >
      <div className="flex flex-col gap-y-3 max-w-[388px] mx-auto relative">
        <h6 className="text-2xl font-bold ">{title}</h6>
        <div className="text-[#989995] text-sm font-bold">
          <span className="text-[#989995] font-semibold text-base leading-5">
            {startTime}
          </span>{" "}
          -{" "}
          <span className="text-[#989995] font-semibold text-base leading-5">
            {endTime}
          </span>
        </div>
        <p className="text-sm font-normal">{desc}</p>
      </div>
      {/* <div className="triangle border-main-orange absolute left-0"></div> */}
    </div>
  );
}

export default OfferDetailsOverlay;

export const OfferDetailsModal = ({
  show,
  title,
  desc,
  startTime,
  endTime,
  date,
  setOfferDetailsVisible,
}) => {
  return (
    <div
      className={`fixed z-[9999] top-[50%] left-0 right-0 w-screen h-full translate-y-[-50%] bg-bg-main text-white p-2 transform  border-main-orange  border md:min-w-[500px] mx-auto rounded-lg shadow-sm py-6 px-6 text-left ${
        show ? "block" : "hidden"
      }`}
    >
      <div className="flex flex-col gap-y-10 md:max-w-[388px] mx-auto relative justify-center h-full">
        <h6 className="text-[#fff] text-2xl font-bold bg-[#fff] bg-opacity-10 p-4 text-center rounded">
          {title}
        </h6>
    
        <Time
          endTime={endTime}
          startTime={startTime}
          date={date}
          className={'!text-[#fff] !font-bold !rounded-md !text-center !py-8 !w-full !mx-auto'}
        />
        <p className="text-base leading-7 font-normal bg-bg-time  p-5 rounded-md">{desc}</p>

        <Button
          onClick={() => setOfferDetailsVisible(false)}
          className={
            "hover:bg-white hover:text-bg-main bg-transparent border border-white w-full !h-12 !p-0 max-w-[270px] mx-auto"
          }
        >
          Close
        </Button>
      </div>
    </div>
  );
};

export function BostedHappyHourOverlay({ show }) {
  return (
    <div
      data-popover
      id="popover-user-profile"
      role="tooltip"
      className={`absolute 2xl:right-[-250px] right-0 top-[-6rem] z-[9999]  inline-block w-64 text-sm transition-opacity duration-300
       bg-bg-main text-white border-main-orange  border min-w-[400px] rounded-lg shadow-sm pt-4 pb-6 px-5 ${
         !show ? "invisible opacity-0" : "opacity-1 visible"
       }`}
    >
      <div className="flex  gap-y-3 items-center justify-between mx-auto relative ">
        <div>
          <h6 className="text-2xl font-bold text-[#fff]">BOOSTED HAPPY HOUR</h6>
          <p className="text-sm text-[#fff] font-normal text-center underline mt-2">
            Learn More
          </p>
        </div>
        <img className="w-10 h-10" src={alert} alt="" />
      </div>
      {/* <div className="triangle border-main-orange absolute left-0"></div> */}
    </div>
  );
}
