import React, { useEffect, useState } from "react";
import searchIcon from "../assest/icons/search.svg"; // Correct path to the search icon

const ScrollButton = () => {
  const [isScrollButtonVisible, setIsScrollButtonVisible] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);

  const handleScroll = () => {
    const currentScrollY = window.scrollY;

    if (currentScrollY > 1500 && currentScrollY < lastScrollY) {
      setIsScrollButtonVisible(true);
    } else {
      setIsScrollButtonVisible(false);
    }

    setLastScrollY(currentScrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  const handleButtonClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="fixed bottom-5 right-1/2 transform translate-x-1/2 md:translate-x-0 md:right-5 z-50">
      {isScrollButtonVisible && (
        <button
          className="bg-[#1C454F] hover:bg-[#497986] text-white font-bold py-2 px-4 rounded flex flex-col items-center"
          onClick={handleButtonClick}
        >
          <img src={searchIcon} alt="Search" className="w-6 h-6 mb-1" />
          Explore
        </button>
      )}
    </div>
  );
};

export default ScrollButton;
