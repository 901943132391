import React from "react";
import Skeleton from "react-loading-skeleton";

function SkeletonUI() {
  return (
    <div className="bg-darkgreen min-h-screen h-full rounded-[15px]  my-10">
      {/* Render your skeleton loader here */}
      <div className="flex flex-col md:flex-row py-20 px-[15px] gap-10 lg:gap-20 max-w-[90%] mx-auto  justify-center">
        <div className=" relative">
          <div className="flex gap-7 items-center ">
            <div className="w-24 rounded-md">
              <Skeleton className="h-[70px] w-24" borderRadius={10} />
            </div>
            <div className="w-full max-w-[200px]  rounded-md">
              <Skeleton className="h-[70px]" borderRadius={10} />
            </div>
            <div className="w-[40px] rounded-full ">
              <Skeleton className="h-[40px] rounded-full" borderRadius={200} />
            </div>
          </div>

          <div className="w-full md:w-[500px] mt-10 lg:mt-[5rem] ml-[10%]  md:ml-[20%] relative z-40">
            <Skeleton className="h-[80px]" borderRadius={20} />
          </div>

          <div className="w-full md:w-[500px] mt-8 lg:mt-[4rem]  ml-[0%]">
            <Skeleton className="h-[80px]" borderRadius={20} />
          </div>

          <div className="flex mt-10 lg:mt-24 ml-[10%] items-center gap-10 ">
            <div className="w-[90px] rounded-full ">
              <Skeleton className="h-[90px] rounded-full" borderRadius={200} />
            </div>
            <div className="w-full max-w-[200px]  rounded-md">
              <Skeleton className="h-[90px]" borderRadius={10} />
            </div>
          </div>

          <div className="w-[250px] mt-[4rem]  ml-[10%]">
            <Skeleton className="h-[80px]" borderRadius={200} />
          </div>
        </div>

        <div className="flex items-end gap-10 justify-center">
          <div className="flex relative max-h-fit w-full">
            <div className="overflow-hidden  w-full z-10  lg:min-w-[550px]  img-container shadow-lg relative bg-no-repeat bg-center bg-cover ">
              <Skeleton className="h-[300px] lg:h-[680px] bg-gray-900 " />
            </div>
            <div className="max-h-[620px] absolute w-full h-full">
              <div className=" h-full w-full absolute top-[18px] -right-[15px] img-container bg-[#192d36]" />
              <div className="max-h-[690px] h-full w-full relative top-[36px] -right-[26px] opacity-90 img-container bg-[#192d36]" />
            </div>
          </div>

          <div className="">
            <div className="w-[60px] mt-[4rem]  ml-[10%]">
              <Skeleton className="h-[60px]" borderRadius={200} />
            </div>
            <div className="w-[60px] mt-[1rem]  ml-[10%]">
              <Skeleton className="h-[60px]" borderRadius={200} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function CardSkeleton() {
  return (
    <div className="card text-white flex flex-col gap-y-4 relative">
      <div className="relative w-full max-w-[500px]">
        <Skeleton className="max-w-[500px] w-full object-cover h-[270px] rounded-lg" />
        <div className="absolute bottom-[10%] text-lg left-[5%] w-content py-1 px-4 bg-transparent rounded-full backdrop-blur-xl">
          <Skeleton
            className="w-[90px] h-[90px] rounded-full"
            borderRadius={200}
          />
        </div>
      </div>
      <div className="card-body flex flex-col gap-2 pl-2">
        <Skeleton className="card-title" />
        <Skeleton className="card-street mb-2 text-muted text-[#989995]" />
      </div>
      <div className="flex gap-2">
        <Skeleton className=" rounded-full w-12 h-12 flex items-center justify-center cursor-pointer" />
        <Skeleton className=" rounded-full w-12 h-12 flex items-center justify-center cursor-pointer" />
        <Skeleton className=" rounded-full w-12 h-12 flex items-center justify-center cursor-pointer" />
      </div>
    
      <div className="px-2">
        <Skeleton className="text-xl font-[500]" />
      </div>
      <div className="flex gap-2 p-2">
        <div className="w-[180px] mt-[4rem] ">
          <Skeleton className="h-[60px]" borderRadius={200} />
        </div>
        <div className="w-[180px] mt-[4rem] ">
          <Skeleton className="h-[60px]" borderRadius={200} />
        </div>
      </div>
    </div>
  );
}
export default SkeletonUI;
