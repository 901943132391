// src/store.js
import { configureStore } from '@reduxjs/toolkit';
import citiesReducer from './slices/citySlices';
import promotionsReducer from './slices/promotionsSlices';
import establishmentsReducer from './slices/establishmentsSlice';
import authReducer from './slices/userSlices'
import happyHoursReducer from './slices/EstablishmentsListingSlices'

export default configureStore({
 reducer: {
    cities: citiesReducer,
    promotions: promotionsReducer,
    establishments: establishmentsReducer,
    auth: authReducer,
    happyHours: happyHoursReducer,
 },
});