import axios from "axios";
import { config } from "..";
import { baseId, tableIds } from "../../constant";

const apiFeedbackUrl = `https://api.airtable.com/v0/${baseId}/${tableIds.feedbackTableId}`;

export const PostFeedback = async (data) => {
    try {
        const payload = {
            fields: data,
            typecast: true,
        };
        const response = await axios.post(apiFeedbackUrl, payload, config);
        return response;
    } catch (error) {
        console.error("Error posting data to Airtable:", error);
        throw error; // Re-throw the error to be handled by the calling function
    }
};