import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { apiKey } from '../api';
import { baseId, tableIds } from "../constant";

import oneicon from '../assest/icons/one_icon.svg';
import twoicon from '../assest/icons/two_icon.svg';
import threeicon from '../assest/icons/three_icon.svg';
import fouricon from '../assest/icons/four_icon.svg';
import fiveicon from '../assest/icons/fiveicon.svg';
import naicon from '../assest/icons/na_icon.svg';
import vibeIcon from '../assest/icons/vibe_icon.svg';
import foodIcon from '../assest/icons/food_icon.svg';
import drinkIcon from '../assest/icons/drink_icon.svg';
import closeIcon from '../assest/icons/close_icon.svg';
import voidicon from '../assest/icons/void.svg';
import dullicon from '../assest/icons/dull.svg';
import steadyicon from '../assest/icons/steady.svg';
import vibranticon from '../assest/icons/vibrant.svg';
import blazeicon from '../assest/icons/blaze.svg';
import mysteryicon from '../assest/icons/mystery.svg';

const ratingConfig = {
  1: {
    ratingIcon: oneicon,
    ratingFunIcon: voidicon,
    mainText: 'Void',
    description: 'Extremely poor, widely undesirable',
  },
  2: {
    ratingIcon: twoicon,
    ratingFunIcon: dullicon,
    mainText: 'Dull',
    description: 'Below average, generally unappealing',
  },
  3: {
    ratingIcon: threeicon,
    ratingFunIcon: steadyicon,
    mainText: 'Steady',
    description: 'Average, nothing special but acceptable',
  },
  4: {
    ratingIcon: fouricon,
    ratingFunIcon: vibranticon,
    mainText: 'Vibrant',
    description: 'Very good, pleasant and enjoyable',
  },
  5: {
    ratingIcon: fiveicon,
    ratingFunIcon: blazeicon,
    mainText: 'Blaze',
    description: 'Top-tier, highly recommended',
  },
  6: {
    ratingIcon: naicon,
    ratingFunIcon: mysteryicon,
    mainText: 'Mystery',
    description: ['No reviews or rating yet.', 'This establishment has less than 10 ratings'],
  }
};

const RatingsModal = ({ establishmentName, isOpen, onClose, onRatingFetch, openGiveRatingsModal }) => {
  const [totalRating, setTotalRating] = useState(6); // Default rating is 6
  const [ratingsData, setRatingsData] = useState({ vibeRating: null, foodRating: null, drinksRating: null });

  useEffect(() => {
    if (isOpen) {
      const fetchRating = async () => {
        try {
          const response = await axios.get(`https://api.airtable.com/v0/${baseId}/${tableIds.ratingTableId}`, {
            params: {
              filterByFormula: `FIND('${establishmentName}', {Establishment})`
            },
            headers: {
              Authorization: `Bearer ${apiKey}`
            }
          });

          if (response.data.records.length === 0) return;

          const { fields } = response.data.records[0];
          const { totalRating, vibeRating, foodRating, drinksRating } = fields;

          if (totalRating !== undefined && totalRating !== null) {
            const roundedTotalRating = Math.round(totalRating); // Ensure it's an integer
            setTotalRating(roundedTotalRating);
            setRatingsData({ vibeRating, foodRating, drinksRating });
            onRatingFetch(roundedTotalRating); // Pass the rating to the parent component
          }
        } catch (error) {
          console.error('Error fetching rating:', error.response ? error.response.data : error.message);
        }
      };

      fetchRating();
    }
  }, [isOpen, establishmentName, onRatingFetch]);

  if (!isOpen) return null;

  const validRating = ratingConfig[totalRating] ? totalRating : 6;

  const {
    ratingIcon,
    ratingFunIcon,
    mainText,
    description,
  } = ratingConfig[validRating];

  const { vibeRating, foodRating, drinksRating } = ratingsData;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="relative flex flex-col items-center bg-bg-main-100 text-white p-12 rounded-lg shadow-lg max-w-sm mx-auto">
        <button className="absolute top-3 right-3" onClick={onClose}>
          <img src={closeIcon} alt="Close" className="w-6 h-6" />
        </button>
        <div className="text-lg text-center font-semibold mb-2">{establishmentName}</div>
        <div className="text-base font-thin mb-2">Vibe Meter</div>
        <div className="flex flex-col items-center rounded-full p-2 mb-4">
          <img src={ratingIcon} alt={`Rating ${validRating}`} className="w-24 h-24" />
        </div>
        <div className="flex flex-col items-center bg-white bg-opacity-10 p-4 rounded-lg mb-6">
          <div className="flex items-center mb-2">
            <img src={ratingFunIcon} alt="Rating Icon" className="w-6 h-6 mr-2" />
            <span className="text-xl">{mainText}</span>
          </div>
          <div className="text-sm text-white text-center">
            {Array.isArray(description) ? description.map((line, index) => (
              <p key={index} className={index > 0 ? 'mt-2' : ''}>{line}</p>
            )) : description}
          </div>
        </div>
        <div className="flex justify-between w-full text-center text-sm mb-2">
          <div className="flex flex-col items-center">
            <div className="text-white text-lg mb-2">Vibe</div>
            <div className="text-white text-base mb-2">{vibeRating}</div>
            <img src={vibeIcon} alt="Vibe Icon" className="w-4 h-4 my-1" />
          </div>
          <div className="flex flex-col items-center">
            <div className="text-white text-lg mb-2">Food</div>
            <div className="text-white text-base mb-2">{foodRating}</div>
            <img src={foodIcon} alt="Food Icon" className="w-4 h-4 my-1" />
          </div>
          <div className="flex flex-col items-center">
            <div className="text-white text-lg mb-2">Drinks</div>
            <div className="text-white text-base mb-2">{drinksRating}</div>
            <img src={drinkIcon} alt="Drinks Icon" className="w-4 h-4 my-1" />
          </div>
        </div>
        <div className="text-center text-off-gray text-xs mt-4">
          Scout & Sip Rating for Last 30 days
        </div>
        <button className="mt-4 text-xs text-main-orange-200" onClick={openGiveRatingsModal}>
          Give a Vibe Rating
        </button>
      </div>
    </div>
  );
};

export default RatingsModal;
