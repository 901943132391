function formatDate(dateString) {
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Reset hours, minutes, seconds, and milliseconds to 0

  const inputDate = new Date(dateString);
  inputDate.setHours(0, 0, 0, 0); // Reset hours, minutes, seconds, and milliseconds to 0

  if (isSameDay(inputDate, today)) {
    return "Today";
  } else {
    const dateParts = dateString.split("-");
    const year = dateParts[0];
    const month = dateParts[1];
    const day = dateParts[2];

    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const formattedMonth = months[parseInt(month) - 1];

    return `${formattedMonth} ${day}`;
  }
}

// Function to convert RGB array to Hex string
const rgbToHex = (rgb) => {
  return (
    "#" +
    rgb
      .map((x) => {
        const hex = x.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
      })
      .join("")
  );
};

// const isSameDay = (date1, date2) =>
//   date1.toDateString() === date2.toDateString();

const isSameDay = (date1, date2) =>
  date1.toISOString().slice(0, 10) === date2.toISOString().slice(0, 10);

const createDateWithTimezone = (date, timezone) => {
  return new Date(date.toLocaleString("en-US", { timeZone: timezone }));
};
function isEmpty(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

function generateDateTimeFromDateAndTime(dateStr, timeStr) {
  // Split date string into day, month, and year components
  const [year, month, day] = dateStr.split("-");

  // Split time string into hour, minute, and AM/PM components
  const [time, meridiem] = timeStr.split(/(?=[ap]m)/i);
  const [hour, minute] = time.split(":");

  // Convert hour to 24-hour format
  let hour24 = parseInt(hour, 10);
  if (meridiem.toLowerCase() === "pm" && hour24 !== 12) {
    hour24 += 12;
  } else if (meridiem.toLowerCase() === "am" && hour24 === 12) {
    hour24 = 0;
  }

  // Create a new Date object with the provided date and time
  const combinedDateTime = new Date(year, month - 1, day, hour24, minute);

  return combinedDateTime;
}
// Function to convert time string to minutes past midnight
const timeToMinutes = (timeStr) => {
  if (!timeStr) return
  // Extract the time part and the AM/PM modifier
  const timePart = timeStr?.slice(0, -2);
  const modifier = timeStr?.slice(-2).toLowerCase();

  // Split the time part into hours and minutes
  const [hoursStr, minutesStr] = timePart?.split(":");

  // Convert hours and minutes to integers
  let hours = parseInt(hoursStr, 10);
  const minutes = parseInt(minutesStr, 10);

  // Adjust hours based on the AM/PM modifier
  if (modifier === "pm" && hours !== 12) {
    hours += 12;
  } else if (modifier === "am" && hours === 12) {
    hours = 0;
  }

  // Calculate the total minutes past midnight
  const minutesPastMidnight = hours * 60 + minutes;

  return minutesPastMidnight;
};

// Corrected currentTimeInMinutes function
const currentTimeInMinutes = () => {
  const now = new Date();
  const hours = now.getHours();
  const minutes = now.getMinutes();
  let totalMinutes = hours * 60 + minutes;
  // No need to add 720 minutes for PM as getHours() returns hours in 24-hour format
  return totalMinutes;
};

// src/utils.js
export const normalizeDayName = (day) => {
  const fullDayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const shortDayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const index = fullDayNames.indexOf(day);
  return index !== -1 ? shortDayNames[index] : "";
};

export const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return "";
  const cleaned = ("" + phoneNumber).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return phoneNumber;
};

export const groupHappyHoursByTime = (happyHours) => {
  return happyHours?.reduce((acc, happyHour) => {
    const key = `${happyHour?.fields["Start Time"]}-${happyHour?.fields["End Time"]}`;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(happyHour);
    return acc;
  }, {});
};


export { rgbToHex, createDateWithTimezone, isSameDay, formatDate, isEmpty, generateDateTimeFromDateAndTime, timeToMinutes, currentTimeInMinutes };
