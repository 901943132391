// components/MultiSelect.js

import React from "react";
import Select, { StylesConfig } from "react-select";

const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "#F2EADF",
    height: "100%",
  }),

  multiValue: (styles, { data }) => {
    return {
      ...styles,
      // backgroundColor: color.alpha(0.1).css(),
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ":hover": {
      backgroundColor: data.color,
      color: "#FBBC05",
    },
  }),
};

const MultiSelect = ({
  options,
  value,
  onChange,
  placeholder,
  maxSelectable,
}) => {
  const handleChange = (selectedOptions) => {
    if (selectedOptions.length <= maxSelectable) {
      onChange(selectedOptions);
    }
  };

  return (
    <Select
      isMulti
      value={value}
      onChange={handleChange}
      options={options}
      placeholder={placeholder}
      closeMenuOnSelect={false}
      styles={colourStyles}
      className={`bg-white border-white border rounded-lg h-14 w-full max-w-96 `}
    />
  );
};

export default MultiSelect;
