import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import sliderBtnIcon from "../assest/icons/slider-btn.svg";

const ImageCarousel = ({ images }) => {
  const arrowStyles = {
    position: "absolute",
    top: "calc(50% - 15px)",
    zIndex: 1,
    background: "none",
    border: "none",
    fontSize: 30,
    color: "#000",
    cursor: "pointer",
  };

  const CustomPrevArrow = ({ onClickHandler, hasPrev, label }) => {
    if (!hasPrev) {
      return null;
    }
    return (
      <button
        onClick={onClickHandler}
        title={label}
        style={{ ...arrowStyles, left: 15 }}
      >
        <img
          className="slider-btn opacity-0 invisible cursor-pointer hover:opacity-50 rotate-180 w-9 h-9"
          src={sliderBtnIcon}
          alt="slider"
        />
      </button>
    );
  };

  const CustomNextArrow = ({ onClickHandler, hasNext, label }) => {
    if (!hasNext) {
      return null;
    }
    return (
      <button
        onClick={onClickHandler}
        title={label}
        style={{ ...arrowStyles, right: 15 }}
      >
        <img
          className="slider-btn opacity-0 invisible cursor-pointer hover:opacity-50 w-9 h-9"
          src={sliderBtnIcon}
          alt="slider"
        />
      </button>
    );
  };

  // Calculate the middle index of the images array
  const middleIndex = Math.floor(images.length / 2);

  return (
    <div className="w-full h-full max-h-[270px] max-w-[500px] mb-4">
      <Carousel
        showThumbs={false}
        showStatus={false}
        infiniteLoop={true} // Enable looping
        defaultActiveIndex={middleIndex} // Set the initial active slide to the middle index
        renderArrowPrev={(onClickHandler, hasPrev, label) => (
          <CustomPrevArrow
            onClickHandler={onClickHandler}
            hasPrev={hasPrev}
            label={label}
          />
        )}
        renderArrowNext={(onClickHandler, hasNext, label) => (
          <CustomNextArrow
            onClickHandler={onClickHandler}
            hasNext={hasNext}
            label={label}
          />
        )}
      >
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            className="w-full h-full max-h-[270px] max-w-[500px] opacity-85 object-cover rounded-md"
            alt={`carousel-${index}`}
          />
        ))}
      </Carousel>
    </div>
  );
};

export default ImageCarousel;
