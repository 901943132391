import React, { useEffect, useRef, useState } from "react";
import Lightbox from "./Lightbox";
import Skeleton from "react-loading-skeleton";
import { HappyHourTime } from "./Time";
import ImageCarousel from "./ImageCarousel";
import { currentTimeInMinutes, timeToMinutes } from "../utils";
import RatingsModal from "./RatingsModal";
import GiveRatingsModal from "./GiveRatingsModal";
import { ratingConfig } from "../constant";
import {
  formatPhoneNumber,
  normalizeDayName,
  groupHappyHoursByTime,
} from "../utils";
import {
  useFetchEstablishmentData,
  useFetchRating,
} from "../hooks/useFetchRating";
import { updateEstablishment, updateHappyHourPoll } from "../api";
import {
  createPoll,
  fetchPoll,
  fetchPollByEstablishmentId,
  updatePoll,
} from "../api/poll";
import EstablishmentHappyHoursList from "./EstablishmentHappyHoursList";

const HappyHoursCard = ({
  imageUrl,
  id,
  name,
  street,
  zip,
  days,
  offerColor,
  topOffer,
  daysBg,
  category,
  city,
  happyHours,
  menu,
  state,
  crossStreet,
  carouselImages,
  phoneNumber,
}) => {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [isRatingsModalOpen, setIsRatingsModalOpen] = useState(false);
  const [isGiveRatingsModalOpen, setIsGiveRatingsModalOpen] = useState(false);
  const [pollLoading, setPollLoading] = useState(false);

  const [pollingError, setPollingError] = useState(null);

  const { currentRatingConfig, ratingsData, setCurrentRatingConfig } =
    useFetchRating(name);
  const establishmentData = useFetchEstablishmentData(isRatingsModalOpen, name);

  const [votes, setVotes] = useState(() => {
    const storedVotes = localStorage.getItem("votes");
    return storedVotes ? JSON.parse(storedVotes) : {};
  });

  const happyHourLocation = {
    name: name,
    city: city,
    street: street,
    zip: zip,
    state: state,
    crossStreet: crossStreet,
  };

  const openLightbox = () => setLightboxOpen(true);
  const closeLightbox = () => setLightboxOpen(false);

  const openRatingsModal = () => setIsRatingsModalOpen(true);
  const closeRatingsModal = () => setIsRatingsModalOpen(false);

  const openGiveRatingsModal = () => {
    setIsRatingsModalOpen(false);
    setIsGiveRatingsModalOpen(true);
  };
  const closeGiveRatingsModal = () => setIsGiveRatingsModalOpen(false);

  const handleRatingFetch = (rating) => {
    console.log("Updating rating config with rating:", rating); // Debug log
    setCurrentRatingConfig(ratingConfig[rating]);
  };

  const handleWish = async (establishmentId, dayOfWeek, wish) => {
    setPollLoading(true);
    try {
      const yesCount = wish === "yes" ? 1 : 0;
      const NoCount = wish === "no" ? 1 : 0;
      // Check if poll exists
      let poll = await fetchPollByEstablishmentId(establishmentId);

      // If poll doesn't exist, create it
      if (!poll) {
        poll = await createPoll({
          Establishment: [establishmentId],
          // ['Day Of Week']: [dayOfWeek],
          ["Yes Count"]: yesCount,
          ["No Count"]: NoCount,
        });
      } else {
        // Update the poll
        const updatedPoll = await updatePoll(poll.id, {
          ["Yes Count"]:
            wish === "yes"
              ? poll.fields["Yes Count"] + 1
              : poll.fields["Yes Count"],
          ["No Count"]:
            wish === "no"
              ? poll.fields["No Count"] + 1
              : poll.fields["No Count"],
        });
        // console.log(updatedPoll, "updatedPoll");
      }

      // Update the establishment with the new poll data
      // await updateEstablishment(establishmentId, {
      //   PollId: updatedPoll.id,
      //   WishCount: updatedPoll.fields.WishCount,
      // });

      // Update local storage to track that the user has voted
      const newVotes = { ...votes, [`${establishmentId}-${dayOfWeek}`]: true };
      setVotes(newVotes);
      localStorage.setItem("votes", JSON.stringify(newVotes));

      setPollingError("");
      setPollLoading(false);
      // Optionally, refresh the UI or state to reflect the update
      // Assuming you have a state or context to update. Replace this with your actual logic.
      // e.g., setPoll(updatedPoll);
    } catch (error) {
      setPollLoading(false);
      setPollingError("Failed to update the poll, please try again");
      console.error("Failed to update poll or establishment:", error.message);
      // alert("Failed to update poll. Please try again later.");
    }
  };

  const fullDayNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const [happyHoursDays, setHappyHoursDays] = useState(days);
  const todayIndex = new Date().getDay(); //  0 for Sunday,  1 for Monday, etc.
  const [activeDay, setActiveDay] = useState({
    value: todayIndex,
    day: fullDayNames[todayIndex],
  });

  const [groupedHappyHours, setGroupedHappyHours] = useState([]);

  useEffect(() => {
    if (happyHours && happyHours.length) {
      const updatedDays = days.map((day) => ({
        ...day,
        active: happyHours[0]?.fields["Happy Hour Day"]?.some(
          (hhDay) =>
            normalizeDayName(hhDay).toLowerCase() === day.value.toLowerCase()
        ),
      }));
      setHappyHoursDays(updatedDays);
      setGroupedHappyHours(groupHappyHoursByTime(happyHours));
    }
  }, [happyHours]);

  const topOfferRef = useRef(null);
  const [isTopOfferVisible, setIsTopOfferVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsTopOfferVisible(true);
        } else {
          setIsTopOfferVisible(false);
        }
      },
      { threshold: 0.1 } // Adjust as needed
    );

    if (topOfferRef.current) {
      observer.observe(topOfferRef.current);
    }

    return () => {
      if (topOfferRef.current) {
        observer.unobserve(topOfferRef.current);
      }
    };
  }, [topOfferRef]);

  return (
    <div className="card text-white flex flex-col gap-y-4 relative">
      <div className="relative w-full max-w-[500px]">
        {imageUrl ? (
          <div className="establishment-img-container relative w-full max-h-[270px] rounded-xl">
            <ImageCarousel images={carouselImages} />
          </div>
        ) : (
          <div className="relative w-full max-w-[500px]">
            <Skeleton className="max-w-[500px] w-full object-cover h-[270px] rounded-lg" />
            <div className="absolute bottom-[10%] text-lg left-[5%] w-content py-1 px-4 bg-transparent rounded-full backdrop-blur-xl">
              <Skeleton
                className="w-[90px] h-[90px] rounded-full"
                borderRadius={200}
              />
            </div>
          </div>
        )}
        <button className="absolute top-[5%] text-[#111F24] text-base left-[2.5%] w-content py-1 px-4 rounded-full bg-white">
          {category[0]}
        </button>
      </div>
      {topOffer && (
        <div
          ref={topOfferRef}
          style={{ backgroundColor: offerColor }}
          className={`w-[90px] h-[90px] rounded-full flex justify-center items-center absolute -top-9 right-[12%] ${
            isTopOfferVisible ? "animate-bounce" : ""
          }`}
        >
          <p
            className={`w-[90%] text-sm font-semibold leading-4 text-center transform rotate-12`}
          >
            {topOffer}
          </p>
        </div>
      )}
      <div className="flex justify-between items-start">
        <div className="card-body flex flex-col gap-2 pl-2">
          <h5 className="card-name">{name}</h5>
          <h6 className="card-street mb-2 text-muted text-[#989995] text-xs md:text-base">
            {street}, {city} ID {zip}
          </h6>
        </div>
        <div className="phone flex flex-col items-center gap-1.5">
          {/* <div
            className="flex items-center cursor-pointer space-x-1"
            onClick={openRatingsModal}
          >
            <img
              src={currentRatingConfig.ratingIcon}
              alt="Rating Icon"
              className="w-6 h-6 mr-1"
            />
            <img
              src={currentRatingConfig.ratingFunIcon}
              alt="Fun Icon"
              className="w-4 h-4"
            />
            <div className="text-base text-white ml-2">
              {currentRatingConfig.mainText}
            </div>
          </div> */}
          {phoneNumber && (
            <span className="cursor-pointer text-xs font-light text-white whitespace-nowrap">
              {formatPhoneNumber(phoneNumber)}
            </span>
          )}
        </div>
      </div>

      <DaysComponent
        daysBg={daysBg}
        days={days}
        activeDay={activeDay}
        setActiveDay={setActiveDay}
      />

      <EstablishmentHappyHoursList
        fullDayNames={fullDayNames}
        activeDay={activeDay}
        groupedHappyHours={groupedHappyHours}
        happyHours={happyHours}
        name={name} // Pass establishment name to EstablishmentHappyHoursList
        handleWish={handleWish}
        establishmentId={id}
        votes={votes}
        pollLoading={pollLoading}
      />
      <div className="flex w-full justify-evenly gap-2">
        <GetDirection
          happyHourLocation={happyHourLocation}
          encodeURIComponent={encodeURIComponent}
        />
        {/* menu 
        <button
          onClick={openLightbox}
          className={
            "!bg-darkgreen hidden text-white transition-all hover:border-main-orange w-[140px] border-transparent border mr-2 flex justify-center gap-x-2 items-center h-full rounded-full"
          }
        >
          View Menu
        </button>
        */}
      </div>
      {/* menu */}
      <Lightbox
        establishment={happyHourLocation}
        file={menu?.url}
        filetype={menu?.type}
        isOpen={lightboxOpen}
        onClose={closeLightbox}
      />
      <RatingsModal
        establishmentName={name}
        isOpen={isRatingsModalOpen}
        onClose={closeRatingsModal}
        openGiveRatingsModal={openGiveRatingsModal} // Pass the function to open GiveRatingsModal
        onRatingFetch={handleRatingFetch}
      />
      <GiveRatingsModal
        isOpen={isGiveRatingsModalOpen}
        onClose={closeGiveRatingsModal}
        establishmentId={establishmentData?.id}
        establishmentName={name}
      />
    </div>
  );
};

export default HappyHoursCard;

const GetDirection = ({ happyHourLocation, encodeURIComponent }) => {
  return (
    <div
      onClick={() =>
        window.open(
          `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
            happyHourLocation.name +
              ", " +
              happyHourLocation.street +
              " " +
              happyHourLocation.city
          )}`,
          "_blank"
        )
      }
      className={
        "text-base bg-[#1C454F] bg-opacity-50 text-white rounded-full h-[70px]  w-full items-center max-w-full md:max-w-full flex justify-center px-2 cursor-pointer hover:border-main-orange hover:bg-darkgreen"
      }
    >
      <span className="col-span-3 text-center">Get Directions</span>
    </div>
  );
};

export const DaysComponent = ({ days, daysBg, activeDay, setActiveDay }) => {
  const [showAllDays, setShowAllDays] = useState(false);
  const toggleDaysVisibility = () => {
    setShowAllDays((prevState) => !prevState);
  };
  return (
    <div className={`flex ${showAllDays ? "flex-wrap" : "flex-row"} gap-1.5`}>
      <div className="flex gap-2 ">
        {days.map((day, index) => (
          <div
            key={index}
            onClick={() => setActiveDay({ value: index, day: day })}
            className={`${
              activeDay.value === index
                ? `${daysBg} border-transparent`
                : "border-white opacity-25"
            } border rounded-full ${
              showAllDays ? "w-9 h-9" : "w-12 h-12"
            } transition-all delay-75 flex items-center justify-center cursor-pointer hover:border-2 hover:border-white hover:opacity-100 ${
              !showAllDays && activeDay.value !== index ? "hidden md:flex" : ""
            }`}
          >
            <span
              className={`font-medium ${showAllDays ? "text-xs" : "text-sm"}`}
            >
              {day.value}
            </span>
          </div>
        ))}
      </div>
      <div className="md:hidden flex items-center gap-x-2">
        <div
          onClick={toggleDaysVisibility}
          className={`border-white opacity-50 border rounded-full ${
            showAllDays ? "w-9 h-9" : "w-12 h-12"
          } transition-all delay-75 flex items-center justify-center cursor-pointer`}
        >
          <span className="text-xl font-medium text-[#FBBC05]">
            {showAllDays ? "-" : "+"}
          </span>
        </div>
      </div>
    </div>
  );
};

// function EstablishmentHappyHoursList({
//   groupedHappyHours,
//   activeDay,
//   fullDayNames,
//   happyHours,
//   name,
//   establishmentId,
//   handleWish,
//   votes
// }) {
//   let pollData = {
//     day: fullDayNames[activeDay.value],
//     establishment: name,
//     id: establishmentId,
//     date: new Date(),
//   };

//   return (
//     <div className="px-2">
//       {Object.entries(groupedHappyHours).length > 0 ? (
//         happyHours?.some((item) => {
//           const fields = item?.fields;
//           if (!fields) {
//             return false;
//           }

//           const currentDay = fullDayNames[activeDay.value];
//           return fields["Happy Hour Day"].includes(currentDay) || null;
//         }) ? (
//           Object.entries(groupedHappyHours)
//             .sort(([timeA, happyHoursA], [timeB, happyHoursB]) => {
//               return timeToMinutes(timeA) - timeToMinutes(timeB);
//             })
//             .map(([time, happyHours], index) => {
//               const [startTime, endTime] = time.split("-");
//               const currentDay = fullDayNames[activeDay.value];
//               const validHappyHoursForDay = happyHours?.filter((happyHour) =>
//                 happyHour.fields["Happy Hour Day"].includes(currentDay)
//               );

//               if (validHappyHoursForDay?.length === 0) {
//                 return null;
//               }

//               // Check if current time falls within this happy hour
//               const isCurrentTimeWithinHappyHour =
//                 currentTimeInMinutes() >= timeToMinutes(startTime) &&
//                 currentTimeInMinutes() <= timeToMinutes(endTime);
//               return (
//                 <div key={index} className="my-4">
//                   <HappyHourTime
//                     isCurrentTimeWithinHappyHour={isCurrentTimeWithinHappyHour}
//                     startTime={startTime}
//                     endTime={endTime}
//                   />
//                   {validHappyHoursForDay.map((happyHour, index) => (
//                     <p
//                       key={index}
//                       className={`text-xl my-2 ${
//                         isCurrentTimeWithinHappyHour
//                           ? "font-[700] text-[#fff] sm:text-xl md:text-xl bg-white bg-opacity-5 px-2 py-1 rounded inline-block"
//                           : "font-[300]"
//                       }`}
//                     >
//                       {happyHour.fields["Happy Hour Offer"]}
//                     </p>
//                   ))}
//                 </div>
//               );
//             })
//         ) : (
//           <div className="my-4">
//             <p className="text-base text-[#989995] font-[500] mb-2">{`No Happy Hours for ${
//               fullDayNames[activeDay.value]
//             }`}</p>
//             <div className="bg-white bg-opacity-10 rounded-lg p-2">
//               <p className="text-center mb -1 text-white mt-2">
//                 {`Do you wish ${name}`}
//                 <br />
//                 {`offered a happy hour for ${fullDayNames[activeDay.value]}?`}
//               </p>
//               <div className="flex justify-center space-x-4 mt-3 mb-2">
//                 <button
//                   onClick={() =>
//                     handleWish(
//                       establishmentId,
//                       fullDayNames[activeDay.value],
//                       "yes"
//                     )
//                   }
//                   className="px-4 py-2 bg-yellow-500 text-black font-bold text-xs rounded"
//                 >
//                   Yes
//                 </button>
//                 <button
//                   onClick={() =>
//                     handleWish(
//                       establishmentId,
//                       fullDayNames[activeDay.value],
//                       "no"
//                     )
//                   }
//                   className="px-4 py-2 bg-[#14252B] text-white font-bold text-xs rounded"
//                 >
//                   No
//                 </button>
//               </div>
//             </div>
//           </div>
//         )
//       ) : (
//         <div className="my-4">
//           <p className="text-base text-[#989995] font-[500] mb-2">{`No Happy Hours for ${
//             fullDayNames[activeDay.value]
//           }`}</p>
//           <div className="bg-white bg-opacity-10 rounded-lg p-2">
//             <p className="text-center mb -1 text-white mt-2">
//               {`Do you wish ${name}`}
//               <br />
//               {`offered a happy hour for ${fullDayNames[activeDay.value]}?`}
//             </p>
//             <div className="flex justify-center space-x-4 mt-3 mb-2">
//               <button
//                 onClick={() =>
//                   handleWish(
//                     establishmentId,
//                     fullDayNames[activeDay.value],
//                     "yes"
//                   )
//                 }
//                 className="px-4 py-2 bg-yellow-500 text-black font-bold text-xs rounded"
//               >
//                 Yes
//               </button>
//               <button
//                 onClick={() =>
//                   handleWish(
//                     establishmentId,
//                     fullDayNames[activeDay.value],
//                     "no"
//                   )
//                 }
//                 className="px-4 py-2 bg-[#14252B] text-white font-bold text-xs rounded"
//               >
//                 No
//               </button>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// }
