import React, { useEffect, useState } from 'react';
import { DashboardLayout } from '../../components/Layout';
import Navbar from '../../components/dashboardUIs/Navbar';
import logo from '../../assest/Logo.svg';
import editicon from '../../assest/icons/editicon.svg';
import { LoadingButton } from '../../components/Button';
import DisplayPDF from '../../components/PDFViewer';
import { useSelector } from 'react-redux';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { storage } from '../../firebase'; // Import your firebase storage config
import { fetchEstablishmentDetailById, fetchEstablishmentDetailByName, updateEstablishment } from '../../api/establishment';
import Loader from '../../components/Loader';
import MultiSelect from '../../components/MultiSelect';
import { fetchCategories } from '../../api';
import { updateEstablishmentUser } from '../../api/users';
import { fetchPollByEstablishmentId } from '../../api/poll';

const initialState = {
  phone: '',
  name: '',
  address: '',
  website: '',
  category: '',
  crossstreet: '',
  menu: '',
  avatar: '',
  coverphoto: '',
};

const formFields = [
  { name: "coverphoto", placeholder: "Cover Photo", type: 'img' },
  { name: "menu", placeholder: "Menu", type: 'img' },
  { name: "name", placeholder: "Business Name", type: 'text' },
  { name: "avatar", placeholder: "Avatar", type: 'img' },
  { name: "address", placeholder: "Business Address", type: 'text' },
  { name: "crossstreet", placeholder: "Major Cross Streets", type: 'text' },
  { name: "website", placeholder: "Website", type: 'text' },
  { name: "phone", placeholder: "Phone Number", type: 'phone' },
  { name: "category", placeholder: "Business Category (up to 3)", type: 'text' },
];

function BussinessInfo() {
  const { list: cities } = useSelector((state) => state.cities);
  const establishment = useSelector((state) => state.establishments.userEstablishment);

  const [formValue, setFormValue] = useState(initialState);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [userDetail, setUserDetail] = useState(localStorage.getItem("selectedEstablishment"));
  const [categories, setCategories] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [error, setError] = useState('')
  const [isDataFetching, setIsDataFetching] = useState(true)

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValue({ ...formValue, [name]: value });
  };
  const handleFetchData = async (estid) => {
    try {
      if (!estid) {
        console.error("please select an establishment to update.");
        return;
      }
      const data = await fetchEstablishmentDetailById(estid);

      const extractDetails = {
        name: data?.fields.Name,
        category: data?.fields.category.map((cat) => ({ value: cat, label: cat })),
        coverphoto: data.fields['cover-image'][0]?.url || '',
        avatar: data.fields['profile-image'][0]?.url || '',
        website: data?.fields.Website || '',
        address: data.fields['street 1'],
        phone: data.fields['Phone Number'] || '',
        crossstreet: data.crossStreets,
        happyHours: data.happyHours,
        menu: data?.fields.menu[0]?.url || '',
        zip: data?.fields.zip || '',
        state: data.fields['State (from state)'][0] || '',
        city: data?.fields.city,
      };
      setData({ ...data, extractDetails });
      setFormValue((prev) => ({ ...prev, ...extractDetails }));
      setIsDataFetching(false)

    } catch (error) {
      setIsDataFetching(false)
      console.error("Error fetching establishment:", error);
    }
  };


  const LoadCategoriesData = async () => {
    try {
      const data = await fetchCategories();
      const formattedCategories = data.map((category) => ({
        value: category,
        label: category,
      }));
      setCategories(formattedCategories);
    } catch (error) {
      console.error("Error fetching categories:", error);
      // setError("Failed to load category data");
    }
  };


  const handleFileUpload = (event, fieldName) => {
    const file = event.target.files[0];
    if (!file) return;

    const storageRef = ref(storage, `${fieldName}/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    setFormValue((prevData) => ({
      ...prevData,
      [`${fieldName}Loading`]: true,
    }));

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(`Upload is ${progress}% done`);
      },
      (error) => {
        console.error("Upload failed:", error);
        setFormValue((prevData) => ({
          ...prevData,
          [`${fieldName}Loading`]: false,
        }));
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setFormValue((prevData) => ({
            ...prevData,
            [fieldName]: url,
            [`${fieldName}Loading`]: false,
          }));
        });
      }
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    const user = JSON.parse(localStorage.getItem("user"));
    // return 
    try {

      const extractCategory = formValue.category.map((cat) => cat.value);

      // Define fields to exclude from data.fields
      const fieldsToExclude = ['State (from state)'];

      // Filter out unwanted fields from data.fields
      const filteredDataFields = Object.keys(data.fields)
        .filter(key => !fieldsToExclude.includes(key))
        .reduce((acc, key) => {
          acc[key] = data.fields[key];
          return acc;
        }, {});

      const attachmentMenuObject = [
        {
          url: formValue.menu,
        },
      ];

      const attachmentProfileObject = [
        {
          url: formValue.avatar,
        },
      ];

      const attachmentCoverPhotoObject = [
        {
          url: formValue.coverphoto,
        },
      ];

      const formData = {
        ...filteredDataFields,
        Name: formValue.name,
        Website: formValue.website,
        ['Phone Number']: formValue.phone,
        ['street 1']: formValue.address,
        ['profile-image']: attachmentProfileObject,
        ['cover-image']: attachmentCoverPhotoObject,
        menu: attachmentMenuObject,
        category: extractCategory,
      };

      // console.log(formData);
      // return

      const updatedEstablishment = await updateEstablishmentUser(data.id, formData);

      // updating user value in the localstorage
      // Assuming updatedEstablishment.fields.Name contains the new establishment name
      const updatedEstablishmentName = updatedEstablishment?.fields?.Name;

      // Update the Establishment field within the fields object of the user
      const updatedUserValue = {
        ...user,
        fields: {
          ...user.fields,
          Establishment: updatedEstablishmentName, // Update the specific field you want to change
        },
      };

      localStorage.setItem("user", JSON.stringify(updatedUserValue)); // Ensure to stringify the object when setting it in localStorage
      setSuccessMessage('Update successful');
    } catch (error) {
      console.error("Error updating establishment:", error);
      setError('Update failed');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCategoryChange = (selectedOptions) => {
    setFormValue((prev) => ({
      ...prev,
      category: selectedOptions,
    }));
  };

  useEffect(() => {
    const userDetail = localStorage.getItem("selectedEstablishment");
    setUserDetail(userDetail);
    LoadCategoriesData()
  }, []);

  useEffect(() => {
    if (establishment) {
      const extractDetails = {
        name: establishment?.fields?.Name,
        category: establishment?.fields?.category.map((cat) => ({ value: cat, label: cat })),
        coverphoto: establishment?.fields['cover-image'][0]?.url || '',
        avatar: establishment?.fields['profile-image'][0]?.url || '',
        website: establishment?.fields?.Website || '',
        address: establishment?.fields['street 1'],
        phone: establishment?.fields['Phone Number'] || '',
        crossstreet: establishment?.crossStreets,
        happyHours: establishment?.happyHours,
        menu: establishment?.fields?.menu[0]?.url || '',
        zip: establishment?.fields?.zip || '',
        state: establishment?.fields['State (from state)'][0] || '',
        city: establishment?.fields?.city,
      };
      setData({ ...establishment, extractDetails });
      setFormValue((prev) => ({
        ...prev,
        ...extractDetails,
      }));
      setIsDataFetching(false)
    } else {
      handleFetchData(userDetail);
    }
  }, [establishment]);

  return (
    <DashboardLayout>
      <div className="container mx-auto px-[50px] mb-2">

        {isDataFetching ? <Loader /> : <>
          <Navbar cities={cities} title="Business Information" establishmentDetail={data?.extractDetails} />

          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-2 items-center pt-11 pb-8 gap-[20px] max-w-[80%] ">
              {formFields.map((field) => {
                return (
                  <div className="flex flex-col" key={field.name}>
                    {field.type === 'img' ? (
                      <>
                        <label className='text-white text-xl font-semibold leading-7 mb-2' htmlFor={field.name}>{field.placeholder}</label>
                        <div className="relative">
                          <div className={`rounded-2xl flex items-center justify-center bg-darkgreen w-[350px] h-[190px] relative ${field.name === 'avatar' && '!w-24 !h-24 '}`}>
                            <input
                              type="file"
                              accept="image/*"
                              onChange={(event) => handleFileUpload(event, field.name)}
                              className="opacity-0 absolute cursor-pointer"
                              style={{ width: "100%", height: "100%" }}
                            />
                            {formValue[field.name] ? (<>
                              <img src={formValue[field.name]} alt={field.placeholder} className={`w-full h-full object-cover rounded-2xl ${formValue[`${field.name}Loading`] && ' opacity-30'}`} />
                              {formValue[`${field.name}Loading`] && (
                                <Loader />)}
                            </>

                            ) : (
                              <div className="w-full h-full relative flex items-center justify-center bg-darkgreen rounded-2xl">
                                <img className='w-20 object-fit' src={logo} alt="logo" />
                                {/* {formValue[`${field.name}Loading`] && (
                                <Loader />)} */}
                              </div>
                            )}
                            <img className='w-8 object-fill absolute bottom-2 right-2 shadow cursor-pointer -z-0' src={editicon} alt="edit" />
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        {field.name !== 'crossstreet' && field.name !== 'category' ?
                          (<>
                            <label className='text-white text-xl font-semibold leading-7 mb-2' htmlFor={field.name}>{field.placeholder}</label>
                            <input
                              type={field.type}
                              name={field.name}
                              value={formValue[field.name]}
                              placeholder={field.placeholder}
                              onChange={handleInputChange}
                              className={`bg-white border-white border rounded-lg h-14 w-full max-w-96 pl-5 `}
                            />
                          </>)
                          :
                          <>
                            {field.name === 'crossstreet' && <div>
                              <label className='text-white text-xl font-semibold leading-7 mb-2' htmlFor="">
                                {field.placeholder}
                              </label>
                              {formValue.crossstreet && formValue?.crossstreet?.map((data) => {
                                return (
                                  <input
                                    type={field.type}
                                    name={field.name}
                                    value={data?.fields?.Name}
                                    placeholder={field.placeholder}
                                    onChange={handleInputChange}
                                    className={`bg-white my-4 border-white border rounded-lg h-14 w-full max-w-96 pl-5`}
                                  />
                                )
                              })}
                            </div>}
                            {/* Add the MultiSelect component for categories */}
                            {field.name === 'category' &&
                              <div className="flex flex-col">
                                <label className='text-white text-xl font-semibold leading-7 mb-2' htmlFor="">
                                  {field.placeholder}
                                </label>
                                <MultiSelect
                                  options={categories}
                                  value={formValue.category}
                                  onChange={handleCategoryChange}
                                  placeholder="Select up to 3 categories"
                                  maxSelectable={3}
                                />
                              </div>
                            }
                          </>
                        }
                      </>
                    )}
                  </div>
                );
              })}

              <LoadingButton className={'uppercase !bg-darkgreen !rounded-md !text-[#fff] !font-bold !text-lg !max-w-52 !mr-24 hover:opacity-75'} isLoading={isLoading}>
                SAVE CHANGES
              </LoadingButton>
            </div>
          </form>
        </>}
      </div>
    </DashboardLayout>
  );
}

export default BussinessInfo;

