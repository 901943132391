import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import PromotionCarousel from "../components/sections/PromotionCarousel";
import HappyHoursListing from "../components/sections/HappyHoursList";
import { fetchEstablishmentsByCity, fetchAllEstablishments } from "../redux/slices/establishmentsSlice";
import { fetchAndProcessPromotions, resetPromotions } from "../redux/slices/promotionsSlices";
import Layout from "../components/Layout";
import { loadCrossStreets } from "../redux/slices/EstablishmentsListingSlices";

function HomePage() {
  const dispatch = useDispatch();
  const { list: cities, selectedCity } = useSelector((state) => state.cities);
  const { byCity: establishments, all } = useSelector((state) => state.establishments);
  const promotions = useSelector((state) => state.promotions);

  const [error, setError] = useState('');
  const [cardLoading, setCardLoading] = useState(true);
  const [loading, setLoading] = useState(true);

  const selectedCityPromotionIds = useMemo(() => selectedCity?.Promotions, [selectedCity]);
  const selectedEstablishmentsIds = useMemo(() => selectedCity?.Establishments, [selectedCity]);

  useEffect(() => {
    if (selectedCityPromotionIds && selectedEstablishmentsIds) {
      setLoading(true);
      Promise.all([
        dispatch(fetchAndProcessPromotions(selectedCityPromotionIds)),
        dispatch(fetchEstablishmentsByCity(selectedEstablishmentsIds))
      ]).then(() => setLoading(false))
        .catch((error) => {
          console.error('Error fetching data:', error);
          setError('Failed to load data');
        });
    } else {
      dispatch(resetPromotions());
      setError('');
    }
  }, [selectedCity, selectedCityPromotionIds, selectedEstablishmentsIds, dispatch]);

  useEffect(() => {
    dispatch(fetchAllEstablishments())
      .then(() => setCardLoading(false))
      .catch((error) => {
        console.error('Error fetching all establishments:', error);
        setError('Failed to load all establishments');
      });
    dispatch(loadCrossStreets())
      .then(() => setCardLoading(false))
      .catch((error) => {
        console.error('Error loading cross streets:', error);
        setError('Failed to load cross streets');
      });
  }, [dispatch]);

  // Shuffle the establishments array
  const shuffledAll = useMemo(() => {
    return shuffleArray([...all]);
  }, [all]);

  // Shuffle function
  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  return (
    <Layout>
      {error && <div className="text-white text-center mt-4">{error}</div>}
      {promotions.length > 0 && (
        <PromotionCarousel
          city={selectedCity?.label}
          establishment={establishments}
          promotionsData={promotions}
          loading={loading}
        />
      )}
      <HappyHoursListing
        data={shuffledAll} // Use shuffledAll here
        cities={cities}
        selectedCity={selectedCity?.value}
        cardLoading={cardLoading}
        hasPromos={promotions?.length > 0}
      />
    </Layout>
  );
}

export default HomePage;
