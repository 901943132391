// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyCFh5p08SM9PJ1lqcWCOADVkz4vn-0djDg",
    authDomain: "scout-sip.firebaseapp.com",
    projectId: "scout-sip",
    storageBucket: "scout-sip.appspot.com",
    messagingSenderId: "16356555762",
    appId: "1:16356555762:web:7b0787d8d212ab283b896b",
    measurementId: "G-JB30WKB6BE"
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

export { storage };