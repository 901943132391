import React, { useState } from "react";
import bottle from "../assest/images/bottle.png";
import SignUpModal from "./sections/SignUpModal";
function AdContainer({ handleAdClose, cities }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSignUpClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="hidden md:hidden px-3 py-4 border-4 border-dotted border-main-orange rounded-lg relative top-4 right-0 max-w-[365px]">
      <div className="flex gap-x-2 items-center">
        <a
          className="btn text-white absolute -top-5 -right-3 cursor-pointer"
          onClick={handleAdClose}
        >
          x
        </a>
        <img src={bottle} alt="icon" />
        <div className="text-base text-white font-bold w-[118px] ml-4">
          NEVER MISS A HAPPY HOUR DEAL AGAIN!
        </div>
        <button
          onClick={handleSignUpClick}
          className="bg-btn-main text-xs font-bold w-28 h-7 text-center text-white ml-2 rounded-md"
        >
          sign up
        </button>
        <SignUpModal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          cities={cities}
        />
      </div>
    </div>
  );
}

export default AdContainer;


export const BoostedHappyHour = () =>{
  return(
    <div className="bg-bg-main border-2 border-main-orange">
      <h4 className="text-sm font-semibold text-[#fff]">BOOSTED HAPPY HOUR</h4>
      <span className="text-[#fff] text-xs font-semibold">Learn More</span>
    </div>
  )
}