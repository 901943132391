import React from "react";
import img from "../../assest/icons/delete.svg";
import Modal from "react-modal";
import { Link } from "react-router-dom";

function ClaimListingModal({ error, open, onClose }) {
  return (
    <Modal
      isOpen={open}
      onRequestClose={onClose}
      overlayClassName=" delete-overlay"
      className=" max-w-[440px] h-[300px] bg-darkgreen mx-auto translate-y-1/2 rounded p-8 flex items-center justify-center"
    >
      <div className="flex flex-col justify-center items-center space-y-6 relative ">
        <button
          onClick={onClose}
          className="absolute -top-0 -right-0 text-white text-4xl"
        >
          {" "}
          &times;
        </button>
        <img src={img} className="w-16 h-16" alt="" />
        <div className="flex flex-col space-y-1 text-center">
          <p className="font-bold text-red">{error}</p>
        </div>

        <div className="flex space-x-2">
          <Link to={"/claim_listing"}>
            <button className="h-12 w-44 bg-main-orange-200 hover:bg-main-orange-300 font-bold text-black border border-transparent rounded">
              Go to Claim Listings
            </button>
          </Link>
          <button
            onClick={onClose}
            className="h-12 w-44 border border-white rounded text-white"
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default ClaimListingModal;
