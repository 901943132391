import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/dashboardUIs/Navbar";
import Chart from "../components/dashboardUIs/Chart";
import DashboardHourChart from "../components/dashboardUIs/DashboardHourChart";
import ScoutDetails from "../components/dashboardUIs/ScoutDetails";
import Halo from "../components/dashboardUIs/Halo";
import alert from "../../src/assest/icons/dashboardalert.svg";
import hours from "../../src/assest/icons/dashboardhour.svg";
import chart from "../../src/assest/icons/chat.svg";
import eye from '../assest/icons/eye.svg';
import Welcome from "../components/dashboardUIs/Welcome";
import { DashboardLayout } from "../components/Layout";
import { fetchEstablishmentByUser, setUserEstablishment } from '../redux/slices/establishmentsSlice';
import { fetchEstablishmentDetailById } from "../api/establishment";

const chatdata = {
  imageUrl: hours,
  title: "METRICS",
  pubName: "BOOSTED HAPPY HOUR",
  month: "Jan 26th",
};

const hourchartdata = {
  imageUrl: alert,
  title: "KEEP THEM UPDATED!",
  pubName: "HAPPY HOURS",
  month: "",
};

const garphdata = {
  imageUrl: chart,
  title: "METRICS FOR",
  pubName: "FEBRUARY",
  month: "",
};

const Dashboard = () => {
  const user = useSelector((state) => state.auth.user);
  const establishment = useSelector((state) => state.establishments.userEstablishment);
  const establishmentError = useSelector((state) => state.establishments.error);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [welcome, setWelcome] = useState(true);
  const [selectedEstablishment, setSelectedEstablishment] = useState(null);
  const [establishemtRelatedToUser, setEstablishmentsRealtedToThisAccount] = useState([]);

  useEffect(() => {
    if (user) {
      const userDetail = JSON.parse(localStorage.getItem("user"));
      if (userDetail && userDetail.establishments) {
        const { establishments } = userDetail;
        const fetchPromises = establishments.map((est) =>
          fetchEstablishmentDetailById(est.id).then(response => ({
            id: est.id,
            response
          }))
        );
        Promise.all(fetchPromises).then(fetchedEstablishments => {
          setEstablishmentsRealtedToThisAccount(fetchedEstablishments);
        }).catch(error => {
          console.error("Error fetching establishments:", error);
        });
      } else {
        dispatch(fetchEstablishmentByUser(userDetail));
      }
    }
  }, [user, dispatch]);

  if (!user) {
    return (
      <div className="container mx-auto px-[50px] pt-[33px] mb-2">
        <h2 className="text-lg text-white font-semibold">Please log in first.</h2>
        <button className="text-white underline underline-offset-2" onClick={() => navigate('/login')}>Log In</button>
      </div>
    );
  }

  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    localStorage.setItem("selectedEstablishment", value.id);
    setSelectedEstablishment(value);
  };

  const updateUserEstablishment = (newEstablishment) => {
    dispatch(setUserEstablishment(newEstablishment));
  };

  return (
    <DashboardLayout>
      {welcome ? (
        <Welcome
          user={user}
          establishments={establishemtRelatedToUser}
          showInfo={() => {
            setWelcome(false);
            navigate('/dashboard/business_info');
            updateUserEstablishment(selectedEstablishment);
          }}
          selectedEstablishment={selectedEstablishment}
          handleInputChange={handleInputChange}
          establishmentError={establishmentError}
        />
      ) : (
        <div className="container mx-auto px-[50px] pt-[33px] mb-2">
          <Navbar title={"Overview"} />
          <div className="flex items-center pt-11 pb-8 gap-[30px]">
            <div className="w-[706px] h-[60px] rounded-2xl bg-navy-blue flex justify-center items-center">
              <p className="text-white text-sm font-Metropolis font-light">
                <span className="font-bold text-white">Boise Exclusive:</span>{" "}
                Only Three Happy Hours Featured Daily for Unique Visibility and Appeal.
              </p>
            </div>
            <div className="w-[338px] h-[60px] rounded-2xl bg-darkgreen flex justify-center items-center">
              <img src={eye} alt="views" className="mr-3" />
              <p className="font-bold text-white text-sm">
                265 profile views since Jan 1, 2024
              </p>
            </div>
          </div>
          <div className="flex gap-[30px]">
            <Chart chatdata={chatdata} />
            <DashboardHourChart hourchartdata={hourchartdata} />
            <Chart bgColor="bg-navy-blue" chatdata={garphdata} />
          </div>
          <div className="pt-[30px] flex gap-[30px]">
            <ScoutDetails />
            <Halo />
          </div>
        </div>
      )}
    </DashboardLayout>
  );
};

export default Dashboard;
