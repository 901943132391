import React, { useEffect, useState } from "react";
import Logo from "../assest/Logo.svg";
import BetaLogo from "../assest/icons/ScoutSipBetaLogo.svg";
import menu from "../assest/icons/hamburger.svg";
import Button from "./Button";
import SelectCity from "./SelectCity";
import { Link, useLocation } from "react-router-dom";
import SearchInput from "./search";
import filterIcon from "../assest/icons/filter.svg";
import live from "../assest/icons/live.svg";
import wine from "../assest/icons/wine.svg";
import music from "../assest/icons/music.svg";
import cocktails from "../assest/icons/coctail-glass.svg";
import outdoor from "../assest/icons/outdoor.svg";
import craft from "../assest/icons/craft.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  setSearchQuery,
  updateSelectedFilters,
  setIsLive,
} from "../redux/slices/EstablishmentsListingSlices";

const Header = ({ cities = [], onCitySelect, defaultCity }) => {
  const dispatch = useDispatch();
  const { selectedFilters, searchQuery, isLive } = useSelector(
    (state) => state.happyHours
  );

  const [city, setCity] = useState(cities);

  useEffect(() => {
    const sortedOptions = [...cities].sort((a, b) =>
      a.label.localeCompare(b.label)
    );
    setCity(sortedOptions);
  }, [cities]);

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/" && location.hash === "#hours-listing") {
      const element = document.getElementById("hours-listing");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  const handleChange = (event) => {
    onCitySelect(event);
  };

  const navContainerClasses =
    "max-w-[1360px] mx-auto h-[70px] flex lg:flex-row justify-between items-center lg:items-center md:pl-0 w-full px-3";
  const buttonClasses = "hover:bg-white font-medium hover:text-bg-main";

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const features = [
    { value: "Live Now!", icon: live, alt: "alt" },
    { value: "Live Music", icon: music, alt: "alt" },
    { value: "Wine", icon: wine, alt: "wine" },
    { value: "Craft Beer", icon: craft, alt: "alt" },
    { value: "Outdoor Seating", icon: outdoor, alt: "alt" },
    { value: "Cocktails", icon: cocktails, alt: "cocktails" },
  ];

  const handleLiveFilter = (isLive) => dispatch(setIsLive(isLive));
  const handleUpdatefilter = (filterKey, selectedValue, isChecked) =>
    dispatch(updateSelectedFilters({ filterKey, selectedValue, isChecked }));

  const handleCheckboxChange = (filterKey, selectedValue, event) => {
    const isChecked = event.target.checked;

    if (selectedValue === "Live Now!") {
      handleLiveFilter(isChecked);
    } else {
      handleUpdatefilter(filterKey, selectedValue, isChecked);
    }
  };

  const handleSearch = (e) => dispatch(setSearchQuery(e.target.value));

  const IsLiveFilterActive = (item) => item === "Live Now!" && isLive;

  const checkedCount = Object.values(selectedFilters).reduce(
    (count, filterValues) => count + filterValues.length,
    0
  );

  return (
    <>
      <div className="bg-bg-main py-4 md:pt-7">
        <nav className={navContainerClasses}>
          <Link to={"/"}>
            <img className="h-10 lg:h-auto" src={BetaLogo} alt="scout-sip" />
          </Link>
          <SelectCity
            handleChange={handleChange}
            defaultCity={defaultCity}
            options={city}
          />

          <div className="lg:order-3 hidden lg:flex justify-between items-center lg:gap-[50px] mt-5 lg:mt-0">
            <Link to={{ pathname: "/", hash: "#hours-listing" }}>
              <h1 className="hover:text-main-orange cursor-pointer hidden lg:block text-lg font-medium text-white">
                Happy Hours
              </h1>
            </Link>
            <Link to="/list-your-business">
              <Button className={buttonClasses}>List Your Business</Button>
            </Link>
          </div>

          <button className="md:hidden cursor-pointer" onClick={toggleMenu}>
            <img className="h-10 object-fill" src={menu} alt="scout-sip" />
          </button>
        </nav>
      </div>

      {/* mobile menu */}
      <div
        className={`fixed z-50 top-0 left-0 w-full h-full bg-bg-main text-white p-2 transform pb-10 overflow-auto ${
          menuOpen ? "translate-x-0" : "translate-x-full"
        } transition-transform duration-300 ease-in-out`}
      >
        <div className="h-full flex flex-col justify-between px-4 xs:pt-2 py-5">
          <div className="w-full">
            <div className="flex justify-between items-center">
              <Link to={"/"}>
                <img className="h-12 lg:h-auto" src={Logo} alt="scout-sip" />
              </Link>

              <button
                className="text-white text-4xl font-normal focus:outline-none"
                onClick={toggleMenu}
              >
                &times;
              </button>
            </div>
            
            <hr className="my-6" />

            <div className="grid grid-cols-2 flex-col my-4 md:flex-row gap-x-16 md:gap-x-8 xs:gap-y-0 gap-y-2 md:gap-y-5  flex-wrap">
              <Link to="/">
                <div className="cursor-pointer hover:text-main-orange text-sm md:text-sm font-normal leading-[38.444px] text-white font-Metropolis mb-2 md:mb-0">
                  Happy Hours
                </div>
              </Link>
              <Link to="/feedback">
                <div className="cursor-pointer hover:text-main-orange text-sm md:text-sm font-normal leading-[38.444px] text-white font-Metropolis mb-2 md:mb-0">
                  Feedback
                </div>
              </Link>
              <Link to="/request-city">
                <div className="cursor-pointer hover:text-main-orange text-sm md:text-sm font-normal leading-[38.444px] text-white font-Metropolis mb-2 md:mb-0">
                  Request a city
                </div>
              </Link>
              <Link to="/login">
                <div className="cursor-pointer hover:text-main-orange text-sm md:text-sm font-normal leading-[38.444px] text-white font-Metropolis mb-2 md:mb-0">
                  Sign Up
                </div>
              </Link>
              <Link to="/claim-listing">
                <div className="cursor-pointer hover:text-main-orange text-sm md:text-sm font-normal leading-[38.444px] text-white font-Metropolis mb-2 md:mb-0">
                  Claim a Listing
                </div>
              </Link>
            </div>

            <Link to="/list-your-business">
              <Button
                className={
                  "hover:bg-white hover:text-bg-main w-full bg-main-orange-50 !h-14 mb-10 !font-semibold !text-xl !text-black"
                }
              >
                List Your Business
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
