import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faTimes } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import { RequestSupport } from "../../api/users"; // Import RequestSupport
import Success from "./Success";
import { LoadingButton } from "../Button";

const Support = ({ open = true, closeModal }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [formData, setFormData] = useState({
    selectedPriority: "",
    question: ""
  });

  const user = useSelector((state) => state.auth.user);

  const [isSuccess, setIsSucess] = useState(false);
  const [loading, setLoading] = useState(false);

  const closeDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleChange = (value, field) => {
    setFormData(prevState => ({
      ...prevState,
      [field]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const data = {
        Question: formData.question,
        Establishments: user.fields.Establishments,
        Priority: formData.selectedPriority
      };
      const response = await RequestSupport(data);
      if (response.status === 200) {
        setIsSucess(true);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error submitting support request:", error);
    }
  };

  const handleCloseSuccessModal = () => {
    setIsSucess(false);
    closeModal();
  };

  const options = [
    { id: 1, value: 'Low' },
    { id: 2, value: 'Medium' },
    { id: 3, value: 'Very high' }
  ];

  return (
    <Modal
      isOpen={open}
      onRequestClose={closeModal}
      overlayClassName="signUpModalOverlay"
      className="signUpModal bg-darkgreen min-h-[378px] max-h-[550px] rounded-xl max-w-[1085.78px] w-full mx-auto h-[75vh] !p-0"
    >
      <div className="relative w-full min-h-64 rounded-[20px] flex flex-col p-8 gap-4 h-full justify-center items-center">
        <button
          className="w-[36.25px] h-[36.25px] rounded-full border border-white font-thin text-white flex items-center justify-center bg-bg-main absolute left-5 top-4 z-[999]"
          onClick={closeModal}
        >
          <FontAwesomeIcon className="shadow-inner" icon={faTimes} />
        </button>
        {!isSuccess ? (
          <div className="w-full max-w-[80%] mx-auto">
            <div className="flex justify-between mb-3">
              <div>
                <p className="text-white text-xl font-semibold font-Metropolis">
                  How can we help you?
                </p>
                <p className="font-normal text-sm font-Metropolis text-white">
                  Please let us know what issues, feedback or ideas you have!
                </p>
              </div>
              <div className="w-[200px] h-10 bg-bg-main rounded-full flex justify-center items-center">
                <p className="text-[14px] font-semibold font-Metropolis text-white px-4 whitespace-nowrap">
                  24hr Max Response Time
                </p>
              </div>
            </div>
            <hr className="!text-navy-blue my-3" />
            <div className="pb-2.5">
              <DropdownOpen title={formData.selectedPriority} filterOpen={dropdownOpen} filterOptions={options} closeDropdown={closeDropdown} handleChange={(value) => handleChange(value, 'selectedPriority')} selectedPriority={formData.selectedPriority} />
            </div>
            <form onSubmit={handleSubmit}>
              <textarea
                className="text-white border border-main-orange-50 text-lg font-normal font-Metropolis px-3 py-2 w-full h-[170px] rounded-2xl lg:py-5 lg:px-5 outline-none resize-none bg-bg-main-100"
                placeholder="Please let us know how we can help you."
                value={formData.question}
                onChange={(e) => handleChange(e.target.value, 'question')}
              />
              <LoadingButton className={'mt-2.5 !w-[140px] !h-11 bg-main-orange-50 rounded-[50px] text-sm font-semibold font-Metropolis'} isLoading={loading}>
                Submit
              </LoadingButton>
            </form>
          </div>
        ) : (
          <Success onClose={handleCloseSuccessModal} />
        )}
      </div>
    </Modal>
  );
};

export default Support;

const DropdownOpen = ({ title, filterOpen, filterOptions, closeDropdown, handleChange, selectedPriority }) => {
  return (
    <div className="flex flex-col relative">
      <div
        className={`max-w-[200px] h-[45px] py-2 ${filterOpen ? "border-main-orange-50" : ""
          } flex items-center justify-between border border-main-orange-50 rounded-[10px] px-3 mb-3 cursor-pointer bg-bg-main-100 text-white`}
        onClick={closeDropdown}
      >
        {title ? title : 'Support Priority'}{" "}
        <FontAwesomeIcon icon={filterOpen ? faChevronDown : faChevronUp} />
      </div>

      {filterOpen && <div
        className={`absolute top-14 transition-all delay-100 min-w-[200px] border border-[#989995] border-colapse rounded-[10px] p-3 bg-bg-main-100 text-white max-h-56 overflow-x-hidden overflow-y-auto customize_scroll_white 
          ${filterOpen ? '!opacity-100 !visible' : ' opacity-0 invisible'}}`}
      >
        {filterOptions.map((filter) => {
          return (
            <ul className="flex flex-col ">
              <li className="flex gap-2 items-center ">
                <div className="checkbox-wrapper-4">
                  <input
                    className="inp-cbx"
                    id={filter.value}
                    type="checkbox"

                    checked={selectedPriority === filter.value}
                    onChange={() => {
                      closeDropdown()
                      handleChange(filter.value)
                    }} // Update the selected priority on change
                  />
                  <label
                    className="cbx"
                    htmlFor={filter.value}
                  >
                    <span>
                      <svg width="12px" height="10px">
                        <use xlinkHref="#check-4"></use>
                      </svg>
                    </span>
                    <span class="label">{filter.value}</span>
                  </label>
                  <svg className="inline-svg">
                    <symbol id="check-4" viewBox="0  0  12  10">
                      <polyline points="1.5  6  4.5  9  10.5  1" />
                    </symbol>
                  </svg>
                </div>
              </li>
            </ul>
          );
        })}
      </div>}
    </div>
  )
}