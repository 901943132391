import React, { useState, useEffect } from "react";
import axios from "axios";
import { apiKey } from "../api";
import { baseId, tableIds } from "../constant";

import blazeIcon from "../assest/icons/blaze.svg";
import vibrantIcon from "../assest/icons/vibrant.svg";
import steadyIcon from "../assest/icons/steady.svg";
import dullIcon from "../assest/icons/dull.svg";
import voidIcon from "../assest/icons/void.svg";
import uploadPhotoIcon from "../assest/icons/uploadphoto.svg";
import { ReactComponent as CheckMark } from "../assest/icons/ratingcheck.svg"; 
import { ReactComponent as BackArrow } from "../assest/icons/backarrow.svg"; 

const ratingImages = {
  Blaze: blazeIcon,
  Vibrant: vibrantIcon,
  Steady: steadyIcon,
  Dull: dullIcon,
  Void: voidIcon,
};

const vibeRatingMap = {
  Blaze: 5,
  Vibrant: 4,
  Steady: 3,
  Dull: 2,
  Void: 1,
};

const foodRatingMap = {
  Exceptional: 5,
  Great: 4,
  Good: 3,
  Meh: 2,
  "Not Good": 1,
  "Didn't Eat": null,
};

const drinkRatingMap = {
  Exceptional: 5,
  Great: 4,
  Good: 3,
  Meh: 2,
  "Not Good": 1,
  "Didn't Drink": null,
};

const GiveRatingsModal = ({
  isOpen,
  onClose,
  establishmentName,
  establishmentIds,
}) => {
    const [step, setStep] = useState(1);
    const [vibeRating, setVibeRating] = useState(null);
    const [foodRating, setFoodRating] = useState(null);
    const [drinkRating, setDrinkRating] = useState(null);
    const [comment, setComment] = useState("");
    const [photo, setPhoto] = useState(null);
    const [thankYouMessage, setThankYouMessage] = useState(false);
  
    useEffect(() => {
      if (!isOpen) {
        resetForm();
      }
    }, [isOpen]);
  
    const resetForm = () => {
      setStep(1);
      setVibeRating(null);
      setFoodRating(null);
      setDrinkRating(null);
      setComment("");
      setPhoto(null);
      setThankYouMessage(false);
    };
  
    const handleNextStep = async () => {
        console.log("Handle next step called, current step:", step);
        if (step === 1 && vibeRating) {
          setStep(2);
        } else if (step === 2 && foodRating && drinkRating) {
          console.log("Establishment ID:", establishmentIds);
          
          const data = {
            fields: {
              Establishment: establishmentIds, // Link establishment using its ID
              vibeRating: vibeRatingMap[vibeRating],
              foodRating: foodRatingMap[foodRating],
              drinksRating: drinkRatingMap[drinkRating],
              comment,
            },
          };
      
          if (photo) {
            const formData = new FormData();
            formData.append("file", photo);
            formData.append("upload_preset", "your_upload_preset"); // Replace with your upload preset
      
            try {
              const uploadResponse = await axios.post(
                "https://api.cloudinary.com/v1_1/your_cloud_name/image/upload",
                formData
              );
              data.fields.photo = [{ url: uploadResponse.data.secure_url }];
              console.log(
                "Photo uploaded successfully:",
                uploadResponse.data.secure_url
              );
            } catch (error) {
              console.error("Error uploading photo:", error);
            }
          }
      
          console.log("Data to submit:", JSON.stringify(data, null, 2)); // Debug log
      
          try {
            const response = await axios.post(
              `https://api.airtable.com/v0/${baseId}/${tableIds.ratingTableId}`,
              data,
              {
                headers: {
                  Authorization: `Bearer ${apiKey}`,
                  "Content-Type": "application/json",
                },
              }
            );
            console.log("Airtable response:", response.data);
            setThankYouMessage(true);
            setTimeout(() => {
              setThankYouMessage(false);
              onClose(); // Close the modal after showing thank you message
            }, 3000);
          } catch (error) {
            console.error("Error submitting ratings:", error);
            if (error.response) {
              console.log("Airtable error response:", error.response.data);
            }
            // Handle error (e.g., show a notification to the user)
          }
        }
      };
  
    const handlePhotoUpload = (event) => {
      setPhoto(event.target.files[0]);
    };
  
    if (!isOpen) return null;
  
    return (
        <div className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-[9999]">
      <div
        className="bg-black bg-opacity-60 absolute inset-0"
        onClick={onClose}
      ></div>
      <div className="relative bg-[#192D36] text-white p-6 rounded-md z-50 w-full max-w-md mx-auto max-h-full overflow-auto">
        {thankYouMessage ? (
          <div className="text-center">
            <h2 className="text-xl mb-2">{establishmentName}</h2>
            <h2 className="text-base mb-4">Thanks you for your feedback!</h2>
          </div>
        ) : step === 1 ? (
          <div>
            <div className="mb-4"></div>
            <h2 className="text-sm text-center mb-2">What Vibe would you give</h2>
            <h2 className="text-xl font-semibold text-center mb-6">
              at {establishmentName}
            </h2>
            <div className="space-y-6">
              {["Blaze", "Vibrant", "Steady", "Dull", "Void"].map((rating) => (
                <button
                  key={rating}
                  onClick={() => setVibeRating(rating)}
                  className={`relative block w-full bg-white bg-opacity-10 p-4 rounded-md ${
                    vibeRating === rating
                      ? "border-yellow-500"
                      : "border-transparent"
                  } hover:border-yellow-500 border-2 text-white`}
                >
                  <div className="flex items-center justify-center">
                    <img
                      src={ratingImages[rating]}
                      alt={rating}
                      className="w-6 h-6 mr-2"
                    />
                    <span className="font-semibold">{rating}</span>
                  </div>
                  <span className="block text-sm mt-2 text-center">
                    {rating === "Blaze"
                      ? "Top-tier, highly recommended"
                      : rating === "Vibrant"
                      ? "Very good, pleasant and enjoyable"
                      : rating === "Steady"
                      ? "Average, nothing special but acceptable"
                      : rating === "Dull"
                      ? "Below average, generally unappealing"
                      : "Extremely poor, widely undesirable"}
                  </span>
                  {vibeRating === rating && (
                    <CheckMark
                      className="absolute top-1 right-1 w-6 h-6"
                      style={{ color: "#41A702" }}
                    />
                  )}
                </button>
              ))}
            </div>
            <div className="flex justify-center">
              <button
                onClick={handleNextStep}
                className={`mt-6 block w-[200px] justify-center text-black font-700 py-2 rounded-3xl ${
                  vibeRating ? "bg-yellow-500" : "bg-yellow-500 opacity-50"
                }`}
                disabled={!vibeRating}
              >
                Next
              </button>
            </div>
          </div>
        ) : (
          <div>
            <BackArrow
              className="absolute top-4 left-4 w-6 h-6 cursor-pointer"
              onClick={() => setStep(1)}
            />
            <div className="mb-4"></div>
            <h2 className="text-base text-center mb-4">
              How would you rate the Food & Drinks?
            </h2>
            <h2 className="text-2xl font-semibold text-center mb-4">
              {establishmentName}
            </h2>
            <div className="mb-4 pb-4">
              <h3 className="text-lg border-t border-white border-opacity-10 mb-2 text-center pt-4">
                How was the food?
              </h3>
              <div className="grid grid-cols-2 gap-4 mt-2 justify-center">
                {["Exceptional", "Great"].map((rating) => (
                  <button
                    key={rating}
                    onClick={() => setFoodRating(rating)}
                    className={`relative inline-block text-xs text-center px-4 py-2 bg-white bg-opacity-5 m-1 ${
                      foodRating === rating
                        ? "border-yellow-500"
                        : "border-transparent"
                    } hover:border-yellow-500 border-2 text-white whitespace-normal overflow-hidden text-ellipsis`}
                    style={{ maxHeight: "3rem" }}
                  >
                    {rating}
                    {foodRating === rating && (
                      <CheckMark
                        className="absolute top-1 right-1 w-4 h-4"
                        style={{ color: "#41A702" }}
                      />
                    )}
                  </button>
                ))}
              </div>
              <div className="grid grid-cols-2 gap-4 mt-2 justify-center">
                {["Good", "Meh"].map((rating) => (
                  <button
                    key={rating}
                    onClick={() => setFoodRating(rating)}
                    className={`relative inline-block text-xs text-center px-4 py-2 bg-white bg-opacity-5 m-1 ${
                      foodRating === rating
                        ? "border-yellow-500"
                        : "border-transparent"
                    } hover:border-yellow-500 border-2 text-white whitespace-normal overflow-hidden text-ellipsis`}
                    style={{ maxHeight: "3rem" }}
                  >
                    {rating}
                    {foodRating === rating && (
                      <CheckMark
                        className="absolute top-1 right-1 w-4 h-4"
                        style={{ color: "#41A702" }}
                      />
                    )}
                  </button>
                ))}
              </div>
              <div className="grid grid-cols-2 gap-4 mt-2 justify-center">
                {["Not Good", "Didn't Eat"].map((rating) => (
                  <button
                    key={rating}
                    onClick={() => setFoodRating(rating)}
                    className={`relative inline-block text-xs text-center px-4 py-2 bg-white bg-opacity-5 m-1 ${
                      foodRating === rating
                        ? "border-yellow-500"
                        : "border-transparent"
                    } hover:border-yellow-500 border-2 text-white whitespace-normal overflow-hidden text-ellipsis`}
                    style={{ maxHeight: "3rem" }}
                  >
                    {rating}
                    {foodRating === rating && (
                      <CheckMark
                        className="absolute top-1 right-1 w-4 h-4"
                        style={{ color: "#41A702" }}
                      />
                    )}
                  </button>
                ))}
              </div>
            </div>
            <div className="mb-4 pb-4">
              <h3 className="text-lg mb-2 border-t border-white border-opacity-10 text-center pt-4">
                How were the Drinks?
              </h3>
              <div className="grid grid-cols-2 gap-4 mt-2 justify-center">
                {["Exceptional", "Great"].map((rating) => (
                  <button
                    key={rating}
                    onClick={() => setDrinkRating(rating)}
                    className={`relative inline-block text-xs text-center px-2 py-2 bg-white bg-opacity-5 m-1 ${
                      drinkRating === rating
                        ? "border-yellow-500"
                        : "border-transparent"
                    } hover:border-yellow-500 border-2 text-white whitespace-normal overflow-hidden text-ellipsis`}
                  >
                    {rating}
                    {drinkRating === rating && (
                      <CheckMark
                        className="absolute top-1 right-1 w-4 h-4"
                        style={{ color: "#41A702" }}
                      />
                    )}
                  </button>
                ))}
              </div>
              <div className="grid grid-cols-2 gap-4 mt-2 justify-center">
                {["Good", "Meh"].map((rating) => (
                  <button
                    key={rating}
                    onClick={() => setDrinkRating(rating)}
                    className={`relative inline-block text-xs text-center px-4 py-2 bg-white bg-opacity-5 m-1 ${
                      drinkRating === rating
                        ? "border-yellow-500"
                        : "border-transparent"
                    } hover:border-yellow-500 border-2 text-white whitespace-normal overflow-hidden text-ellipsis`}
                  >
                    {rating}
                    {drinkRating === rating && (
                      <CheckMark
                        className="absolute top-1 right-1 w-4 h-4"
                        style={{ color: "#41A702" }}
                      />
                    )}
                  </button>
                ))}
              </div>
              <div className="grid grid-cols-2 gap-4 mt-2 justify-center">
                {["Not Good", "Didn't Drink"].map((rating) => (
                  <button
                    key={rating}
                    onClick={() => setDrinkRating(rating)}
                    className={`relative inline-block text-xs text-center px-4 py-2 bg-white bg-opacity-5 m-1 ${
                      drinkRating === rating
                        ? "border-yellow-500"
                        : "border-transparent"
                    } hover:border-yellow-500 border-2 text-white whitespace-normal overflow-hidden text-ellipsis`}
                  >
                    {rating}
                    {drinkRating === rating && (
                      <CheckMark
                        className="absolute top-1 right-1 w-4 h-4"
                        style={{ color: "#41A702" }}
                      />
                    )}
                  </button>
                ))}
              </div>
            </div>
            <div className="border-t border-white border-opacity-10 mb-4 pt-4">
              <h3 className="text-lg text-left mb-2">Leave a comment</h3>
              <textarea
                className="w-full p-2 text-black bg-gray-700 mb-4"
                placeholder="Leave a comment on your experience (optional)"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              ></textarea>
            </div>
            <div className="flex justify-center items-center mb-4">
              <input
                type="file"
                onChange={handlePhotoUpload}
                className="hidden"
                id="upload-photo"
              />
              <label
                htmlFor="upload-photo"
                className="cursor-pointer flex flex-col items-center space-y-2"
              >
                <img src={uploadPhotoIcon} alt="Upload" className="w-12 h-12" />
                <span>Upload Photo</span>
              </label>
            </div>
            <div className="flex justify-center">
              <button
                onClick={handleNextStep}
                className={`mt-6 block w-[200px] font-semibold py-2 text-black rounded-3xl ${
                  foodRating && drinkRating
                    ? "bg-yellow-500"
                    : "bg-yellow-500 opacity-50"
                }`}
                disabled={!foodRating || !drinkRating}
              >
                SUBMIT VIBE
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GiveRatingsModal;