import React, { useState } from "react";
import arrowIcon from "../assest/icons/Cheveron.svg";

const Dropdown = ({ options, onSelect, placeholder, selectedOption }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    onSelect(option);
    toggleDropdown();
  };

  const sortedOptions = options?.sort((a, b) => a.label.localeCompare(b.label));

  return (
    <div className="relative w-full">
      <div
        onClick={toggleDropdown}
        className={`cursor-pointer flex items-center justify-between w-full lg:min-w-[600px] bg-[#fff] h-14 text-[#141414] font-normal text-xl px-5 rounded ${
          selectedOption === "" ? " " : ""
        }`}
      >
        {selectedOption?.label || (
          <span className="text-gray-500">{placeholder}</span>
        )}
        <div
          className={`w-[43px] h-[43px] me-[5px] bg-[#fff] rounded-full flex items-center justify-center ${
            isOpen ? "rotate-180" : "rotate-0"
          }`}
        >
          <img src={arrowIcon} alt="arrow" />
        </div>
      </div>
      {isOpen && (
        <>
          {sortedOptions.length > 0 ? (
            <ul className="h-60 customize_scroll overflow-y-scroll overflow-x-hidden absolute left-0 mt-2 w-full bg-darkgreen border rounded-2xl shadow-lg py-3 z-10">
              {sortedOptions?.map((option, index) => (
                <li
                  key={index}
                  onClick={() => handleOptionClick(option)}
                  className="cursor-pointer px-4 py-2 hover:text-main-orange"
                >
                  {option.label}
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-white">Loading ...</p>
          )}
        </>
      )}
    </div>
  );
};

export default Dropdown;
