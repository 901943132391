import { Link } from "react-router-dom";
import Dropdown from "../../CustomDropdown";

export const BusinessName = ({ error, setErrors, formData, setFormData }) => (
  <div className="flex flex-col gap-y-4 w-full">
    <h2 className="text-sm lg:text-3xl font-semibold flex">
      What is the name of your business?
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 0.425049L9.04512 5.5536L13.1423 2.29669L10.6463 6.89718L15.8785 7.03586L11.0093 8.95567L14.9282 12.425L9.96418 10.7659L10.7362 15.9426L8 11.4808L5.26384 15.9426L6.03582 10.7659L1.0718 12.425L4.99069 8.95567L0.121538 7.03586L5.35366 6.89718L2.8577 2.29669L6.95488 5.5536L8 0.425049Z"
          fill="#EB4335"
        />
      </svg>
    </h2>
    {error && (
      <p className="text-red italic text-lg font-semibold my-4 text-center">
        This field is required!
      </p>
    )}
    {/* Form fields for step  1 */}
    <input
      className="w-full lg:min-w-[600px] bg-[#fff] h-14 text-[#141414] font-normal text-xl px-5 rounded"
      type="text"
      placeholder="Enter your business."
      value={formData.businessName.name}
      onChange={(e) => {
        // Update the form data
        setFormData({
          ...formData,
          businessName: { ...formData.businessName, name: e.target.value },
        });
        setErrors((prevErrors) => ({
          ...prevErrors,
          businessName: false,
        }));
      }}
    />
  </div>
);

export const BusinessAddress = ({
  formData,
  setFormData,
  states,
  setDropdownOpen,
  setcityDropdown,
  cities,
  error,
  setErrors,
}) => {
  const handleDropdownChange = (option, field, setDropdown) => {
    if (field === "city") {
      // Find the corresponding state for the selected city
      const state = states.find((state) => state.id === option.fields.State[0]);
      setFormData({
        ...formData,
        businessAddress: {
          ...formData.businessAddress,
          [field]: option, // Set the city value
          state: state, // Set the state value if found
        },
      });
      setDropdown(false);
      return;
    }
    setFormData({
      ...formData,
      businessAddress: {
        ...formData.businessAddress,
        [field]: option,
      },
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      businessAddress: false,
    }));
    setDropdown(false);
  };

  const handleInputChange = (e, field) => {
    setFormData({
      ...formData,
      businessAddress: {
        ...formData.businessAddress,
        [field]: e.target.value,
      },
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      businessAddress: false,
    }));
  };

  return (
    <div className="flex flex-col gap-y-4 w-full">
      <h2 className="text-sm lg:text-3xl font-semibold flex">
        What is the address?
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 0.425049L9.04512 5.5536L13.1423 2.29669L10.6463 6.89718L15.8785 7.03586L11.0093 8.95567L14.9282 12.425L9.96418 10.7659L10.7362 15.9426L8 11.4808L5.26384 15.9426L6.03582 10.7659L1.0718 12.425L4.99069 8.95567L0.121538 7.03586L5.35366 6.89718L2.8577 2.29669L6.95488 5.5536L8 0.425049Z"
            fill="#EB4335"
          />
        </svg>
      </h2>
      {error && (
        <p className="text-red italic text-lg font-semibold my-4 text-center">
          This field is required!
        </p>
      )}
      <div className="flex flex-col gap-y-5">
        <input
          name="street"
          className="w-full lg:min-w-[600px] bg-[#fff] h-14 text-[#141414] font-normal text-xl px-5 rounded"
          type="text"
          placeholder="Street"
          value={formData.businessAddress.street}
          onChange={(e) => handleInputChange(e, "street")}
        />

        <Dropdown
          options={cities}
          onSelect={(option) =>
            handleDropdownChange(option, "city", setcityDropdown)
          }
          placeholder="Select City"
          selectedOption={formData.businessAddress.city}
        />
        <p className="text-sm font-medium text-[#fff]">
          Don’t see your city?{" "}
          <Link
            to={"/request_city"}
            className="text-main-orange hover:underline underline-offset-1"
          >
            Request It Here
          </Link>
        </p>
        <Dropdown
          options={states}
          onSelect={(option) =>
            handleDropdownChange(option, "state", setDropdownOpen)
          }
          placeholder="Select State"
          selectedOption={formData.businessAddress.state}
        />
        <input
          className="w-full lg:min-w-[600px] bg-[#fff] h-14 text-[#141414] font-normal text-xl px-5 rounded"
          type="text"
          name="zip"
          placeholder="Zip Code"
          value={formData.businessAddress.zip}
          onChange={(e) => handleInputChange(e, "zip")}
        />
      </div>
    </div>
  );
};

export const CrossStreetAddress = ({
  error,
  setErrors,
  formData,
  setFormData,
  cities,
}) => {
  const handleDropdownChange = (option, field) => {
    setFormData({
      ...formData,
      crossStreets: {
        ...formData.crossStreets,
        [field]: option,
      },
    });
  };
  return (
    <div className="flex flex-col gap-y-4 w-full">
      <h2 className="text-sm lg:text-3xl font-semibold flex">
        Closest major cross streets?
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 0.425049L9.04512 5.5536L13.1423 2.29669L10.6463 6.89718L15.8785 7.03586L11.0093 8.95567L14.9282 12.425L9.96418 10.7659L10.7362 15.9426L8 11.4808L5.26384 15.9426L6.03582 10.7659L1.0718 12.425L4.99069 8.95567L0.121538 7.03586L5.35366 6.89718L2.8577 2.29669L6.95488 5.5536L8 0.425049Z"
            fill="#EB4335"
          />
        </svg>
      </h2>
      {error && (
        <p className="text-red italic text-lg font-semibold my-4 text-center">
          This field is required!
        </p>
      )}
      {/* Form fields for step  1 */}
      <div className="flex flex-col gap-y-4 w-full">
        <input
          className="w-full lg:min-w-[600px] bg-[#fff] h-14 text-[#141414] font-normal text-xl px-5 rounded"
          type="text"
          placeholder="8th street."
          value={formData.crossStreets.street}
          onChange={(e) => {
            setFormData({
              ...formData,
              crossStreets: {
                ...formData.crossStreets,
                street: e.target.value,
              },
            });
            setErrors((prevErrors) => ({
              ...prevErrors,
              crossStreets: false,
            }));
          }}
        />
        <p className="text-center font-medium text-2xl">And</p>
        <input
          className="w-full lg:min-w-[600px] bg-[#fff] h-14 text-[#141414] font-normal text-xl px-5 rounded"
          type="text"
          placeholder="Bannock"
          value={formData.crossStreets.street2}
          onChange={(e) => {
            setFormData({
              ...formData,
              crossStreets: {
                ...formData.crossStreets,
                street2: e.target.value,
              },
            });
            setErrors((prevErrors) => ({
              ...prevErrors,
              crossStreets: false,
            }));
          }}
        />
        <p className="text-sm font-light text-center">
          Not sure? no worries click next
        </p>
      </div>
    </div>
  );
};

export const UserName = ({
  error,
  setErrors,
  formData,
  setFormData,
  handleNext,
}) => (
  <div className="flex flex-col gap-y-4 w-full">
    <h2 className="text-sm lg:text-3xl font-semibold flex">
      What is your Full Name?
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 0.425049L9.04512 5.5536L13.1423 2.29669L10.6463 6.89718L15.8785 7.03586L11.0093 8.95567L14.9282 12.425L9.96418 10.7659L10.7362 15.9426L8 11.4808L5.26384 15.9426L6.03582 10.7659L1.0718 12.425L4.99069 8.95567L0.121538 7.03586L5.35366 6.89718L2.8577 2.29669L6.95488 5.5536L8 0.425049Z"
          fill="#EB4335"
        />
      </svg>
      {/* Form fields for step  1 */}
    </h2>
    {error && (
      <p className="text-red italic text-lg font-semibold my-4 text-center">
        This field is required!
      </p>
    )}
    <input
      className="w-full lg:min-w-[600px] bg-[#fff] h-14 text-[#141414] font-normal text-xl px-5 rounded"
      type="text"
      placeholder="John Cool"
      value={formData.user.name}
      onChange={(e) => {
        setFormData({
          ...formData,
          user: { ...formData.user, name: e.target.value },
        });
        setErrors((prevErrors) => ({
          ...prevErrors,
          user: false,
        }));
      }}
    />
  </div>
);

export const UserEmail = ({
  error,
  setErrors,
  formData,
  setFormData,
  handleNext,
}) => (
  <div className="flex flex-col gap-y-4 w-full">
    <h2 className="text-sm lg:text-3xl font-semibold flex">
      What is your email?
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 0.425049L9.04512 5.5536L13.1423 2.29669L10.6463 6.89718L15.8785 7.03586L11.0093 8.95567L14.9282 12.425L9.96418 10.7659L10.7362 15.9426L8 11.4808L5.26384 15.9426L6.03582 10.7659L1.0718 12.425L4.99069 8.95567L0.121538 7.03586L5.35366 6.89718L2.8577 2.29669L6.95488 5.5536L8 0.425049Z"
          fill="#EB4335"
        />
      </svg>
    </h2>
    {error && (
      <p className="text-red italic text-lg font-semibold my-4 text-center">
        This field is required!
      </p>
    )}
    {/* Form fields for step  1 */}
    <input
      className="w-full lg:min-w-[600px] bg-[#fff] h-14 text-[#141414] font-normal text-xl px-5 rounded"
      type="text"
      placeholder="name@company.com"
      value={formData.user.email}
      onChange={(e) => {
        setFormData({
          ...formData,
          user: { ...formData.user, email: e.target.value },
        });
        setErrors((prevErrors) => ({
          ...prevErrors,
          user: false,
        }));
      }}
    />
  </div>
);

export const UserRole = ({
  roles,
  error,
  setErrors,
  formData,
  setFormData,
  handleNext,
  setRoleDropdown,
}) => {
  const handleDropdownChange = (option, field, setDropdown) => {
    setFormData({
      ...formData,
      user: { ...formData.user, role: option },
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      user: false,
    }));
    setDropdown(false);
  };

  return (
    <div className="flex flex-col gap-y-4 w-full">
      <h2 className="text-sm lg:text-3xl font-semibold flex">
        What is your role in the business?
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 0.425049L9.04512 5.5536L13.1423 2.29669L10.6463 6.89718L15.8785 7.03586L11.0093 8.95567L14.9282 12.425L9.96418 10.7659L10.7362 15.9426L8 11.4808L5.26384 15.9426L6.03582 10.7659L1.0718 12.425L4.99069 8.95567L0.121538 7.03586L5.35366 6.89718L2.8577 2.29669L6.95488 5.5536L8 0.425049Z"
            fill="#EB4335"
          />
        </svg>
      </h2>
      {error && (
        <p className="text-red italic text-lg font-semibold my-4 text-center">
          This field is required!
        </p>
      )}
      {/* Form fields for step  1 */}
      <Dropdown
        options={roles}
        onSelect={(option) => handleDropdownChange(option, "role", () => {})}
        placeholder="Select one that apply’s"
        selectedOption={formData.user.role}
      />
    </div>
  );
};

export const BusinessCategory = ({
  formData,
  setFormData,
  categories,
  handleCategoryChange,
  error,
  setErrors,
}) => (
  <div className="flex flex-col gap-y-2 md:gap-y-4 w-full">
    <div>
      <h2 className="text-base md:text-3xl leading-6 font-semibold relative">
        What category(s) best describe your business
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="absolute md:relative md:-top-7 md:left-[8rem] -right-1 top-0 "
        >
          <path
            d="M8 0.425049L9.04512 5.5536L13.1423 2.29669L10.6463 6.89718L15.8785 7.03586L11.0093 8.95567L14.9282 12.425L9.96418 10.7659L10.7362 15.9426L8 11.4808L5.26384 15.9426L6.03582 10.7659L1.0718 12.425L4.99069 8.95567L0.121538 7.03586L5.35366 6.89718L2.8577 2.29669L6.95488 5.5536L8 0.425049Z"
            fill="#EB4335"
          />
        </svg>
      </h2>
      <span className="italic font-thin text-sm md:text-lg text-white">
        Select one or all that apply.
      </span>
    </div>
    {error && (
      <p className="text-red italic text-lg font-semibold my-0 text-center">
        This field is required!
      </p>
    )}

    <ul className="grid grid-cols-2 md:grid-cols-3 gap-x-2 gap-y-1 mt-4">
      {categories.map((category) => (
        <li key={category} className="flex gap-2 items-center min-w-40">
          <div className="checkbox-wrapper-4">
            <input
              className="inp-cbx"
              id={category}
              type="checkbox"
              value={category} // Set the value attribute to the category value
              onChange={handleCategoryChange}
            />
            <label className="cbx" htmlFor={category}>
              <span>
                <svg width="12px" height="10px">
                  <use xlinkHref="#check-4"></use>
                </svg>
              </span>
              <span className="label">{category}</span>
            </label>
            <svg className="inline-svg">
              <symbol id="check-4" viewBox="0  0  12  10">
                <polyline points="1.5  6  4.5  9  10.5  1" />
              </symbol>
            </svg>
          </div>
        </li>
      ))}
    </ul>

    <div className="flex flex-col gap-2">
      <span className="italic font-thin text-lg text-white">
        Or enter your own category
      </span>
      <input
        className="w-full lg:min-w-[600px] bg-[#fff] h-14 text-[#141414] font-normal text-xl px-5 rounded"
        type="text"
        placeholder="Enter your business."
        value={formData.BusinessCategory.custom}
        onChange={(e) => {
          setFormData({
            ...formData,
            BusinessCategory: {
              ...formData.BusinessCategory,
              custom: e.target.value,
            },
          });
          setErrors((prevErrors) => ({
            ...prevErrors,
            categories: false,
          }));
        }}
      />
    </div>
  </div>
);
export const BusinessAmenities = ({
  formData,
  setFormData,
  amenities,
  handleAmenitiesChange,
  error,
  setErrors,
}) => {
  return(
  
  <div className="flex flex-col gap-y-2 md:gap-y-4 w-full">
    <div>
      <h2 className="text-base md:text-3xl leading-6 font-semibold relative">
        Select the amenities your establishment has to offer
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="absolute md:relative md:-top-7 md:left-[8rem] -right-1 top-0 "
        >
          <path
            d="M8 0.425049L9.04512 5.5536L13.1423 2.29669L10.6463 6.89718L15.8785 7.03586L11.0093 8.95567L14.9282 12.425L9.96418 10.7659L10.7362 15.9426L8 11.4808L5.26384 15.9426L6.03582 10.7659L1.0718 12.425L4.99069 8.95567L0.121538 7.03586L5.35366 6.89718L2.8577 2.29669L6.95488 5.5536L8 0.425049Z"
            fill="#EB4335"
          />
        </svg>
      </h2>
      <span className="italic font-thin text-sm md:text-lg text-white">
        Select one or all that apply
      </span>
    </div>
    {error && (
      <p className="text-red italic text-lg font-semibold my-0 text-center">
        This field is required!
      </p>
    )}

    <ul className="grid grid-cols-2 md:grid-cols-3 gap-x-2 gap-y-1 mt-4">
      {amenities.map((category) => (
        <li key={category} className="flex gap-2 items-center min-w-40">
          <div className="checkbox-wrapper-4">
            <input
              className="inp-cbx"
              id={category}
              type="checkbox"
              value={category} // Set the value attribute to the category value
              onChange={handleAmenitiesChange}
            />
            <label className="cbx" htmlFor={category}>
              <span>
                <svg width="12px" height="10px">
                  <use xlinkHref="#check-4"></use>
                </svg>
              </span>
              <span className="label">{category}</span>
            </label>
            <svg className="inline-svg">
              <symbol id="check-4" viewBox="0  0  12  10">
                <polyline points="1.5  6  4.5  9  10.5  1" />
              </symbol>
            </svg>
          </div>
        </li>
      ))}
    </ul>

    <div className="flex flex-col gap-2">
      <span className="italic font-thin text-lg text-white">
        Don’t see it? write it in.
      </span>
      <input
        className="w-full lg:min-w-[600px] bg-[#fff] h-14 text-[#141414] font-normal text-xl px-5 rounded"
        type="text"
        placeholder="Let us know."
        value={formData.BusinessAmenities.custom}
        onChange={(e) => {
          setFormData({
            ...formData,
            BusinessAmenities: {
              ...formData.BusinessAmenities,
              custom: e.target.value,
            },
          });
          setErrors((prevErrors) => ({
            ...prevErrors,
            amenities: false,
          }));
        }}
      />
    </div>
  </div>
)};
