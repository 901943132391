import React, { useEffect, useState } from "react";
import Lightbox from "./Lightbox";
import img1 from "../assest/images/happy.jpg";
const Establishment = ({
  happyHourLocation,
  lightboxOpen,
  setLightboxOpen,
}) => {
  if (!happyHourLocation) {
    return <div className="h-[90px]"></div>;
  }

  const { menu } = happyHourLocation;
  const openLightbox = () => setLightboxOpen(true);
  const closeLightbox = () => setLightboxOpen(false);

  return (
  <div className="flex items-center gap-3 md:gap-5 pt-4 md:pt-9">
      {!happyHourLocation.profile ? (
        <div className="w-[90px] h-[90px] rounded-full bg-white"></div>
      ) : (
        <div className="w-[60px] h-[60px] md:w-[90px] md:h-[90px] rounded-full bg-white overflow-hidden object-contain">
          <img
            className="w-[60px] h-[60px] md:w-[90px] md:h-[90px] "
            src={happyHourLocation.profile[0].url}
            alt="promo"
          />
        </div>
      )}
      <div className="">
        <p className="text-base md:text-2xl font-semibold text-white ">
          {happyHourLocation.name}
        </p>
        <p className="text-base md:text-lg font-normal text-off-gray ">
          {happyHourLocation.street}, {happyHourLocation.city},{" "}
          {happyHourLocation.state} {happyHourLocation.zip}
        </p>
        {/* <p className='text-white'> <span className=' cursor-pointer hover:text-main-orange text-lg font-medium underline text-white pr-[15px]'>Get Directions </span>| <span className='text-lg font-medium underline text-white pl-[15px] cursor-pointer hover:text-main-orange '>View Menu</span></p> */}
        <p className="text-white">
          <span
            className="cursor-pointer hover:text-main-orange text-sm md:text-lg font-medium underline text-white pr-[15px]"
            onClick={() =>
              window.open(
                `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                  happyHourLocation.name +
                    ", " +
                    happyHourLocation.street +
                    " " +
                    happyHourLocation.city
                )}`,
                "_blank"
              )
            }
          >
            Get Directions
          </span>
          |
          <span
            onClick={openLightbox}
            className="inline-block text-lg font-medium underline text-white pl-[15px] cursor-pointer hover:text-main-orange"
          >
            View Menu
          </span>
        </p>
        <Lightbox
          establishment={happyHourLocation}
          file={(menu && menu[0].url) || img1}
          isOpen={lightboxOpen}
          onClose={closeLightbox}
        />
      </div>
    </div>
  );
};
export default Establishment;
