import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import StepperIndicator from "../../Stepper/StepperIndicator";
import logo from "../../../assest/Logo.svg";
import {
  BusinessAddress,
  BusinessName,
  CrossStreetAddress,
  UserEmail,
  UserName,
  UserRole,
  BusinessCategory,
  BusinessAmenities,
} from "./coomponets";

const initialFormData = {
  businessName: {
    name: "",
  },
  businessAddress: {
    street: "",
    city: "",
    state: "",
    zip: "",
  },
  crossStreets: {
    street: "",
    street2: "",
  },
  user: {
    name: "",
    email: "",
    role: "",
  },
  BusinessCategory: {
    categories: [],
    custom: "",
  },
  BusinessAmenities: {
    amenities: [],
    custom: "",
  },
};

const AddBusinessDetails = ({
  setShowNextStep,
  categories,
  amenities,
  onSubmit,
  states,
  roles,
  cities,
}) => {
  const user = useSelector((state) => state.auth.user);

  const [currentStep, setCurrentStep] = useState(1);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [formData, setFormData] = useState(initialFormData);

  const [stateDropdown, setStateDropdown] = useState(false);
  const [cityDropdown, setcityDropdown] = useState(false);

  const [errors, setErrors] = useState({});
  const validateForm = () => {
    const newErrors = {};
    // Example validation for businessName
    if (currentStep === 1 && !formData.businessName.name) {
      newErrors.businessName = true;
    }
    if (currentStep === 2 && !formData.businessAddress.street) {
      newErrors.businessAddress = true;
    }
    if (currentStep === 2 && !formData.businessAddress.city) {
      newErrors.businessAddress = true;
    }
    if (currentStep === 2 && !formData.businessAddress.state) {
      newErrors.businessAddress = true;
    }
    if (currentStep === 2 && !formData.businessAddress.zip) {
      newErrors.businessAddress = true;
    }
    if (
      currentStep === 3 &&
      !formData.crossStreets.street &&
      !formData.crossStreets.street2
    ) {
      newErrors.crossStreets = true;
    }
    if (currentStep === 4 && !formData.user.name) {
      newErrors.user = true;
    }
    if (currentStep === 5 && !formData.user.email) {
      newErrors.user = true;
    }
    if (currentStep === 6 && !formData.user.role) {
      newErrors.user = true;
    }
    if (
      currentStep === 7 &&
      formData.BusinessCategory.categories.length === 0 &&
      !formData.BusinessCategory.custom
    ) {
      newErrors.categories = true;
    }
    if (
      currentStep === 8 &&
      formData.BusinessAmenities.amenities.length === 0 &&
      !formData.BusinessAmenities.custom
    ) {
      newErrors.amenities = true;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Returns true if no errors
  };

  const handleNext = () => {
    if (validateForm()) {
      if (currentStep >= 8) {
        handleSubmit();
      } else {
        setCurrentStep((prevStep) => Math.min(prevStep + 1, 8));
      }
    }
  };

  const handlePrev = () => {
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 1));
  };

  const handleCategoryChange = (event) => {
    const { checked, value } = event.target;

    // reset required error
    setErrors((prevErrors) => ({
      ...prevErrors,
      categories: false,
    }));

    setFormData((prevFormData) => {
      const newCategories = checked
        ? [...prevFormData.BusinessCategory.categories, value] // Add the category if checked
        : prevFormData.BusinessCategory.categories.filter(
            (category) => category !== value
          ); // Remove the category if unchecked

      return {
        ...prevFormData,
        BusinessCategory: {
          ...prevFormData.BusinessCategory,
          categories: newCategories,
        },
      };
    });
  };

  const handleAmenitiesChange = (event) => {
    const { checked, value } = event.target;
    // reset required error
    setErrors((prevErrors) => ({
      ...prevErrors,
      amenities: false,
    }));

    setFormData((prevFormData) => {
      const newAmenities = checked
        ? [...prevFormData.BusinessAmenities.amenities, value] // Add the category if checked
        : prevFormData.BusinessAmenities.amenities.filter(
            (amenity) => amenity !== value
          ); // Remove the category if unchecked

      return {
        ...prevFormData,
        BusinessAmenities: {
          ...prevFormData.BusinessAmenities,
          amenities: newAmenities,
        },
      };
    });
  };

  const handleSubmit = () => {
    onSubmit(formData);
    setShowNextStep(2);
  };

  const renderForm = () => {
    switch (currentStep) {
      case 1:
        return (
          <BusinessName
            error={errors.businessName}
            formData={formData}
            setFormData={setFormData}
            handleNext={handleNext}
            setErrors={setErrors}
          />
        );
      case 2:
        return (
          <BusinessAddress
            error={errors.businessAddress}
            formData={formData}
            setFormData={setFormData}
            handleNext={handleNext}
            states={states}
            cities={cities}
            setDropdownOpen={setStateDropdown}
            setcityDropdown={setcityDropdown}
            cityDropdown={cityDropdown}
            dropdownOpen={stateDropdown}
            setErrors={setErrors}
          />
        );
      case 3:
        return (
          <CrossStreetAddress
            error={errors.crossStreets}
            formData={formData}
            setFormData={setFormData}
            handleNext={handleNext}
            cities={cities}
            setErrors={setErrors}
          />
        );
      case 4:
        return (
          <UserName
            error={errors.user}
            formData={formData}
            setFormData={setFormData}
            handleNext={handleNext}
            setErrors={setErrors}
          />
        );
      case 5:
        return (
          <UserEmail
            error={errors.user}
            formData={formData}
            setFormData={setFormData}
            handleNext={handleNext}
            setErrors={setErrors}
          />
        );
      case 6:
        return (
          <UserRole
            error={errors.user}
            formData={formData}
            setFormData={setFormData}
            handleNext={handleNext}
            roles={roles}
            setErrors={setErrors}
          />
        );
      case 7:
        return (
          <BusinessCategory
            error={errors.categories}
            formData={formData}
            setFormData={setFormData}
            handleNext={handleNext}
            categories={categories}
            handleCategoryChange={handleCategoryChange}
            setErrors={setErrors}
          />
        );
      case 8:
        return (
          <BusinessAmenities
            error={errors.amenities}
            formData={formData}
            setFormData={setFormData}
            handleNext={handleNext}
            amenities={amenities}
            handleAmenitiesChange={handleAmenitiesChange}
            setErrors={setErrors}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (user) {
      const name = user.fields.Name;
      const email = user.fields.Email;

      setFormData((prev) => ({
        ...prev,
        user: {
          name: name,
          email: email,
        },
      }));
    }
  }, [user]);


  return (
    <div className="min-h-screen !h-fit xl:h-screen bg-darkgreen rounded-[15px] xs:mx-2 mx-[15px] my-8 relative">
      <div className="max-w-[665px] mx-auto px-4 flex flex-col text-white p-6 rounded  h-full items-center justify-start py-20">
        <div className="text-center">
          <h1 className="text-3xl font-bold lg:text-[60px] leading-[72px] tracking-tighter">
            List Your Business
          </h1>
          <p className="text-sm font-medium lg:text-2xl tracking-tighter">
            Showcase Your Business, Connect with Customers
          </p>
        </div>
        <StepperIndicator
          steps={[1, 2, 3, 4, 5, 6, 7, 8]}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
        />

        {renderForm()}

        <div className="flex justify-between mt-8 gap-x-2 lg:gap-x-8">
          {currentStep > 1 && (
            <button
              onClick={handlePrev}
              className="bg-transparent text-white border-main-orange border px-4 py-2 rounded transition-colors delay-75 hover:bg-main-orange-100"
            >
              Back
            </button>
          )}
          {/* {currentStep < 3 && ( */}
          <button
            onClick={handleNext}
            className="orange-btn text-white px-4 py-2 rounded font-semibold"
          >
            Next
          </button>
          {/* )} */}
        </div>
      </div>
      <div className="h-10 my-4">
        <img
          className="md:hidden absolute bottom-10 translate-x-[-50%] left-[50%] opacity-60"
          src={logo}
          alt="scout&sip"
        />
      </div>
    </div>
  );
};

export default AddBusinessDetails;
