import React from 'react';
import Layout from "../components/Layout";

const UberCredits = () => {
  return (
    <>
    <Layout>
    <div className="bg-bg-main min-h-screen flex items-center justify-center p-4">
      <div className="bg-bg-main-100 p-6 rounded-lg shadow-lg w-full max-w-xs">
        <div className="bg-main-orange-50 text-white text-center py-2 px-4 rounded-t-lg flex justify-center items-center">
          <img src="/path/to/uber-logo.png" alt="Uber Logo" className="h-8 w-8 mr-2" />
          <div>
            <p className="text-sm font-bold">REDEEM $7 UBER CREDIT</p>
            <p className="text-xs">WHEN YOU VISIT</p>
            <p className="text-xs font-bold">MATADOR BOISE</p>
          </div>
        </div>
        <div className="text-center py-4">
          <p className="text-white text-lg">Get your trip to <span className="font-bold">Matador Boise</span> paid for!</p>
          <p className="text-main-orange text-sm font-bold">UBER CREDITS CAN BE TRANSFERRED</p>
          <p className="text-off-gray text-xs mt-2">UBER CREDITS ARE <span className="font-bold">ONLY VALID</span> TO THOSE WHO VISIT THE ESTABLISHMENT</p>
        </div>
        <form className="space-y-4">
          <div>
            <label className="block text-off-gray">What is your name?</label>
            <input type="text" className="w-full p-2 rounded bg-light-gray text-white" placeholder="Travis" />
          </div>
          <div>
            <label className="block text-off-gray">What is/was your servers name?</label>
            <input type="text" className="w-full p-2 rounded bg-light-gray text-white" placeholder="Amanda" />
          </div>
          <div>
            <label className="block text-off-gray">Upload a photo of plate/drink/establishment</label>
            <input type="file" className="w-full p-2 rounded bg-light-gray text-white" />
          </div>
          <div>
            <label className="block text-off-gray">Care if Matador reposts your photo?</label>
            <input type="text" className="w-full p-2 rounded bg-light-gray text-white" placeholder="Yes or no" />
          </div>
          <div>
            <label className="block text-off-gray">Image Credit Username</label>
            <input type="text" className="w-full p-2 rounded bg-light-gray text-white" placeholder="@travisdleslie on Instagram" />
          </div>
          <button className="w-full bg-main-orange-100 text-white py-2 rounded">SUBMIT</button>
          <p className="text-off-gray text-xs text-center mt-4">
            *LINK TO REDEEM UBER CREDIT WILL BE SENT AFTER SUBMISSION
          </p>
        </form>
      </div>
    </div>
    </Layout>
    </>
  );
};

export default UberCredits;
