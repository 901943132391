// src/components/Layout.js
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "./Header";
import Footer from "./Footer";

import { setCities, setSelectedCity } from "../redux/slices/citySlices";
import { fetchCityData } from "../api";
import Sidebar from "./dashboardUIs/Sidebar";
import Support from "./dashboardUIs/Support";
import { useState } from "react";
import { useIsMobile } from "../hooks";
import NoMobileScreen from "./NoMobileScreen";
import ScrollButton from "./ScrollButton";

const Layout = ({ children }) => {
  const dispatch = useDispatch();
  const { list: cities, selectedCity } = useSelector((state) => state.cities);

  useEffect(() => {
    const loadCitiesData = async () => {
      try {
        const data = await fetchCityData();
        const transformedData = data
          .map((record) => ({
            ...record,
            Promotions: record.fields?.Promotions,
            Establishments: record.fields?.Establishments,
          }))
          .sort((a, b) => a.fields.Name.localeCompare(b.fields.Name));

        const defaultCity = data.find(
          (record) => record.fields?.Name === "Boise"
        );

        if (defaultCity) {
          dispatch(
            setSelectedCity({
              ...defaultCity,
              label: defaultCity.fields.Name,
              value: defaultCity.fields.Name,
              Establishments: defaultCity.fields.Establishments,
              Promotions: defaultCity.fields.Promotions,
            })
          );
        }

        dispatch(setCities(transformedData));
      } catch (error) {
        console.error("Error fetching cities:", error);
        // setError("Failed to load city data");
      }
    };

    loadCitiesData();
  }, [dispatch]);

  const handleCitySelect = (city) => {
    dispatch(setSelectedCity(city));
  };

  return (
    <div>
      <Header
        defaultCity={selectedCity}
        cities={cities}
        onCitySelect={handleCitySelect}
      />
      <main>{children}</main>
      
      <ScrollButton />
      <Footer />
    </div>
  );
};

export default Layout;

export const DashboardLayout = ({ children }) => {
  const [openSupport, setOpenSupportModal] = useState(false);

  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const { list: cities, selectedCity } = useSelector((state) => state.cities);

  useEffect(() => {
    const loadCitiesData = async () => {
      try {
        const data = await fetchCityData();
        const transformedData = data
          .map((record) => ({
            ...record,
            Promotions: record.fields?.Promotions,
            Establishments: record.fields?.Establishments,
          }))
          .sort((a, b) => a.fields.Name.localeCompare(b.fields.Name));

        const defaultCity = data.find(
          (record) => record.fields?.Name === "Boise"
        );

        if (defaultCity) {
          dispatch(
            setSelectedCity({
              ...defaultCity,
              label: defaultCity.fields.Name,
              value: defaultCity.fields.Name,
              Establishments: defaultCity.fields.Establishments,
              Promotions: defaultCity.fields.Promotions,
            })
          );
        }

        dispatch(setCities(transformedData));
      } catch (error) {
        console.error("Error fetching cities:", error);
        // setError("Failed to load city data");
      }
    };

    loadCitiesData();
  }, [dispatch]);

  const handleCitySelect = (city) => {
    dispatch(setSelectedCity(city));
  };
  return (
    <>
      <button
        data-drawer-target="default-sidebar"
        data-drawer-toggle="default-sidebar"
        aria-controls="default-sidebar"
        type="button"
        className="hidden md:hidden items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600 "
      >
        <span className="sr-only">Open sidebar</span>
        <svg
          className="w-6 h-6"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clipRule="evenodd"
            fillRule="evenodd"
            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
          ></path>
        </svg>
      </button>

      {isMobile ? (
        <Header
          defaultCity={selectedCity}
          cities={cities}
          onCitySelect={handleCitySelect}
        />
      ) : (
        <Sidebar
          open={openSupport}
          openSupportHandler={() => setOpenSupportModal(!openSupport)}
        />
      )}

      {!isMobile ? (
        <div className="sm:ml-64 p-4">{children}</div>
      ) : (
        <NoMobileScreen />
      )}
      <Support
        isSuccess={false}
        open={openSupport}
        closeModal={() => setOpenSupportModal(false)}
      />
    </>
  );
};
