import React from "react";

const DisplayPDF = ({ filePath }) => {
  return (
    <embed
      src={filePath}
      // type="application/pdf"
      className="w-full h-full min-h-[calc(100vh-220px)] customize_scroll menu_preview"
    />
  );
};

export default DisplayPDF;
