// src/slices/citiesSlice.js
import { createSlice } from '@reduxjs/toolkit';

const citiesSlice = createSlice({
 name: 'cities',
 initialState: {
    list: [],
    selectedCity: 'Boise',
 },
 reducers: {
    setCities: (state, action) => {
      state.list = action.payload;
    },
    setSelectedCity: (state, action) => {
      state.selectedCity = action.payload;
    },
 },
});

export const { setCities, setSelectedCity } = citiesSlice.actions;

export default citiesSlice.reducer;