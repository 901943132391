import React from "react";
import filterIcon from "../assest/icons/filter.svg";
const FilterButton = ({
  FilterText,
  selectedFilterCount,
  selectColor,
  selectSize,
  onClick,
  className,
}) => {
  return (
    <button
      className={`bg-[#497986] pl-5 pr-2 flex justify-between items-center gap-3 h-[55px] rounded-full text-lg ${className}`}
      onClick={onClick}
    >
      {FilterText}
      <div
        className={`flex text-base justify-center items-center ${selectColor} ${selectSize}  rounded-full`}
      >
        {" "}
        {selectedFilterCount == 0 ? (
          <img src={filterIcon} className="w-4 h-4" alt="filter" />
          ) : (
          selectedFilterCount
        )}
      </div>
    </button>
  );
};

export default FilterButton;
