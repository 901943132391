// src/redux/slices/promotionsSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchPromotionsData } from '../../api';
import { isSameDay } from '../../utils';

// Async thunk for fetching and processing promotions
export const fetchAndProcessPromotions = createAsyncThunk(
  'promotions/fetchAndProcessPromotions',
  async (promoIds, thunkAPI) => {
    try {
      const data = await fetchPromotionsData(promoIds);

      // Filter and process the data as per your logic
      const filteredData = data.filter((record) => {
        const promoDate = new Date(record.fields?.["promo-date"]);
        const today = new Date();
        return isSameDay(promoDate, today);
      });

      const groupedByCity = filteredData.reduce((acc, record) => {
        const city = record.fields?.City;
        if (!acc[city]) {
          acc[city] = [];
        }
        if (acc[city].length < 3) {
          acc[city].push(record);
        }
        return acc;
      }, {});

      const transformedData = Object.values(groupedByCity)
        .flat()
        .map((record) => ({
          id: record.id,
          title: record.fields?.["Happy Hour Offer"],
          subTitle: record.fields?.Highlight,
          imageUrl: record.fields?.image?.[0],
          date: record.fields?.["promo-date"],
          startTime: record.fields?.["start-time"],
          endTime: record.fields?.["end-time"],
          happyHourLocation: {
            name: record.fields?.City,
            location: record.fields?.Establishments,
          },
          highLightText: record.fields?.Highlight,
          highLightColor: record.fields?.Hightcolor,
          description: record.fields?.description,
        }));

      return transformedData; // Return the processed data
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const promotionsSlice = createSlice({
  name: 'promotions',
  initialState: [],
  reducers: {
    resetPromotions: () => [],
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAndProcessPromotions.fulfilled, (state, action) => {
      // Update the state with the processed data
      return action.payload;
    });
    builder.addCase(fetchAndProcessPromotions.rejected, (state, action) => {
      // Handle errors, e.g., by setting an error state
      // This is just an example; you might want to handle errors differently
      console.error('Error fetching promotions:', action.error.message);
    });
  },
});
export const {
  resetPromotions
} = promotionsSlice.actions;
export default promotionsSlice.reducer;