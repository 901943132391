import axios from "axios";
import { config } from "..";
import { baseId, tableIds } from "../../constant";

const apiPromotionsUrl = `https://api.airtable.com/v0/${baseId}/${tableIds.promotionsTableId}`;


export const fetchPromotionsData = async (promotionIds) => {
    try {
        // Construct a filter formula to match any of the provided promotion IDs
        const filterByPromotionIds = `OR(${promotionIds
            .map((id) => `RECORD_ID()="${id}"`)
            .join(",")})`;
        const urlWithFilter = `${apiPromotionsUrl}?filterByFormula=${encodeURIComponent(
            filterByPromotionIds
        )}`;

        const response = await axios.get(urlWithFilter, config);

        return response.data.records;
    } catch (error) {
        console.error("Error fetching promotions data from Airtable:", error);
    }
};



// Fetch promotion by ID
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const fetchPromotionById = async (id, retries = 5, backoff = 300) => {
    try {
        const response = await axios.get(`${apiPromotionsUrl}/${id}`, config);
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 429 && retries > 0) {
            console.warn(`Retrying fetchPromotionById after ${backoff} ms...`);
            await delay(backoff);
            return fetchPromotionById(id, retries - 1, backoff * 2);
        } else {
            console.error(`Error fetching promos by ID ${id}:`, error);
            throw error;
        }
    }
};

// Function to fetch promotions in batch
export const fetchPromotions = async (ids) => {
    try {
        const promises = ids.map(id => axios.get(`${apiPromotionsUrl}/${id}`, config));
        const responses = await Promise.all(promises);
        return responses.map(response => response.data);
    } catch (error) {
        console.error("Error fetching promotions:", error);
    }
};

// Utility function to introduce delay
// const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// Fetch promotion by establishment ID with retry logic
export const fetchPromotionByEstablishmentId = async (establishmentId, retries = 5, backoff = 300) => {
    const filterFormula = `AND({Active}="Yes", {establishmentId}="${establishmentId}")`;
    const url = `${apiPromotionsUrl}?filterByFormula=${encodeURIComponent(filterFormula)}`;

    try {
        const response = await axios.get(url, config);
        return response.data.records;
    } catch (error) {
        if (error.response && error.response.status === 429 && retries > 0) {
            console.warn(`Retrying fetchPromotionByEstablishmentId after ${backoff} ms...`);
            await delay(backoff);
            return fetchPromotionByEstablishmentId(establishmentId, retries - 1, backoff * 2);
        } else {
            console.error(`Error fetching promotion by establishment ID ${establishmentId}:`, error);
            throw error;
        }
    }
};

