import axios from "axios";
import { config } from "..";
import { baseId, tableIds } from "../../constant";

const apiClaimListingUrl = `https://api.airtable.com/v0/${baseId}/${tableIds.claimListingTableId}`;

export const createClaimListing = async (formData) => {
    try {
        const response = await axios.post(apiClaimListingUrl, { fields: formData }, config);
        return response;
    } catch (error) {
        console.error('Error creating Happy Hour:', error);
        throw error;
    }
};

export const deleteClaimListing = async (id) => {
    try {
        await axios.delete(`${apiClaimListingUrl}/${id}`, config);
        return { success: true };
    } catch (error) {
        console.error('Error deleting Happy Hour:', error);
        throw error;
    }
};

export const getClaimListings = async () => {
    try {
        const response = await axios.get(apiClaimListingUrl, config);
        return response.data.records;
    } catch (error) {
        console.error('Error fetching Happy Hours:', error);
        throw error;
    }
};

export const getStreeClaimListingById = async (id) => {
    try {
        const response = await axios.get(`${apiClaimListingUrl}/${id}`, config);
        return response.data;
    } catch (error) {
        console.error('Error fetching Happy Hours:', error);
        throw error;
    }
};