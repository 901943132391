import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { createUser, isUserAlreadySignIn, verifyUser } from '../../api/users';
import { verifyEstablishmentBelongsToTheUser } from '../../api/establishment';

// Async thunk for signing up
export const Signup = createAsyncThunk('auth/signUp', async ({ userData, establishmentName }, { rejectWithValue }) => {
  try {
    // Check if the user already exists
    const userExists = await isUserAlreadySignIn(userData.email);

    if (userExists) {
      // If the user already exists, throw an error
      throw new Error('A user with these credentials already exists. Either go to login or create an account with a different email.');
    }

    // Verify if the user is an establishment owner
    const isUserEstablishmentOwner = await verifyEstablishmentBelongsToTheUser(establishmentName,userData.Email);

    if (!isUserEstablishmentOwner) {
      // If the user is not an establishment owner, throw an error
      throw new Error('Establishment does not belong to this user. Use another email or claim a listing to proceed.');
    }

    // Proceed with creating a new user if the user does not exist and is an establishment owner
    const newUser = await createUser(userData);
    return newUser;
  } catch (error) {
    // Handle errors during the process
    return rejectWithValue(error.message || error.response?.data);
  }
});

// Async thunk for logging in
export const LogIn = createAsyncThunk('auth/logIn', async ({ username, password }, { rejectWithValue }) => {
  try {
    const user = await verifyUser(username, password);
    return user;
  } catch (error) {
    return rejectWithValue(error.response.data); // Pass the error response data to the action payload
  }
});

// Function to load user from localStorage
const loadUserFromLocalStorage = () => {
  const user = localStorage.getItem('user');
  return user ? JSON.parse(user) : null;
};

// Slice
const authSlice = createSlice({
  name: 'auth',
  initialState: { user: loadUserFromLocalStorage(), error: null }, // Load user from localStorage
  reducers: {
    logOut: (state) => {
      state.user = null;
      // Clear user data from localStorage
      localStorage.removeItem('user');
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(Signup.fulfilled, (state, action) => {
        state.user = action.payload;
        state.error = null;
        // Optionally, save user data to localStorage if needed
        localStorage.setItem('user', JSON.stringify(action.payload));
      })
      .addCase(Signup.rejected, (state, action) => {
        state.error = action.payload;
        console.error("Signup failed with error: ", action.payload);
      })
      .addCase(LogIn.fulfilled, (state, action) => {
        state.user = action.payload;
        // Save user data to localStorage
        localStorage.setItem('user', JSON.stringify(action.payload));
      })
      .addCase(LogIn.rejected, (state, action) => {
        console.error("Login failed with error: ", action.payload);
      });
  },
});

export const { logOut } = authSlice.actions;

export default authSlice.reducer;