import { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import ImageContainer from "../components/ImageContainer";
import img from "../assest/images/login.png";
import Input, { InputWithIcon } from "../components/Input";
import keyIcon from '../assest/icons/key.svg';
import emailIcon from '../assest/icons/email.svg';
import bottle from '../assest/images/bottle.png'
import facebook from '../assest/icons/facebook.svg'
import google from '../assest/icons/google.svg'
import {
    FetchRequestCity,
    fetchCityData,
    fetchStateData,
    requestCity,
} from "../api";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Layout from "../components/Layout";
import { EmailIcon, KeyIcon } from "../utils/icons";
import { LogIn } from "../redux/slices/userSlices";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "../components/Button";

function Login() {

    const location = useLocation();
    const { email, establishment } = location.state || {};

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const user = useSelector((state) => state.auth.user);
    const [error, setError] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [formValue, setFormValue] = useState({
        email: '',
        password: ''
    });

    const handleInputChange = (event) => {
        setError('')
        const name = event.target.name;
        const value = event.target.value;
        setFormValue({ ...formValue, [name]: value });
    };

    const formFields = [
        {
            name: "email",
            placeholder: "Email",
            icon: <EmailIcon />,
            // icon: emailIcon,
            type: 'text'
        },
        {
            name: "password",
            placeholder: "Password",
            icon: <KeyIcon />,
            // icon: keyIcon,
            type: 'password'
        },
    ];

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if all form fields have values
        const allFieldsFilled = Object.values(formValue).every(field => field.trim() !== '');
        if (!allFieldsFilled) {
            setError("Please fill in all fields.");
            return;
        }

        setIsSubmitting(true)
        try {
            const username = formValue.email;
            const password = formValue.password;
            dispatch(LogIn({ username, password })).then((data) => {
                setIsSubmitting(false)
                if (data.type === 'auth/logIn/fulfilled') {
                    navigate('/dashboard');
                } else {
                    setError("Failed to submit your request");
                }
            }).catch((error) => {
                console.error("LogIn failed:", error);
                setIsSubmitting(false)
                setError("Failed to submit your request");
            });
        } catch (error) {
            setIsSubmitting(false)
            // Handle errors, e.g., show an error message
            setError("Failed to submit your request");
        }
    };

    useEffect(() => {
        if (email && establishment) {
            setFormValue((prev) => ({ ...prev, email }));
        }
    }, [email, establishment]);

    return (
        <>
            <Layout>

                <div className="bg-darkgreen rounded-[15px] mx-[15px] my-10 h-[950px] relative px-4">
                    <div className="max-w-[1360px] mt-[41px] mb-5  mx-auto  h-full">
                        <div className="flex justify-center gap-5 w-full pt-12 h-full items-center">
                            <div className="pt-8 text-center">
                                <img src={bottle} alt="bottle" className="mx-auto" />
                                <p className="text-[60px] font-bold leading-[72px] text-white">
                                    Welcome Back
                                </p>
                                <span className="text-white text-lg font-normal">Please enter your details</span>
                                <form
                                    action=""
                                    onSubmit={handleSubmit}
                                    className="max-w-[408px]  py-5 flex gap-y-6 flex-col justify-center items-center text-white"
                                >
                                    {formFields.map((field, index) => {
                                        return (
                                            <div
                                                className="flex gap-4 items-center box w-full"
                                                key={field.name}
                                            >
                                                <InputWithIcon
                                                    type={field.type}
                                                    placeholder={field.placeholder}
                                                    name={field.name}
                                                    handleInputChange={handleInputChange}
                                                    value={formValue[field.name]}
                                                    icon={field.icon}
                                                />
                                            </div>
                                        );
                                    })}
                                    <div className="text-right w-full text-[#9DAAA8]"><Link to="/forgot_password">Forgot password?</Link></div>

                                    {error && <span className="text-red text-bold text-base">
                                        {error}
                                    </span>}

                                    <LoadingButton isLoading={isSubmitting}>
                                        Login
                                    </LoadingButton>


                                    <span>Or</span>

                                    <div className="flex gap-x-3">
                                        <div className="bg-white w-12 h-12 rounded-full text-black flex items-center justify-center">
                                            <img src={facebook} alt="" />
                                        </div>
                                        <div className="bg-white w-12 h-12 rounded-full text-black flex items-center justify-center">
                                            <img src={google} alt="" />
                                        </div>
                                    </div>

                                    <p className="font-normal text-lg text-[#9DAAA8]">Don’t have an account? <Link to="/signup" className="font-bold text-[#fff]">Sign up</Link></p>
                                </form>
                            </div>

                            <div className="w-[718px] hidden md:block">
                                <img src={img} alt="image" className="absolute right-0 top-0 h-full max-h-full object-contain" />
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    );
}
export default Login;
