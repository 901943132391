import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import RequestCity from "./pages/requestCity";
import HomePage from "./pages";
import './App.css'
import Feedback from "./pages/feedback";
import ListYourBusiness from "./pages/ListYourBusiness";
import Login from "./pages/Login";
import ForgotPassword from "./pages/Forgot";
import ClaimListing from "./pages/ClaimListing";
import SignUp from "./pages/Signup";
import Dashboard from "./pages/Dashboard";
import BussinessInfo from "./pages/dashboard/BusinessInfo";
import Settings from "./pages/dashboard/Settings";
import HappyHoursInfo from "./pages/dashboard/HappyHoursInfo";
import GiftCard from "./pages/GiftCard";
import UberCredits from "./pages/UberCredits";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/request-city" element={<RequestCity />} />
        <Route path="/feedback" element={<Feedback />} />
        <Route path="/list-your-business" element={<ListYourBusiness />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/claim-listing" element={<ClaimListing />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/gift-card" element={<GiftCard />} />
        <Route path="/uber-credits" element={<UberCredits />} />
        {/* dashboard routes */} 
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/dashboard/business-info" element={<BussinessInfo />} />
        <Route path="/dashboard/settings" element={<Settings />} />
        <Route path="/dashboard/happy-hours-info" element={<HappyHoursInfo />} />
        {/* Testing routes */} 
        
        {/* Add other routes as needed */}
      </Routes>
    </Router>
  );
}

export default App;