import React, { useState, useEffect } from "react";
// import './BorderLoadingAnimation.css'; // Assuming you have a CSS file named BorderLoadingAnimation.css

export const BorderLoadingAnimation = () => {
  const [perVal, setPerVal] = useState(10);

  useEffect(() => {
    const increament = setInterval(() => {
      setPerVal((prevPerVal) => {
        if (prevPerVal === 100) {
          clearInterval(increament);
          return prevPerVal;
        }
        return prevPerVal + 1;
      });
    }, 100);

    return () => clearInterval(increament); // Cleanup on component unmount
  }, []);

  return (
    <div className={`border-container ${perVal === 100 ? "" : "active"}`}>
      <span className="overlay" id="percent">
        {perVal}%
      </span>
    </div>
  );
};

// Assuming you're using React
export const CircularLoader = ({ progress }) => {
  // Calculate the stroke-dashoffset based on the progress
  const strokeDashoffset = 377 - (377 * progress) / 100;

  return (
    <>
      <svg className="loader-svg svg_1" xmlns="http://www.w3.org/2000/svg">
        <g>
          <ellipse
            ry="60"
            rx="60"
            cy="62.5"
            cx="62.5"
            strokeWidth="4"
            stroke="#1C454F"
            fill="transparent"
          />
          <ellipse
            ry="60"
            rx="60"
            className="circle"
            cy="62.5"
            cx="62.5"
            strokeWidth="4"
            stroke="#FBBC05"
            fill="transparent"
            style={{ strokeDashoffset }} // Dynamically set the stroke-dashoffset
          />
        </g>
      </svg>
    </>
  );
};

export const BorderProgressbar = ({ progress }) => {
  // Calculate the animation duration based on the progress
  const animationDuration = `${progress}%`;

  return (
    <div
      className="border-progress-bar"
      style={{
        animationDuration: animationDuration,
      }}
    >
      LOGIN
    </div>
  );
};
