import React from "react";
import DashboardAvatar from "./DashboardAvatar";
import { useState } from "react";
import { useEffect } from "react";

const DashboardHourChart = ({ hourchartdata}) => {

    const days = [
        "Sun",
        "Mon",
        "Tue",
        "Wed",
        "Thu",
        "Fri",
        "Sat"
      ];
  const { imageUrl, title, pubName, month } = hourchartdata;
  return (
    <div className="rounded-[23px] bg-navy-blue">
      <div className="px-5 pt-5">
        <DashboardAvatar
          imageUrl={imageUrl}
          title={title}
          pubName={pubName}
          month={month}
        />
        <div className="pt-4 pb-[30px]">
        <div className="flex gap-2">
        {days.map((day, index) => (
          <div
            // onClick={() => setActiveDay({ value: index, day: day })} // Add this line
            className={`
                 border-off-gray text-white
            border rounded-full w-12 h-12 transition-all delay-75 flex items-center justify-center cursor-pointer hover:border-2 hover:border-off-gray hover:opacity-100 hover:bg-btn-main`}
            key={index}
          >
            <span className="text-sm font-semibold">{day}</span>
          </div>
        ))}
      </div>
        </div>
          <p className="text-base font-semibold text-dark-silver pb-5">3:00 pm - 6:00 pm</p>
          <ul className="pb-10 list-none text-white text-base font-semibold font-Metropolis gap-1">
            <li>$1 Off Draft Beer</li>
            <li>$5 House Wines</li>
            <li>$7 Select Spirits</li>
            <li>$6 Hand-Crafted Cocktails</li>
          </ul>
          <p className="underline font-Metropolis text-xs font-semibold text-main-orange-50 pb-2 text-center">EDIT HAPPY HOURS</p>
      </div>
    </div>
  );
};

export default DashboardHourChart;
