import React, { useEffect, useState } from "react";
import arrowIcon from "../assest/icons/Cheveron.svg";
const SelectCity = ({ options, handleChange, defaultCity }) => {
  const [selectedOption, setSelectedOption] = useState(defaultCity);
  const [open, setOpen] = useState(false);
  function handleClick(option) {
    setSelectedOption(option);
    handleChange(option);
    setOpen(false);
  }

  useEffect(() => {
    setSelectedOption(defaultCity);
  }, [defaultCity]);
  return (
    <div className="relative w-44 md:w-[218px] h-[60px] md:h-[70px]">
      <button
        onClick={() => setOpen(!open)}
        className="bg-transparent text-white border border-[#F2EADF] md:pl-4 px-2 py-2 w-full h-full rounded-full flex justify-between items-center hover:bg-darkgreen hover:shadow-lg transition-all"
      >
        <p className="pl-3 md:pl-4 text-base md:text-xl font-semibold">
          {selectedOption.label ? selectedOption.label : "Select a city"}
        </p>
        <div
          className={`w-[40px] md:w-[49px] h-[40px] md:h-[49px] bg-btn-main rounded-full flex items-center justify-center ${
            open ? "rotate-180" : "rotate-0"
          }`}
        >
          <img src={arrowIcon} alt="arrow" />
        </div>
      </button>
      {open && (
        <ul className="absolute left-0 mt-2 w-full bg-bg-main border border-white rounded-[20px]  text-white py-4 z-50">
          {options.length ? (
            options.map((option, index) => (
              <li
                key={index}
                onClick={() => handleClick(option)}
                className="px-4 py-2 hover:text-main-orange cursor-pointer text-xl font-medium not-italic"
              >
                {option.label}
              </li>
            ))
          ) : (
            <li className="px-4">Loading...</li>
          )}
        </ul>
      )}
    </div>
  );
};

export default SelectCity;
