import React, { useEffect, useState } from "react";

function TimePicker({ label, setTime, time, updateHappyHourTimes, isEdit }) {
  const [timeSelectorClicked, setTimeSelectorClicked] = useState(false);


  const handleTimeChange = (type, value) => {
    setTimeSelectorClicked(true);
    switch (type) {
      case "hour":
        setTime((prev) => ({ ...prev, hour: value }));
        break;
      case "minute":
        setTime((prev) => ({ ...prev, minute: value }));
        break;
      case "ampm":
        setTime((prev) => ({ ...prev, amPm: value }));
        break;
      default:
        break;
    }
  };

  const updateTime = () => {
    updateHappyHourTimes(time);
    setTimeSelectorClicked(false);
  };

  return (
    <div className="flex flex-col space-y-2">
      <label className="text-sm font-semibold ms-3" htmlFor={label}>
        {label}
      </label>

      <div
        className={`mt-2 p-3 rounded-sm ${
          !isEdit ? "bg-[#FFF] text-black" : "bg-bg-main text-white"
        }`}
      >
        <div className="border-2 bg-transparent flex justify-center items-center">
          <select
            name="hours"
            className="w-10  text-center cursor-pointer hover:bg-bg-main hover:text-white bg-transparent text-xl appearance-none outline-none "
            onChange={(e) => handleTimeChange("hour", e.target.value)}
            value={time.hour}
          >
            {Array.from({ length: 13 }, (_, i) => (
              <option key={i} value={i < 10 ? `0${i}` : i}>
                {i < 10 ? `0${i}` : i}
              </option>
            ))}
          </select>
          <span className="text-xl mx-1">:</span>
          <select
            name="minutes"
            className="w-10  text-center cursor-pointer hover:bg-bg-main hover:text-white bg-transparent text-xl appearance-none outline-none mr-4 "
            onChange={(e) => handleTimeChange("minute", e.target.value)}
            value={time.minute}
          >
            {Array.from({ length: 60 }, (_, i) => (
              <option key={i} value={i < 10 ? `0${i}` : i}>
                {i < 10 ? `0${i}` : i}
              </option>
            ))}
          </select>
          <select
            name="ampm"
            className="w-10  text-center cursor-pointer hover:bg-bg-main hover:text-white bg-transparent text-xl appearance-none outline-none"
            onChange={(e) => handleTimeChange("ampm", e.target.value)}
            value={time.amPm}
          >
            <option value="AM">AM</option>
            <option value="PM">PM</option>
          </select>
        </div>
        {timeSelectorClicked && ( // Conditionally render the buttons based on the state
          <div
            className={`flex justify-end text-sm gap-2 mt-3 ${
              isEdit ? "text-white" : "text-black"
            }`}
          >
            <button
              className="text-sm hover:bg-bg-main hover:text-white px-1 rounded-md"
              onClick={() => setTimeSelectorClicked(false)}
            >
              Cancel
            </button>
            <button
              className="border border-gray-400 p-1 rounded-md text-sm px-1 hover:text-white hover:bg-bg-main"
              onClick={updateTime}
            >
              Save
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default TimePicker;
