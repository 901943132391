import axios from "axios";
import { config } from ".."; // Assuming you have the config for Airtable API
import { baseId, tableIds } from "../../constant";
import bcrypt from 'bcryptjs';

const apiSignUpUserUrl = `https://api.airtable.com/v0/${baseId}/${tableIds.userSignUpTableId}`;

export const UserSignUpToNewsletter = async (data) => {
  try {
    const payload = {
      fields: data,
      typecast: true,
    };
    const response = await axios.post(apiSignUpUserUrl, payload, config);
    return response;
  } catch (error) {
    console.error("Error posting data to Airtable:", error);
    throw error; // Re-throw the error to be handled by the calling function
  }
};

// Function to hash a password
const hashPassword = async (password) => {
  const salt = await bcrypt.genSalt(10);
  return await bcrypt.hash(password, salt);
};

// Function to create a new user
export const createUser = async (userData) => {
  const hashedPassword = await hashPassword(userData.Password);
  const user = {
    fields: {
      ...userData,
      Password: hashedPassword,
    },
  };
  try {
    const response = await axios.post(`https://api.airtable.com/v0/${baseId}/${tableIds.usersTableId}`, user, config);
    return response.data;
  } catch (error) {
    console.error("Error creating user:", error);
    throw error;
  }
};


export const verifyUser = async (email, password) => {
  try {
    // Fetch user records from Airtable
    const response = await axios.get(`https://api.airtable.com/v0/${baseId}/${tableIds.usersTableId}`, config);

    // Search for a user with the provided email in the records array
    const userRecord = response.data.records.find(record => record.fields.Email === email);

    // If user exists, compare password and find establishment associated with the user
    if (userRecord) {
      const isPasswordValid = await bcrypt.compare(password, userRecord.fields.Password);
      if (isPasswordValid) {
        const establishmentResponse = await axios.get(`https://api.airtable.com/v0/${baseId}/${tableIds.establishmentUserId}?filterByFormula=AND({Email} = '${email}')`, config);

        // Combine user and establishment data

        // Extract and structure establishment data from the user record
        const establishments = establishmentResponse.data.records.map(estab => ({
          id: estab.fields.Establishment[0]
        }));

        const userDetail = { ...userRecord, establishments: establishments };
        return userDetail;
      } else {
        throw new Error("Invalid credentials");
      }
    } else {
      throw new Error("No user found with the provided email");
    }
  } catch (error) {
    console.error("Error verifying user:", error);
    throw error; // Re-throw the error to be handled by the caller
  }
};


export const isUserAlreadySignIn = async (email) => {
  try {
    const filterFormula = `Email="${email}"`;
    const response = await axios.get(`https://api.airtable.com/v0/${baseId}/${tableIds.usersTableId}?filterByFormula=${encodeURIComponent(filterFormula)}`, config);

    // Check if there's at least one record with the given email
    return response.data.records.length > 0;
  } catch (error) {
    console.error("Error verifying user:", error);
    throw error;
  }
};

export const fetchEstablishmentUser = async (id) => {
  try {
    const response = await axios.get(`https://api.airtable.com/v0/${baseId}/${tableIds.establishmentUserId}/${id}`, config);
    return response.data;
  } catch (error) {
    console.error('Error fetching Happy Hours:', error);
    throw error;
  }
};

export const updateEstablishmentUser = async (id, updatedFields) => {
  try {
    const response = await axios.patch(`https://api.airtable.com/v0/${baseId}/${tableIds.establishmentUserId}/${id}`, {
      fields: updatedFields,
    }, config);
    return response.data;
  } catch (error) {
    console.error('Error updating Establishment:', error);
    throw error;
  }
};


// Function to send code to the email for password reset
export const sendCodeForPasswordReset = async (email) => {
  try {
    // Here you should implement your logic to send a code to the user's email
    // For example, you can use a third-party service like SendGrid or AWS SES to send emails
    // Since you don't have a backend, you may need to use a client-side email service like Nodemailer
    // or you can directly update a field in the user's record in Airtable to store the code

    // For demonstration purposes, let's assume we're updating a field in Airtable to store the code
    await axios.patch(`https://api.airtable.com/v0/${baseId}/${tableIds.usersTableId}`, {
      fields: {
        ResetCode: "123456" // Example code
      }
    }, config);

    return { message: "Code sent successfully" };
  } catch (error) {
    console.error("Error sending code for password reset:", error);
    throw error;
  }
};

// Function to verify code for password reset
export const verifyCodeForPasswordReset = async (email, code) => {
  try {
    // Here you should implement your logic to verify the code entered by the user
    // For example, you can compare the entered code with the one stored in the user's record in Airtable

    // For demonstration purposes, let's assume the code is stored in a field called ResetCode
    const response = await axios.get(`https://api.airtable.com/v0/${baseId}/${tableIds.usersTableId}`, config);
    const user = response.data.records[0];
    if (user && user.fields.ResetCode === code) {
      return { message: "Code verified successfully" };
    } else {
      throw new Error("Invalid code");
    }
  } catch (error) {
    console.error("Error verifying code for password reset:", error);
    throw error;
  }
};

// Function to reset password
export const resetPassword = async (email, newPassword) => {
  try {
    // Here you should implement your logic to reset the user's password
    // For example, you can update the user's password in Airtable with the new password provided

    // For demonstration purposes, let's assume we're updating the Password field in Airtable
    await axios.patch(`https://api.airtable.com/v0/${baseId}/${tableIds.usersTableId}`, {
      fields: {
        Password: newPassword // Update password
      }
    }, config);

    return { message: "Password reset successfully" };
  } catch (error) {
    console.error("Error resetting password:", error);
    throw error;
  }
};


export const RequestSupport = async (data) => {
  try {
    const response = await axios.post(`https://api.airtable.com/v0/${baseId}/${tableIds.supportTableId}`, { fields: data }, config);
    return response;
  } catch (error) {
    console.error('Error requesting support', error);
    throw error;
  }
};