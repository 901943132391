import React from "react";
import halosvg from "../../assest/icons/dashboardtourch.svg";
import img from "../../assest/images/happyCard1.png";
const Halo = () => {
  return (
    <div className="rounded-[20px] bg-navy-blue px-7 pt-5 pb-5" style={{
      background: `linear-gradient(180deg, #1C444E 6%, #1D3138 100%)`
    }}>
      <div className="flex flex-col justify-center items-center pb-10">
        <div className="flex gap-[15px] items-center">
          <div className="bg-bg-main w-12 h-12 rounded-full -mt-10 flex justify-center items-center">
            <img src={halosvg} />
          </div>
          <div>
            <p className="text-[10px] font-medium font-Metropolis text-dark-silver">
              NEWS
            </p>
            <h2 className="font-bold text-xl font-Metropolis text-white">
              SCOUT & SIP DETAILS
            </h2>
            <p className="text-dark-silver text-sm font-normal font-Mtropolis mt-2.5">
              Elevate Your Profile with the Spotlight Halo - <br /> A Vibrant
              Callout Circle for Maximum Visibility!
            </p>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center w-[290px] mx-auto  relative">
        <div className="w-14 h-14 absolute bg-main-orange-50 rounded-full flex justify-center items-center -top-6 right-10">
          <p className="p-4 text-[8px] font-bold font-Metropolis rotate-[26deg]">
            LIVE <br /> BAND!
          </p>
        </div>
        <img src={img} alt="img" className="" />
      </div>
      <button className="mx-auto flex justify-center items-center mt-4 w-[166px] h-[30px] bg-main-orange-50 rounded-[50px] text-xs font-bold font-Metropolis">
        $7/mo for Early Access
      </button>
    </div>
  );
};

export default Halo;
