import { useEffect, useState } from "react";
import img from "../assest/images/login.png";
import img2 from "../assest/images/forgot-password.png";
import img3 from "../assest/images/code.png";
import { InputWithIcon } from "../components/Input";
import stars from "../assest/icons/stars.svg";
import paddLock from '../assest/icons/padlock-large.svg'
import axios from "axios";
import bcrypt from 'bcryptjs';

import { baseId, tableIds } from "../constant";

import Layout from "../components/Layout";
import { CheckIcon, EmailIcon } from "../utils/icons";
import { resetPassword, sendCodeForPasswordReset, verifyCodeForPasswordReset } from "../api/users";

const apiForgotPasswordUrl = `https://api.airtable.com/v0/${baseId}/${tableIds.forgotPasswordTableId}`;

const formFields = [
    {
        name: "email",
        placeholder: "Type Your Email",
        // icon: emailIcon,
        icon: <EmailIcon />,
        type: 'email'
    },
];

const EnterCodeFormFields = [
    {
        name: "email",
        placeholder: "Type Code send via email",
        // icon: emailIcon,
        icon: <EmailIcon />,
        type: 'email'
    },
];

const ResetPasswordFormFields = [
    {
        name: "password",
        placeholder: "New Password",
        icon: <CheckIcon />,
        type: 'password'
    },
    {
        name: "reset-password",
        placeholder: "Confirm Password",
        icon: <CheckIcon />,
        type: 'password'
    },
];


function ForgotPassword() {


    const [formValue, setFormValue] = useState({
        email: "",
        code: "",
        password: "",
        confirmPassword: ""
    });
    const [step, setStep] = useState(1);
    const [error, setError] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormValue({ ...formValue, [name]: value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError(""); // Clear any previous error messages
        try {
            if (step === 1) {
                // Step 1: Submit email to request code
                await sendCodeForPasswordReset(formValue.email);
                setSuccessMessage("Verification code sent to your email");
                setStep(2); // Move to next step
            } else if (step === 2) {
                // Step 2: Submit code to verify
                await verifyCodeForPasswordReset(formValue.email, formValue.code);
                setSuccessMessage("Code verified");
                setStep(3); // Move to next step
            } else if (step === 3) {
                // Step 3: Submit new password
                if (formValue.password !== formValue.confirmPassword) {
                    setError("Passwords do not match");
                    return;
                }
                // Reset password
                await resetPassword(formValue.email, formValue.password);
                setSuccessMessage("Password reset successfully");
                setStep(4); // Move to next step or redirect to login
            }
        } catch (error) {
            setError(error.message || "An error occurred");
        }
    };

    // Function to hash a password
    const hashPassword = async (password) => {
        const salt = await bcrypt.genSalt(10);
        return await bcrypt.hash(password, salt);
    };
    return (
        <>
            <Layout>

                <div className="bg-darkgreen rounded-[15px] mx-[15px] my-10 h-[950px] relative">
                    <div className="max-w-[1360px] mt-[41px] mb-5  mx-auto  h-full">

                        {step === 1 && (
                            <EmailForm
                                formValue={formValue}
                                handleInputChange={handleInputChange}
                                handleSubmit={handleSubmit}
                                error={error}
                                formFields={formFields}
                            />
                        )}
                        {step === 2 && (
                            <EnterCodeForm
                                formValue={formValue}
                                handleInputChange={handleInputChange}
                                handleSubmit={handleSubmit}
                                error={error}
                                formFields={EnterCodeFormFields}
                            />
                        )}
                        {step === 3 && (
                            <ResetPasswordForm
                                formValue={formValue}
                                handleInputChange={handleInputChange}
                                handleSubmit={handleSubmit}
                                error={error}
                                formFields={ResetPasswordFormFields}
                            />
                        )}
                        {successMessage && <p>{successMessage}</p>}
                    </div>
                </div>
            </Layout>
        </>
    );
}
export default ForgotPassword;

const EmailForm = ({ handleSubmit, formFields, handleInputChange, formValue, onNext }) => {
    return (
        <div className="flex justify-center gap-5 w-full pt-12 h-full items-center">
            <div className="pt-8 text-center">
                <img src={paddLock} alt="paddLock" className="mx-auto" />
                <p className="text-[60px] font-bold leading-[72px] text-white">
                    Forgot your Password?
                </p>
                <span className="text-white text-lg font-normal">Please enter your email</span>
                <form
                    action=""
                    // onSubmit={handleSubmit}
                    className="max-w-[408px] mx-auto py-5 flex gap-y-6 flex-col justify-center items-center text-white"
                >
                    {formFields.map((field, index) => {
                        return (
                            <div
                                className="flex gap-4 items-center box w-full"
                                key={field.name}
                            >
                                <InputWithIcon
                                    type={field.type}
                                    placeholder={field.placeholder}
                                    name={field.name}
                                    handleInputChange={handleInputChange}
                                    value={formValue[field.name]}
                                    icon={field.icon}
                                />
                            </div>
                        );
                    })}
                    <button
                        onClick={onNext}
                        type="submit"
                        className="signin-action-btn"
                    >
                        SEND
                    </button>
                </form>
            </div>

            <div className="w-[718px]">
                <img src={img} alt="image" className="absolute  right-0 top-0 h-auto max-h-full object-contain" />
            </div>
        </div>
    )
}
const EnterCodeForm = ({ handleSubmit, handleInputChange, formFields, formValue, onNext }) => {
    return (
        <div className="flex justify-center gap-5 w-full pt-12 h-full items-center">
            <div className="pt-8 text-center">
                <img src={stars} alt="stars" className="mx-auto" />
                <p className="text-[60px] font-bold leading-[72px] text-white mt-4">
                    Enter Code
                </p>
                <span className="text-white text-lg font-normal">Please enter the code via email</span>
                <form
                    action=""
                    // onSubmit={handleSubmit}
                    className="max-w-[408px] mx-auto py-5 flex gap-y-6 flex-col justify-center items-center text-white"
                >
                    {formFields.map((field, index) => {
                        return (
                            <div
                                className="flex gap-4 items-center box w-full"
                                key={field.name}
                            >
                                <InputWithIcon
                                    type={field.type}
                                    placeholder={field.placeholder}
                                    name={field.name}
                                    handleInputChange={handleInputChange}
                                    value={formValue[field.name]}
                                    icon={field.icon}
                                />
                            </div>
                        );
                    })}
                    {/* <div className="text-right w-full text-[#9DAAA8]"><Link to="/forgot-password">Forgot password?</Link></div> */}
                    <button
                        onClick={onNext}
                        type="submit"
                        className="signin-action-btn"
                    >
                        Submit
                    </button>
                </form>
            </div>

            <div className="w-[718px]">
                <img src={img3} alt="image" className="absolute right-0 top-0 h-full max-h-full object-contain" />
            </div>
        </div>
    )
}
const ResetPasswordForm = ({ handleSubmit, handleInputChange, formFields, formValue, onNext }) => {
    return (
        <div className="flex justify-center gap-5 w-full pt-12 h-full items-center">
            <div className="pt-8 text-center">
                <img src={paddLock} alt="paddLock" className="mx-auto" />
                <p className="text-[60px] font-bold leading-[72px] text-white">
                    Reset Your Password?
                </p>
                <span className="text-white text-lg font-normal">please enter your new password</span>
                <form
                    action=""
                    // onSubmit={handleSubmit}
                    className="max-w-[408px] mx-auto py-5 flex gap-y-6 flex-col justify-center items-center text-white"
                >
                    {formFields.map((field, index) => {
                        return (
                            <div
                                className="flex gap-4 items-center box w-full"
                                key={field.name}
                            >
                                <InputWithIcon
                                    type={field.type}
                                    placeholder={field.placeholder}
                                    name={field.name}
                                    handleInputChange={handleInputChange}
                                    value={formValue[field.name]}
                                    icon={field.icon}
                                />
                            </div>
                        );
                    })}
                    {/* <div className="text-right w-full text-[#9DAAA8]"><Link to="/forgot-password">Forgot password?</Link></div> */}
                    <button
                        onClick={onNext}
                        type="submit"
                        className="signin-action-btn"
                    >
                        SEND
                    </button>
                </form>
            </div>

            <div className="w-[718px]">
                <img src={img2} alt="image" className="absolute right-0 top-0 h-full max-h-full object-contain" />
            </div>
        </div>
    )
}