import React, { useState } from "react";
import { EstablishementIcon } from "../../utils/icons";
import { Link } from "react-router-dom";

function Welcome({
  user,
  showInfo,
  establishments,
  handleInputChange,
  selectedEstablishment,
  establishmentError,
}) {
  return (
    <div className="container mx-auto px-[50px] mb-2 h-screen">
      <div className="flex flex-col gap-6 justify-center items-center h-4/5 max-w-[600px] mx-auto ">
        {!establishments ? (
          <p className="font-normal leading-9 text-2xl text-white text-center">
            Hey, {user?.fields?.Name} with{" "}
            <strong className="underline underline-offset-4">
              {user?.fields?.Establishment}
            </strong>
            {/* Hey, Travis Leslie with Fork Boise */}
          </p>
        ) : (
          <p className="font-normal leading-9 text-2xl text-white text-center">
            Hey, {user?.fields?.Name}
          </p>
        )}
        {!establishmentError ? (
          <>
            <div className="text-left">
              <p className="font-normal leading-9 text-lg text-white">
                Please Select an Establishment you want to update?
              </p>
              <Dropdown
                options={establishments}
                setSelectedValue={(value) =>
                  handleInputChange({
                    target: { name: "establishment", value },
                  })
                }
                selectedValue={selectedEstablishment?.fields?.Name}
                placeholder={"Select the Establishment"}
              />
            </div>
            <h2 className="font-semibold text-4xl leading-9 text-white text-center ">
              Welcome To Scout & Sip
            </h2>
            <p className="font-normal leading-9 text-2xl text-white text-center">
              To get started, please fill out your business information. Once
              that's complete, you can then proceed to enter your Happy Hour
              details.
            </p>

            <button
              type="button"
              onClick={showInfo}
              className={`hover:bg-main-orange-300 transition-colors delay-75 font-semibold text-xl text-bg-main bg-main-orange-200 w-full h-14  mx-auto rounded-xl flex items-center justify-center gap-x-3 max-w-64`}
            >
              <EstablishementIcon activeColor={"#000"} />
              Business Info
            </button>
          </>
        ) : (
          <div className="error-message text-white flex flex-col gap-6">
            <p className="text-red text-center">{establishmentError}</p>

            <Link to="/list_your_business">
              <button
                type="button"
                className={`hover:bg-main-orange-300 transition-colors delay-75 font-semibold text-xl text-bg-main bg-main-orange-200 w-full h-14  mx-auto rounded-xl flex items-center justify-center gap-x-3 max-w-72`}
              >
                <EstablishementIcon activeColor={"#000"} />
                List Your Business
              </button>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}

export default Welcome;

const Dropdown = ({
  options,
  selectedValue,
  setSelectedValue,
  placeholder,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <div className="relative border rounded-lg w-full">
      <div
        onClick={() => setDropdownOpen(!dropdownOpen)}
        className={`cursor-pointer bg-white border-white border h-14 w-full pl-5 flex items-center justify-between text-bg-main rounded-lg`}
      >
        {selectedValue || placeholder}
        <div
          className={`w-[43px] h-[43px] me-[5px] flex items-center justify-center ${
            dropdownOpen ? "rotate-180" : "rotate-0"
          }`}
        >
          <svg
            width="12"
            height="8"
            viewBox="0 0 12 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              y="2.13477"
              width="3"
              height="8"
              transform="rotate(-45 0 2.13477)"
              fill="#000"
            />
            <rect
              x="6.40088"
              y="7.07104"
              width="3"
              height="7"
              transform="rotate(-135 6.40088 7.07104)"
              fill="#000"
            />
          </svg>
        </div>
      </div>
      {dropdownOpen && (
        <ul className="h-fit customize_scroll overflow-y-scroll overflow-x-hidden absolute left-0 mt-2 w-full bg-white border shadow-lg py-3 z-50 text-bg-main">
          {options.length > 0 ? (
            options.map((option, index) => {
              const { response } = option;
              const { fields } = response;
              const value = fields.Name;

              return (
                <li
                  key={index}
                  onClick={() => {
                    setSelectedValue(response);
                    setDropdownOpen(false);
                  }}
                  className="cursor-pointer px-4 py-2 hover:text-main-orange"
                >
                  {value}
                </li>
              );
            })
          ) : (
            <li className="cursor-pointer px-4 py-2 hover:text-main-orange">
              Loading...
            </li>
          )}
        </ul>
      )}
    </div>
  );
};
