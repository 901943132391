import { useEffect, useState } from "react";
import img from "../assest/images/claim.png";
import { InputWithIcon } from "../components/Input";
import { useDispatch, useSelector } from "react-redux";
import {
  createClaimListing,
  getUserRoles,
} from "../api";
import { fetchAllEstablishments } from "../redux/slices/establishmentsSlice";
import Layout from "../components/Layout";
import { Link } from "react-router-dom";
import Button, { LoadingButton } from "../components/Button";

const formFields = [
  {
    name: "city",
    label: "Where is your establishment located?",
    placeholder: "City",
  },
  {
    name: "establishment",
    label: "Which one is your establishment?",
    placeholder: "Enter your city name",
  },
  {
    name: "name",
    label: "What is your name?",
    placeholder: "Your Full Name",
  },
  {
    name: "email",
    label: "What is your email?",
    placeholder: "name@company.com",
  },
  {
    name: "phone",
    label: "What is your phone number?",
    placeholder: "208-777-9889",
  },
  {
    name: "role",
    label: "What is your role in the company?",
    placeholder: "Owner",
  },
];

const initialState = {
  phone: "",
  city: "",
  establishment: "",
  name: "",
  email: "",
  phone: "",
  role: "",
};
function ClaimListing() {
  const dispatch = useDispatch();
  const cities = useSelector((state) => state.cities.list);
  const selectedCity = useSelector((state) => state.cities.selectedCity);
  const allEstablishments = useSelector((state) => state.establishments.all);

  const [establishmentList, setEstablishmentList] = useState([]);
  const [roles, setRoles] = useState([]);


  const [isDone, setisDone] = useState(false);

  const [error, setError] = useState("");
  const [showSuccess, setShowSuccess] = useState("");
  const [formValue, setFormValue] = useState(initialState);

  const [isLoading, setIsLoading] = useState(false);

  // Assuming formValue.city is an array of IDs
  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setFormValue({ ...formValue, [name]: value });

    // Check if the updated field is 'city'
    if (name === 'city') {
      // Filter establishments based on the selected city IDs
      const filteredEstablishments = allEstablishments.filter(establishment => value.fields.Establishments.includes(establishment.id));

      const transformedEstablishments = filteredEstablishments.map(establishment => ({
        ...establishment,
        label: establishment.fields.Name,
        value: establishment.fields.Name,
      }));
      setEstablishmentList(transformedEstablishments);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true)
    // Assuming createClaimListing expects an object with the form values
    const payload = {
      City: [formValue.city.id],
      Establishment: [formValue.establishment.id],
      // ['State (from State) (from Citt)'] :formValue.city.fields.State,
      Name: formValue.name,
      Email: formValue.email,
      ['Phone Number']: formValue.phone,
      Role: formValue.role.value,
    };
    try {
      const response = await createClaimListing(payload);

      if (response.status === 200) {
        setIsLoading(false)
        setisDone(true)
      }
    } catch (error) {
      setIsLoading(false)
      console.error('Error submitting claim listing:', error);
      // Handle error, e.g., show an error message
    }
  };

  const LoadUserRoles = async () => {
    try {
      const data = await getUserRoles();
      // Filter the response to find the object with the name "EstablishmentUsers"
      const establishmentUsers = data.tables.find(table => table.name === "EstablishmentUsers");
      // Check if the "EstablishmentUsers" object was found
      if (establishmentUsers) {
        // Find the "Role" field
        const roleField = establishmentUsers.fields.find(field => field.name === "Role");
        if (roleField && roleField.options && roleField.options.choices) {
          // Map the choices to the desired format
          const roleOptions = roleField.options.choices.map(choice => ({
            value: choice.name, // Assuming 'name' is the property you want to use as the value
            label: choice.name, // Assuming 'name' is the property you want to use as the label
          }));
          // Set the state with the mapped role options
          setRoles(roleOptions);
        } else {
          console.error("Role field or its options not found in the response.");
        }
      } else {
        console.error("EstablishmentUsers not found in the response.");
      }
    } catch (error) {
      console.error("Error fetching user roles:", error);
      setError("Failed to load user roles data");
    }
  };

  useEffect(() => {
    dispatch(fetchAllEstablishments())
      .then(() => {
        console.log('fetched')
      })
      .catch((error) => {
        console.error('Error fetching all establishments:', error);
        // setError('Failed to load all establishments');
      });
    LoadUserRoles();
  }, [dispatch]);



  return (
    <>
      <Layout>
        <div className={`${!isDone ? '' : '!bg-transparent'} bg-bg-time md:bg-darkgreen rounded-[15px] mx-[15px]`}>
          <div className="max-w-[1360px] mt-[41px] mb-5 py-10 2xl:py-[78px] mx-auto min-h-screen h-full flex items-center justify-center flex-col">
            {!isDone ? (
              <>
                <h1 className="text-white text-center font-bold text-lg lg:text-4xl 2xl:text-[80px] leading-8 lg:leading-[72px] letter md:tracking-tighter header-mob w-full">
                  Claim a Listing
                </h1>

                <div className="flex flex-col md:flex-row justify-center gap-10 w-full pt-5 2xl:pt-12 lg:max-w-[80%] mx-auto">
                  <div className="pt-8">
                    <p className="font-semibold text-base md:text-lg 2xl:text-2xl text-center mb-4 text-white max-w-[400px] mx-auto">
                      Please fill out the form completely!
                    </p>
                    <span className="text-main-orange-50 text-center block  text-base">
                      Allow up to 24hrs for a response
                    </span>
                    <form
                      action=""
                      onSubmit={handleSubmit}
                      className="max-w-full md:max-w-[330px] py-5 flex gap-y-4 flex-col justify-center items-center text-white mx-auto px-5"
                    >
                      {formFields.map((field, index) => {
                        return (
                          <div
                            className="flex gap-4 items-center box w-full justify-center"
                            key={field.name}
                          >
                            <div className="flex flex-col gap-2 justify-center w-full">
                              <label htmlFor={field.name}>{field.label}</label>
                              {field.name === "city" && (
                                <Dropdown
                                  options={cities}
                                  setSelectedValue={(value) => handleInputChange({ target: { name: "city", value: value } })}
                                  selectedValue={formValue.city}
                                  placeholder={"Select your City"}
                                  isLoading={cities && cities?.length >= 0}
                                />
                              )}
                              {field.name === "establishment" && (
                                <Dropdown
                                  options={establishmentList}
                                  setSelectedValue={(value) => handleInputChange({ target: { name: "establishment", value: value } })}
                                  selectedValue={formValue.establishment}
                                  placeholder={"Select your Establishment"}
                                  isLoading={allEstablishments && allEstablishments.length >= 0}
                                />
                              )}
                              {field.name === 'role' &&
                                <Dropdown
                                  options={roles}
                                  setSelectedValue={(value) => handleInputChange({ target: { name: "role", value: value } })}
                                  selectedValue={formValue.role}
                                  placeholder={"Select your role"}
                                />}
                              {field.name !== "city" &&
                                field.name !== "establishment" &&
                                field.name !== "role" && (
                                  <InputWithIcon
                                    placeholder={field.placeholder}
                                    name={field.name}
                                    className={"min-w-[320px] bg-white w-full"}
                                    handleInputChange={handleInputChange}
                                    value={formValue[field.name]}
                                  />
                                )}

                            </div>
                          </div>
                        );
                      })}

                      <LoadingButton className={'!h-[56px] mt-4 mx-auto'} isLoading={isLoading}>
                        Submit
                      </LoadingButton>
                      <Link to="/signup" className="text-lg underline text-white underline-offset-4">
                        SignUp
                      </Link>
                      <span className="md:hidden text-xs text-[#fff] font-normal mt-4">*Please note, We may call you to verify information</span>
                      <div
                        className={`text-white text-center w-full mx-auto ${!showSuccess ? "opacity-0" : ""
                          }`}
                      >
                        <h6 className="ml-4 font-bold text-lg">
                          Thank you for your request!
                        </h6>
                        <span className="ml-4 text-lg font-normal">
                          {" "}
                          We look forward having happy hours for{" "}
                          {`${selectedCity || "McCall"}`} Soon!
                        </span>
                      </div>
                    </form>


                  </div>



                  <img src={img} alt="image" className="object-contain hidden md:block" />
                </div>
              </>
            ) : (
              <ThankYouScreen />
            )}
          </div>
        </div>
      </Layout>
    </>
  );
}
export default ClaimListing;

const Dropdown = ({
  // setDropdownOpen,
  // dropdownOpen,
  options,
  selectedValue,
  setSelectedValue,
  placeholder,
  isLoading
}) => {

  const [dropdownOpen, setDropdownOpen] = useState(false)
  return (
    <>
      <div className="relative border rounded md:w-[320px] w-full">
        <div
          onClick={() => setDropdownOpen(!dropdownOpen)}
          className={`cursor-pointer bg-white border-white border h-14 w-full pl-5 flex items-center justify-between text-bg-main`}
        >
          {selectedValue.label || placeholder}

          <div
            className={`w-[43px] h-[43px] me-[5px] rounded-full flex items-center justify-center ${dropdownOpen ? "rotate-180" : "rotate-0"}`}
          >
            <svg
              width="12"
              height="8"
              viewBox="0 0 12 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                y="2.13477"
                width="3"
                height="8"
                transform="rotate(-45 0 2.13477)"
                fill="#000"
              />
              <rect
                x="6.40088"
                y="7.07104"
                width="3"
                height="7"
                transform="rotate(-135 6.40088 7.07104)"
                fill="#000"
              />
            </svg>
          </div>
        </div>
        {dropdownOpen && (
          <ul className="h-60 customize_scroll overflow-y-scroll overflow-x-hidden absolute left-0 mt-2 w-full bg-white border shadow-lg py-3 z-50 text-bg-main">
            {options.length > 0 ? options?.map((option, index) => (
              <li
                key={index}
                onClick={() => {
                  setSelectedValue(option); // Directly call setSelectedValue with the option object
                  setDropdownOpen(false);
                }}
                className="cursor-pointer px-4 py-2 hover:text-main-orange"
              >
                {option.label}
              </li>
            )) :
              <li className="cursor-pointer px-4 py-2 hover:text-main-orange">Loading...</li>
            }
            {/* {isLoading && <li>Loading...</li>} */}
          </ul>
        )}
      </div>
    </>
  );
};

const ThankYouScreen = () => (
  <div className="text-center flex gap-8 flex-col justify-center items-center h-full w-full lg:mt-20 max-w-[670px] m-auto ">
    <h2 className="text-white text-3xl lg:text-6xl tracking-tight font-bold">Thank you!</h2>

    <p className="text-lg leading-6 font-medium xl:text-3xl lg:font-semibold text-white lg:leading-[46px]">
      Please allow 24hrs for a reply. <br /> We will send you an email and may even
      call you if there is a conflict
    </p>

    <Link to={'/'}>
      <button
        type="button"
        className="px-6 h-[56px] bg-main-orange-50 rounded-full  text-lg  leading-10 font-bold  flex items-center justify-center hover:bg-main-orange-300 text-bg-main transition-all max-w-[320px] w-full"
      >
        View Other Listings
      </button>
    </Link>
  </div>
);
