import axios from "axios";
import { config } from "..";
import { baseId, tableIds } from "../../constant";
const apiStreetUrl = `https://api.airtable.com/v0/${baseId}/${tableIds.streetTableId}`;

export const createStreet = async (street) => {
    try {
        const response = await axios.post(apiStreetUrl, { fields: street }, config);
        return response;
    } catch (error) {
        console.error('Error creating Happy Hour:', error);
        throw error;
    }
};

export const deleteStreet = async (id) => {
    try {
        await axios.delete(`${apiStreetUrl}/${id}`, config);
        return { success: true };
    } catch (error) {
        console.error('Error deleting Happy Hour:', error);
        throw error;
    }
};

export const getStreets = async () => {
    try {
        const response = await axios.get(apiStreetUrl, config);
        return response.data.records;
    } catch (error) {
        console.error('Error fetching Happy Hours:', error);
        throw error;
    }
};

export const getStreetsById = async (id) => {
    try {
        const response = await axios.get(`${apiStreetUrl}/${id}`, config);
        return response.data;
    } catch (error) {
        console.error('Error fetching Happy Hours:', error);
        throw error;
    }
};