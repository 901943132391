import React, { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import success from "../../../assest/images/success.png";
import AddHappyHours from "./AddHappyHours";

const DAYS_OF_WEEK = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const AddHappyHoursInfo = ({ setShowNextStep, onSubmit, happyHoursData }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [getStarted, setGetStarted] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [selectedDay, setSelectedDay] = useState("Monday");
  const [doneDays, setDoneDays] = useState([]);
  const [formData, setFormData] = useState({
    happyHours: Object.fromEntries(DAYS_OF_WEEK.map((day) => [day, []])),
  });

  const location = useLocation();
  const isDashboardView = location.pathname === "/dashboard/happy_hours_info";

  const handleChangeDay = useCallback((day) => {
    setSelectedDay(day);
    setIsModalOpen((prev) => !prev);
  }, []);

  const days = DAYS_OF_WEEK.map((day) => ({
    value: day.substring(0, 3),
    label: day,
    onClick: () => handleChangeDay(day),
  }));

  const handleNext = useCallback(() => {
    let nextDayIndex =
      (DAYS_OF_WEEK.indexOf(selectedDay) + 1) % DAYS_OF_WEEK.length;
    let nextDay = DAYS_OF_WEEK[nextDayIndex];

    while (doneDays.includes(nextDay)) {
      nextDayIndex = (nextDayIndex + 1) % DAYS_OF_WEEK.length;
      nextDay = DAYS_OF_WEEK[nextDayIndex];
    }

    if (nextDayIndex === 0) {
      handleSubmit();
    } else {
      setSelectedDay(nextDay);
      setCurrentStep(nextDayIndex + 1);
    }
  }, [selectedDay, doneDays]);

  const handleSubmit = useCallback(() => {
    onSubmit(formData.happyHours);

    if (!isDashboardView) {
      setGetStarted(true);
      setShowNextStep(3);
    }
  }, [formData, onSubmit, setShowNextStep]);

  const handleDoneDays = useCallback(
    (day) => {
      setDoneDays((prevDays) =>
        prevDays.includes(day) ? prevDays : [...prevDays, day]
      );
      if (currentStep === 7) {
        handleSubmit();
      }
    },
    [currentStep, handleSubmit]
  );

  useEffect(() => {
    if (isDashboardView) {
      setGetStarted(true);
    }
  }, [isDashboardView]);

  useEffect(() => {
    if (happyHoursData) {
        const updatedFormData = { ...formData };

        // Flatten the array of arrays
        const flattenedData = happyHoursData.flat();

        flattenedData.forEach((item) => {
            const day = item.fields["Happy Hour Day"][0];
            const newEntry = {
                start: item.fields["Start Time"],
                end: item.fields["End Time"],
                offer: item.fields["Happy Hour Offer"],
                establishments: item.fields["Establishments"],
                hhId: item.id,
            };


            // Check if the new entry already exists to avoid duplication
            const entryExists = updatedFormData.happyHours[day].some(
                (entry) =>
                    entry.start === newEntry.start &&
                    entry.end === newEntry.end &&
                    entry.offer === newEntry.offer &&
                    JSON.stringify(entry.establishments) === JSON.stringify(newEntry.establishments)
            );


            if (!entryExists) {
                updatedFormData.happyHours[day].push(newEntry);
            }
        });

        setFormData(updatedFormData);
    }
}, [happyHoursData]);


  return (
    <div
      className={`h-auto min-h-screen xl:h-auto rounded-[15px] xs:mx-2 mx-[15px] my-8 ${
        isDashboardView ? "bg-transparent" : "bg-darkgreen"
      }`}
    >
      <div
        className={`xs:px-2 px-4 py-8 flex flex-col text-white rounded h-full items-center xl:justify-start xl:py-20 justify-center min-h-screen md:min-h-[unset] ${
          isDashboardView ? "!py-5 2xl:!py-10 !px-0" : ""
        }`}
      >
        {!getStarted ? (
          <div
            className={`flex flex-col gap-y-10 ${isDashboardView && "hidden"}`}
          >
            <img
              src={success}
              className="pl-2 w-24 h-24 md:w-48 md:h-48 mx-auto"
              alt=""
            />
            <h2 className="text-xl text-center md:text-3xl font-medium">
              Now let's get your Happy Hour Info
            </h2>
            <button
              onClick={() => setGetStarted(true)}
              className="w-60 h-16 font-bold mx-auto  px-4 py-2 rounded orange-btn"
            >
              LET'S DO IT!
            </button>
          </div>
        ) : (
          <AddHappyHours
            formData={formData}
            currentStep={currentStep}
            setFormData={setFormData}
            handleNext={handleNext}
            isModalOpen={isModalOpen}
            setSelectedDay={setSelectedDay}
            selectedDay={selectedDay}
            setIsModalOpen={setIsModalOpen}
            handleChangeDay={handleChangeDay}
            days={days}
            doneDays={doneDays}
            handleDoneDays={handleDoneDays}
            handleSubmit={handleSubmit}
            isDashboardView={isDashboardView}
          />
        )}
      </div>
    </div>
  );
};

export default AddHappyHoursInfo;
