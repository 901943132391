import React from "react";
import { Link } from "react-router-dom";
import alertIcon from "../assest/icons/alert.svg";
import businessIcon from "../assest/icons/Business.svg";
import mapIcon from "../assest/icons/map.svg";
import { useIsMobile } from "../hooks";
const Footer = () => {
  const isMobileView = useIsMobile();
  return (
    <div className="bg-bg-time pt-8">
      <div className="max-w-[1360px] mx-auto flex flex-col md:h-52 justify-center items-center gap-y-10 px-4">
        <div className="flex w-full justify-between items-center">
          <div className="flex-col gap-y-4 hidden md:flex">
            <Link to={"/list-your-business"}>
              <button className="orange-btn p-2 rounded-full flex items-center justify-center w-[200px] font-bold  text-base border border-transparent hover:bg-bg-main hover:text-white hover:border-main-orange-50  gap-2 h-14">
                <img className="mb-1" src={mapIcon} alt="icon" />
                List Your Business
              </button>
            </Link>
            <button className="bg-btn-main text-white p-2 rounded-full flex items-center justify-center w-[200px] font-bold  text-base border border-transparent hover:bg-bg-main hover:text-white hover:border-main-orange-50  gap-2 h-14">
              <img src={businessIcon} alt="icon" />
              Business Login
            </button>
          </div>

          <div className="boosted-happy-hour !hidden md:!flex">
            <svg
              width="30"
              height="35"
              viewBox="0 0 30 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M29.0807 12.9771L17.2241 1.12046C15.9984 -0.105305 14.0038 -0.105375 12.7779 1.12046C11.676 2.22233 11.5811 3.90283 12.3948 5.10281L12.3783 5.18534C11.5235 9.45981 9.44244 13.3484 6.35992 16.4309L0.921606 21.8692C-0.307097 23.098 -0.307307 25.0866 0.921606 26.3154L3.88567 29.2794C5.1143 30.5081 7.10307 30.5082 8.33184 29.2794L9.07289 28.5384L14.2601 33.7256C15.4888 34.9544 17.4774 34.9544 18.7062 33.7256C19.932 32.4998 19.932 30.5053 18.7062 29.2795L16.4831 27.0564L17.2242 26.3154C18.4529 25.0867 18.453 23.0981 17.2242 21.8693L16.7206 21.3656C19.1979 19.6312 22.0114 18.4236 25.0159 17.8227L25.0995 17.806C26.3262 18.6342 28.0028 18.5011 29.0807 17.4231H29.0808C30.3064 16.1974 30.3064 14.2029 29.0807 12.9771ZM6.84981 27.7974C6.44029 28.2069 5.77723 28.2069 5.3677 27.7973L2.40364 24.8333C1.99405 24.4236 1.99405 23.7608 2.40364 23.3512L7.59079 18.164L12.037 22.6102C11.5295 23.1177 7.36981 27.2773 6.84981 27.7974ZM17.2241 30.7615C17.6327 31.17 17.6327 31.8349 17.2241 32.2435C16.8156 32.652 16.1506 32.652 15.7421 32.2435L10.5549 27.0563L12.037 25.5742L17.2241 30.7615ZM13.519 24.0922C13.834 23.7772 14.3056 23.2946 15.0499 22.659L15.7421 23.3512C16.1517 23.7608 16.1517 24.4236 15.7421 24.8333L15.001 25.5743L13.519 24.0922ZM13.5635 21.1727L9.02817 16.6374C11.4587 13.8309 13.1992 10.5181 14.1304 6.91903L23.2819 16.0705C19.6828 17.0016 16.3701 18.7422 13.5635 21.1727ZM27.5985 15.941C27.1889 16.3505 26.526 16.3505 26.1164 15.941L14.26 4.08453C13.8504 3.67487 13.8504 3.01209 14.26 2.6025C14.6696 2.1929 15.3325 2.1929 15.7421 2.6025L27.5985 14.4589C28.007 14.8675 28.007 15.5323 27.5985 15.941Z"
                fill="#F2EADF"
              />
            </svg>
            <span className="font-semibold text-xl">Boosted Happy Hours</span>
          </div>

          <div className="grid grid-cols-2 flex-col md:flex-row gap-x-16 md:gap-x-8 gap-y-2 md:gap-y-5 flex-wrap">
  <Link to="/">
    <div className="cursor-pointer hover:text-main-orange text-sm md:text-sm font-normal leading-[38.444px] text-white font-Metropolis mb-2 md:mb-0">
      Happy Hours
    </div>
  </Link>
  <Link to="/feedback">
    <div className="cursor-pointer hover:text-main-orange text-sm md:text-sm font-normal leading-[38.444px] text-white font-Metropolis mb-2 md:mb-0">
      Feedback
    </div>
  </Link>
  <Link to="/request-city">
    <div className="cursor-pointer hover:text-main-orange text-sm md:text-sm font-normal leading-[38.444px] text-white font-Metropolis mb-2 md:mb-0">
      Request a city
    </div>
  </Link>
  {!isMobileView ? (
    <Link to="/signup">
      <div className="cursor-pointer hover:text-main-orange text-sm md:text-sm font-normal leading-[38.444px] text-white font-Metropolis mb-2 md:mb-0">
        Sign Up
      </div>
    </Link>
  ) : (
    <Link to="/list-your-business">
      <div className="cursor-pointer hover:text-main-orange text-sm md:text-sm font-normal leading-[38.444px] text-white font-Metropolis mb-2 md:mb-0">
        List Your Business
      </div>
    </Link>
  )}
  <Link to="/claim-listing">
    <div className="cursor-pointer hover:text-main-orange text-sm md:text-sm font-normal leading-[38.444px] text-white font-Metropolis mb-2 md:mb-0">
      Claim a Listing
    </div>
  </Link>
    <a href="mailto:travis@scoutsip.com">
      <div className="cursor-pointer hover:text-main-orange text-sm md:text-sm font-normal leading-[38.444px] text-white font-Metropolis mb-2 md:mb-0">
        Contact
      </div>
    </a>
</div>

        </div>
        <span className="text-sm font-normal text-white mb-4 md:mb-0">
          Copyright @Scout&Sip 2024
        </span>
      </div>
    </div>
  );
};

export default Footer;
