// StepperForm.jsx
import React, { useState, useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { fetchAmenities, fetchCategories, fetchCityData, fetchStateData, getUserRoles } from "../api";
import list from "../assest/images/list.png";
import AddBusinessDetails from "../components/sections/AddBusinessDetails";
import AddEstasblishementDetail from "../components/sections/AddEstablishmentDetail";
import AddHappyHoursInfo from "../components/sections/AddHappyHoursInfo";
import ItemList from "../components/itemslist";
import Layout from "../components/Layout";
import { useSelector } from "react-redux";


function ListYourBusiness() {
  const [selectedCity, setSelectedCity] = useState("");
  const [cities, setCities] = useState([]);

  const [error, setError] = useState("");
  const [getStarted, setGetStarted] = useState(false);
  const [categories, setCategories] = useState([]);
  const [amenities, setAmenities] = useState([]);
  const [states, setStates] = useState([]);
  const [roles, setRoles] = useState([]);


  const LoadCitiesData = async () => {
    try {
      const data = await fetchCityData();
      const transformedData = data.map((record) => ({
        ...record,
        Promotions: record.fields?.Promotions,
        Establishments: record.fields?.Establishments,
      }));

      const defaultCity = data.find(
        (record) => record.fields?.Name === "Boise"
      );

      if (defaultCity) {
        setSelectedCity({
          ...defaultCity,
          label: defaultCity.fields.Name,
          value: defaultCity.fields.Name,
          Establishments: defaultCity.fields.Establishments,
          Promotions: defaultCity.fields.Promotions,
        });
      }

      setCities(transformedData);
    } catch (error) {
      console.error("Error fetching cities:", error);
      setError("Failed to load city data");
    }
  };

  const LoadStates = async () => {
    try {
      const data = await fetchStateData();
      const sortedOptions = [...data].sort((a, b) =>
        a.label.localeCompare(b.label)
      );
      setStates(sortedOptions);
    } catch (error) {
      console.error("Error fetching cities:", error);
      setError("Failed to load city data");
    }
  };

  const LoadUserRoles = async () => {
    try {
      const data = await getUserRoles();
      // Filter the response to find the object with the name "EstablishmentUsers"
      const establishmentUsers = data.tables.find(table => table.name === "EstablishmentUsers");
      // Check if the "EstablishmentUsers" object was found
      if (establishmentUsers) {
        // Find the "Role" field
        const roleField = establishmentUsers.fields.find(field => field.name === "Role");
        if (roleField && roleField.options && roleField.options.choices) {
          // Map the choices to the desired format
          const roleOptions = roleField.options.choices.map(choice => ({
            value: choice.name, // Assuming 'name' is the property you want to use as the value
            label: choice.name, // Assuming 'name' is the property you want to use as the label
          }));
          // Set the state with the mapped role options
          setRoles(roleOptions);
        } else {
          console.error("Role field or its options not found in the response.");
        }
      } else {
        console.error("EstablishmentUsers not found in the response.");
      }
    } catch (error) {
      console.error("Error fetching user roles:", error);
      setError("Failed to load user roles data");
    }
  };


  const LoadCategoriesData = async () => {
    try {
      const data = await fetchCategories();

      setCategories(data);
    } catch (error) {
      console.error("Error fetching cities:", error);
      setError("Failed to load city data");
    }
  };
  const LoadAmenitiesData = async () => {
    try {
      const data = await fetchAmenities();

      setAmenities(data);
    } catch (error) {
      console.error("Error fetching cities:", error);
      setError("Failed to load city data");
    }
  };

  useEffect(() => {
    LoadStates();
    LoadCitiesData();
    LoadCategoriesData();
    LoadUserRoles();
    LoadAmenitiesData();
  }, []);

  const [businesses, setBusinesses] = useState({
    businessDetails: null,
    HappyHours: null,
    estasblishementDetails: null,
  });

  const handleBusinessDetailsSubmit = (details) => {
    setBusinesses((prevBusinesses) => ({
      ...prevBusinesses,
      businessDetails: details,
    }));
  };

  const handleHappyHoursSubmit = (happyHours) => {
    setBusinesses((prevBusinesses) => ({
      ...prevBusinesses,
      HappyHours: happyHours,
    }));
  };

  const handleEstasblishementDetailsSubmit = (details) => {
    setBusinesses((prevBusinesses) => ({
      ...prevBusinesses,
      estasblishementDetails: details,
    }));
  };
  const [showNextStep, setShowNextStep] = useState(1);

  const renderForm = () => {
    switch (showNextStep) {
      case 1:
        return (
          <AddBusinessDetails
            categories={categories}
            amenities={amenities}
            setShowNextStep={setShowNextStep}
            onSubmit={handleBusinessDetailsSubmit}
            cities={cities}
            states={states}
            roles={roles}
          />
        );
      case 2:
        return (
          <AddHappyHoursInfo
            setShowNextStep={setShowNextStep}
            onSubmit={handleHappyHoursSubmit}
          />
        );
      case 3:
        return (
          <AddEstasblishementDetail
            setShowNextStep={setShowNextStep}
            onSubmit={handleEstasblishementDetailsSubmit}
            businesses={businesses}
            cities={cities}
            states={states}
          />
        );
      default:
        return null;
    }
  };
  return (
    <div>
      <Layout>
        {/* <ItemList /> */}

        {!getStarted && (
          <div className="bg-darkgreen rounded-[15px] xs:mx-2 mx-[15px]">
            <div className="max-w-[1360px] mt-[41px] mb-5 py-[78px] mx-auto">
              <div className="text-center hidden lg:block">
                <h1 className="text-white text-center font-extrabold text-[60px] leading-[72px] letter tracking-[-3px] ">
                  List Your Business
                </h1>
                <span className="text-white text-xl font-medium">
                  Showcase Your Business, Connect with Customers
                </span>
              </div>

              <div className="flex flex-col md:flex-row justify-center gap-x-14 w-full pt-12 items-center">
                <div className="flex flex-col space-y-5 mb-10">
                  <h2 className="text-4xl text-center leading-10 lg:text-[80px] lg:leading-[100px] font-bold text-[#fff]">
                    Join the Buzz
                  </h2>
                  <p className="text-center font-medium text-2xl  mb-4 text-white max-w-[400px] ">
                    Hundreds Daily Choose Scout & Sip for the Hottest Happy Hours!
                  </p>
                  <button
                    onClick={() => setGetStarted(true)}
                    className="h-16 rounded-md mx-auto md:ml-0 lg:rounded-full lg:bg-btn-main lg:text-white w-[216px] border border-transparent hover:bg-bg-main hover:border-main-orange-50 hover:text-[#fff] bg-main-orange-50 text-bg-main font-bold"
                  >
                    Get Started
                  </button>
                </div>

                <img
                  src={list}
                  alt="image"
                  className="object-contain w-fit h-auto max-h-[500px]"
                />
              </div>
            </div>
          </div>
        )}
        {getStarted && renderForm()}

      </Layout>
    </div>
  );
}

export default ListYourBusiness;

