import React from "react";
import FilterButton from "./FilterButton";

const FilterContainer = ({ setHandleFilter, selectedFilterCount,   resetFilters }) => {

  return (
    <div className="flex flex-col gap-4 w-[200px]">
      <FilterButton
        FilterText={"Filters"}
        selectedFilterCount={selectedFilterCount}
        selectColor="bg-[#14252B]"
        selectSize={"w-7 h-7"}
        onClick={setHandleFilter}
      />
      <FilterButton
        className={"bg-transparent border border-white"}
        FilterText={"Remove Filters"}
        onClick={resetFilters}
      />
    </div>
  );
};

export default FilterContainer;
