import React, { useEffect, useState } from "react";
import { HappyHourTime } from "../../Time";
import done from "../../../assest/icons/done.svg";
import edit from "../../../assest/icons/edit-2.svg";
import editicon from "../../../assest/icons/editicon.svg";
import trash from "../../../assest/icons/trash-2.svg";
import ConfirmationModal from "../../Popups/ConfirmationModal";
import AddHappyHourTime from "../../AddHappyHourTime";

const DEFAULT_OFFER_STATE = {
  offer: "",
  start: "03:00:PM",
  end: "05:00:PM",
};

const AddHappyHours = (props) => {
  const {
    formData,
    setFormData,
    handleNext,
    isModalOpen,
    selectedDay,
    handleChangeDay,
    days,
    currentStep,
    handleDoneDays,
    doneDays,
    handleSubmit,
    setIsModalOpen,
    isDashboardView,
  } = props;

  const [showList, setShowList] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteIndex, setDeleteOfferIndex] = useState(null);
  const [error, setError] = useState("");
  const [newOffer, setNewOffer] = useState(DEFAULT_OFFER_STATE);
  const [selectedOffer, setSelectedOffer] = useState(null);

  // Add state to track the selected offer for editing
  const [editingOffer, setEditingOffer] = useState(null);

  const deleteHappyHour = (day, index) => {
    setFormData((prevState) => ({
      ...prevState,
      happyHours: {
        ...prevState.happyHours,
        [day]: prevState.happyHours[day].filter((_, i) => i !== index),
      },
    }));
    setNewOffer(DEFAULT_OFFER_STATE);
    setEditingOffer(null);
    setOpenDelete(false);
  };

  const handleAddHappyHourOffer = () => {
    if (!validateOffer(newOffer)) {
      setError("Please fill in all fields before adding a happy hour.");
      return;
    }

    if (editingOffer) {
      updateOffer();
    } else {
      addNewOffer();
    }

    setNewOffer(DEFAULT_OFFER_STATE);
    // setShowList(true);
  };

  const validateOffer = (offer) => {
    return (
      offer.offer && offer.start !== "00:00:AM" && offer.end !== "00:00:PM"
    );
  };

  const addNewOffer = () => {
    setFormData((prevState) => ({
      ...prevState,
      happyHours: {
        ...prevState.happyHours,
        [selectedDay]: [...prevState.happyHours[selectedDay], newOffer],
      },
    }));
    setError("");
    handleChangeDay(selectedDay);
  };

  const updateOffer = () => {
    const offerIndex = formData.happyHours[selectedDay].findIndex(
      (item) => item.offer === editingOffer.offer
    );

    setFormData((prevState) => ({
      ...prevState,
      happyHours: {
        ...prevState.happyHours,
        [selectedDay]: prevState.happyHours[selectedDay].map((item, index) =>
          index === offerIndex ? newOffer : item
        ),
      },
    }));

    setEditingOffer(null);
  };

  const handleEditClick = (offer) => {
    setIsModalOpen(false);
    setEditingOffer(offer);
    setNewOffer(offer);
  };

  const offerValue = newOffer.offer;
  const startValue = newOffer.start;
  const endValue = newOffer.end;

  const isEditingDay = doneDays.includes(selectedDay);

  useEffect(() => {
    // reset when user changes days
    setEditingOffer(null);
    setSelectedOffer(null);
    setNewOffer(DEFAULT_OFFER_STATE);
    setIsModalOpen(true);
  }, [selectedDay]);

  useEffect(() => {
    if (formData.happyHours[selectedDay].length > 0) {
      setShowList(true);
    } else {
      setShowList(false);
    }
  }, [formData, selectedDay]);

  return (
    <div className="flex flex-col-reverse md:flex-row w-full mx-auto gap-x-10 2xl:gap-x-20 justify-between max-w-[1360px]  relative">
      <div className={`flex flex-col gap-y-4 max-w-[566px] `}>
        <div className="flex gap-3 justify-center md:justify-start mb-5 flex-wrap md:flex-nowrap">
          {days.map((day, index) => (
            <DayButton
              key={index}
              index={index}
              day={day}
              selectedDay={selectedDay}
              doneDays={doneDays}
              currentStep={currentStep}
              onClick={day.onClick}
              editingOffer={editingOffer}
            />
          ))}
        </div>

        {/* {!isModalOpen && (
          <> */}
        {formData.happyHours[selectedDay].length !== 0 && (
          <div className="w-full text-start md:hidden">
            {isEditingDay && (
              <button
                onClick={handleNext}
                className=" bg-bg-main max-w-[337px] w-full ml-auto h-16 font-bold text-xl text-[#fff] px-4 py-2 rounded uppercase"
              >
                DONE WITH {selectedDay}
              </button>
            )}
            {/* Happy Hours Offers List */}
            <OfferList
              selectedDay={selectedDay}
              formData={formData}
              editingOffer={editingOffer}
              deleteOffer={(index) => {
                setDeleteOfferIndex(index);
                setOpenDelete(true);
              }}
              handleEditClick={handleEditClick}
              showList={showList}
              isEditingDay={isEditingDay}
              setSelectedOffer={setSelectedOffer}
              selectedOffer={selectedOffer}
            />

            <ConfirmationModal
              open={openDelete}
              onClose={() => setOpenDelete(false)}
              handleDelete={() => deleteHappyHour(selectedDay, deleteIndex)}
            />
          </div>
        )}
        {/* </>
        )} */}
        {/* {error && <span className="text-red text-lg">{error}</span>} */}
        {isModalOpen && !isEditingDay && (
          <Modal
            isOpen={isModalOpen}
            handleChangeDay={handleChangeDay}
            selectedDay={selectedDay}
            handleNext={handleNext}
            isHappyHouradded={formData.happyHours[selectedDay].length}
            handleDoneDays={handleDoneDays}
            isEditingDay={doneDays.includes(selectedDay)}
            handleSubmit={handleSubmit}
            isDashboardView={isDashboardView}
          />
        )}

        <div
          className={`flex flex-col gap-y-7 ${
            isModalOpen && !isEditingDay && "opacity-50"
          }`}
        >
          {!isEditingDay && (
            <>
              {editingOffer ? (
                <h2 className="text-3xl font-semibold">
                  Edit {selectedDay} Happy Hours
                </h2>
              ) : (
                <h2
                  className={`text-3xl font-semibold ${
                    isModalOpen && isDashboardView ? "hidden" : ""
                  }`}
                >
                  What is your Happy Hour for {selectedDay}?
                </h2>
              )}
            </>
          )}
          {isEditingDay && !editingOffer && (
            <h2 className="text-3xl font-semibold">
              Add an Additional Happy Hour?
            </h2>
          )}
          {editingOffer && isEditingDay && (
            <h2 className="text-3xl font-semibold">
              Edit {selectedDay} Happy Hours
            </h2>
          )}
          {/* Form Fields */}
          {error && <p className="text-red">{error}</p>}
          <div
            className={`flex flex-col gap-y-7 ${
              isModalOpen && !isEditingDay && "opacity-50"
            }`}
          >
            <label className="font-semibold text-2xl">Happy Hour Offer</label>
            <input
              className={`min-w-full h-14  font-normal text-xl px-5 rounded ${
                editingOffer
                  ? "bg-bg-main text-white"
                  : "bg-[#FFF] text-[#141414]"
              } ${isDashboardView ? "border" : ""}`}
              type="text"
              placeholder="$5 House Wines"
              value={offerValue}
              onChange={(e) =>
                setNewOffer((prev) => ({ ...prev, offer: e.target.value }))
              }
            />
            {/* Add time */}

            <AddHappyHourTime
              defaultstartValue={startValue}
              defaultendValue={endValue}
              setNewOffer={setNewOffer}
              newOffer={newOffer}
              isEdit={editingOffer}
            />

            <button
              onClick={handleAddHappyHourOffer}
              className="orange-btn w-full  mx-auto h-16 font-bold text-xl text-[#fff] px-4 py-2 rounded"
            >
              {editingOffer ? "SAVE OFFER" : "+ ADD HAPPY HOUR"}
            </button>
          </div>
        </div>
      </div>

      <div className="w-full text-start hidden md:block">
        {isEditingDay && (
          <button
            onClick={handleNext}
            className={` max-w-[337px] w-full ml-auto h-16 font-bold text-xl text-[#fff] px-4 py-2 rounded uppercase ${
              isDashboardView
                ? "bg-darkgreen hover:bg-opacity-75"
                : "bg-bg-main"
            }`}
          >
            DONE WITH {selectedDay}
          </button>
        )}
        {/* Happy Hours Offers List */}
        <OfferList
          selectedDay={selectedDay}
          formData={formData}
          editingOffer={editingOffer}
          deleteOffer={(index) => {
            setDeleteOfferIndex(index);
            setOpenDelete(true);
          }}
          handleEditClick={handleEditClick}
          showList={showList}
          isEditingDay={isEditingDay}
          setSelectedOffer={setSelectedOffer}
          selectedOffer={selectedOffer}
        />

        <ConfirmationModal
          open={openDelete}
          onClose={() => setOpenDelete(false)}
          handleDelete={() => deleteHappyHour(selectedDay, deleteIndex)}
        />
      </div>
    </div>
  );
};

export default AddHappyHours;

const Modal = ({
  isOpen,
  handleChangeDay,
  selectedDay,
  handleNext,
  isHappyHouradded,
  handleDoneDays,
  isEditingDay,
  handleSubmit,
  isDashboardView,
}) => {
  if (!isOpen) return null;
  // if(isEditingDay)  return null

  const handleDone = () => {
    handleDoneDays(selectedDay);
    handleChangeDay(selectedDay);
    handleNext();
  };
  const handleYesClick = () => {
    handleChangeDay(selectedDay);
  };

  const handleNoClick = () => {
    handleDoneDays(selectedDay);
    handleNext();
  };
  return (
    <div
      className={`${
        isDashboardView ? "bg-transparent" : "bg-darkgreen"
      } w-full max-w-[565px] max-h-fit rounded z-50 text-white`}
    >
      {isHappyHouradded ? (
        <h2 className="text-4xl font-semibold mb-4">
          Would you like to add another Happy Hour for {selectedDay}?
        </h2>
      ) : (
        <h2 className="text-4xl font-semibold mb-4">
          Do you offer a Happy Hour on {selectedDay}?
        </h2>
      )}

      <div className="flex justify-start gap-x-4">
        <button
          type="button"
          onClick={handleYesClick}
          className=" cursor-pointer orange-btn px-4 py-2 rounded"
        >
          Yes
        </button>
        {!isHappyHouradded ? (
          <button
            type="button"
            onClick={handleNoClick}
            className={` cursor-pointer text-[#fff] px-4 py-2 rounded ml-4 transition-all delay-75 hover:border-[#fff] border border-transparent ${
              isDashboardView ? "bg-darkgreen" : "bg-bg-main"
            }`}
          >
            No
          </button>
        ) : (
          <button
            onClick={handleDone}
            className={`  max-w-[337px] w-full h-15 font-bold text-xl text-[#fff] px-4 py-2 rounded uppercase
            hover:border-[#fff] border border-transparent ${
              isDashboardView ? "bg-darkgreen" : "bg-bg-main"
            }`}
          >
            DONE WITH {selectedDay}
          </button>
        )}
      </div>
    </div>
  );
};

const DayButton = ({
  index,
  day,
  selectedDay,
  doneDays,
  currentStep,
  onClick,
  editingOffer,
}) => {
  return (
    <div
      onClick={onClick}
      className={`border rounded-full w-[70px] h-[70px] flex items-center justify-center cursor-pointer relative
      ${
        selectedDay === day.label || doneDays.includes(day.label)
          ? "opacity-100"
          : "opacity-50 "
      }
      ${
        index < currentStep && doneDays.includes(day.label) ? "bg-btn-main" : ""
      }
      ${
        (editingOffer && selectedDay === day.label) ||
        (doneDays.includes(day.label) && selectedDay)
          ? "border-main-orange border-2 bg-btn-main"
          : "border-[#fff]"
      }
      ${
        doneDays.includes(day.label) &&
        !(doneDays.includes(day.label) && selectedDay === day.label) &&
        "!border-transparent"
      }`}
    >
      <span className="text-sm font-semibold">{day.value}</span>
      {doneDays.includes(day.label) && (
        <img className="absolute -top-1 -right-1" src={done} alt="success" />
      )}
      {selectedDay === day.label && doneDays.includes(day.label) && (
        <img
          className="absolute -top-1 -right-1"
          src={editicon}
          alt="success"
        />
      )}
    </div>
  );
};

const OfferList = ({
  selectedDay,
  formData,
  editingOffer,
  deleteOffer,
  handleEditClick,
  isEditingDay,
  showList,
  setSelectedOffer,
  selectedOffer,
}) => {
  // Group offers by their start and end times
  const groupedOffers = formData.happyHours[selectedDay].reduce(
    (acc, offer) => {
      const key = `${offer.start}-${offer.end}`;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(offer);
      return acc;
    },
    {}
  );

  // Convert the groupedOffers object into an array of entries, sort by start time
  const sortedGroupedOffers = Object.entries(groupedOffers).sort(
    ([timeA, offersA], [timeB, offersB]) => {
      // Assuming start times are in a format like "03:00:PM"
      const [startA, endA] = timeA.split("-");
      const [startB, endB] = timeB.split("-");
      return startA.localeCompare(startB);
    }
  );

  return (
    <div
      className={`flex flex-col min-w-[257px] mx-auto my-5 md:my-20 border-2  px-5 py-4 !border-[#fff] rounded 
      ${!showList && " !border-transparent"}
  `}
    >
      {!isEditingDay ? (
        <h6
          className={`text-left font-semibold text-xl text-white ${
            formData.happyHours[selectedDay].length <= 0 ? "hidden" : ""
          }`}
        >
          Current {selectedDay} Offers
        </h6>
      ) : (
        <h6
          className={`text-left font-semibold text-xl text-white ${
            formData.happyHours[selectedDay].length <= 0 ? "hidden" : ""
          }`}
        >
          Select an offer to edit
        </h6>
      )}

      <ul className="my-5 grid grid-cols-1 2xl:grid-cols-2 gap-y-2 w-full">
        {/* {formData.happyHours[selectedDay].map((happyHour, index) => ( */}
        {/* {Object.entries(groupedOffers).map(([time, offers]) => ( */}
        {sortedGroupedOffers.map(([time, offers]) => (
          <div className="w-full md:max-w-fit">
            <HappyHourTime
              startTime={offers[0].start}
              endTime={offers[0].end}
              className="px-4"
            />
            {offers.map((happyHour, index) => {
              // Find the original index of the offer in the formData array
              const originalIndex = formData.happyHours[selectedDay].findIndex(
                (offer) =>
                  offer.offer === happyHour.offer &&
                  offer.start === happyHour.start &&
                  offer.end === happyHour.end
              );
              return (
                <li
                  key={index}
                  className={`flex justify-between space-y-4 items-center text-xl font-semibold cursor-pointer px-4 py-2 border rounded border-transparent transition-all delay-100 gap-x-4
          ${
            editingOffer?.offer === happyHour.offer
              ? "selectedHappyHourOffer"
              : ""
          }`}
                  onClick={() => {
                    setSelectedOffer(happyHour);
                    handleEditClick(happyHour);
                  }}
                >
                  <button
                    onClick={() => {
                      setSelectedOffer(happyHour);
                      handleEditClick(happyHour);
                    }}
                    className="cursor-pointer"
                  >
                    <img src={edit} alt="" />
                  </button>
                  {happyHour.offer}
                  <button
                    onClick={() => deleteOffer(originalIndex)} // Pass the original index here
                    className="cursor-pointer !mt-0"
                  >
                    <img src={trash} alt="" />
                  </button>
                </li>
              );
            })}
          </div>
        ))}
      </ul>
    </div>
  );
};
