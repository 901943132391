import React from "react";

const HighLightText = ({ color, classname, text }) => {
  return (
    <div
      style={{ backgroundColor: color }}
      className={`bg-[#D2973D] w-24 md:w-40 h-24 md:h-40 rounded-full flex justify-center items-center text-sm md:text-2xl font-semibold text-white font-Metropolis ${classname}`}
    >
      {text}
    </div>
  );
};

export default HighLightText;
