import { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import ImageContainer from "../components/ImageContainer";
import img from "../assest/images/city.png";
import Input from "../components/Input";
import arrowIcon from "../assest/icons/Cheveron.svg";
import {
  FetchRequestCity,
  fetchCityData,
  fetchStateData,
  requestCity,
} from "../api";
import Layout from "../components/Layout";

function RequestCity() {
  const [state, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [requestedCities, setRequestedCity] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [error, setError] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [formValue, setFormValue] = useState({
    state: "",
    Population: "",
    city: "",
  });

  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setFormValue({ ...formValue, [name]: value });
  };

  const formFields = [
    {
      name: "state",
      label: "What State is it located in?",
      placeholder: "Enter your state",
    },
    {
      name: "city",
      label: "What is the city",
      placeholder: "Enter your city name",
    },
    {
      name: "Population",
      label: "If you had to guess, how many people live there?",
      placeholder: "10,000",
    },
  ];
  const LoadStates = async () => {
    try {
      const data = await fetchStateData();
      const sortedOptions = [...data].sort((a, b) =>
        a.label.localeCompare(b.label)
      );
      setStates(sortedOptions);
    } catch (error) {
      console.error("Error fetching cities:", error);
      setError("Failed to load city data");
    }
  };
  const LoadCitiesData = async () => {
    try {
      const data = await fetchCityData();
      const transformedData = data.map((record) => ({
        ...record,
        Promotions: record.fields?.Promotions,
        Establishments: record.fields?.Establishments,
      }));

      const defaultCity = data.find(
        (record) => record.fields?.Name === "Boise"
      );

      if (defaultCity) {
        setSelectedCity({
          ...defaultCity,
          label: defaultCity.fields.Name,
          value: defaultCity.fields.Name,
          Establishments: defaultCity.fields.Establishments,
          Promotions: defaultCity.fields.Promotions,
        });
      }

      setCities(transformedData);
    } catch (error) {
      console.error("Error fetching cities:", error);
      setError("Failed to load city data");
    }
  };

  const LoadRequestedCities = async () => {
    try {
      const data = await FetchRequestCity();
      setRequestedCity(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    LoadStates();
    LoadCitiesData();
    LoadRequestedCities();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = {
        City: formValue.city,
        Population: formValue.Population,
        ["State"]: selectedState.id[0],
      };
      setSelectedCity(formValue.city);
      const response = await requestCity(formData);
      if (response.status === 200) {
        setError("");
        setShowSuccess(true);
        setFormValue({
          state: "",
          Population: "",
          city: "",
        });
        setSelectedState("");
      }
      // Handle successful submission, e.g., show a success message
    } catch (error) {
      // Handle errors, e.g., show an error message
      setError("Failed to submit your request");
    }
  };

  return (
    <>
      <Layout>
        <div className="bg-bg-time md:bg-darkgreen rounded-[15px] mx-[15px]">
          <div className="max-w-[1360px] mt-[41px] mb-5 py-10 md:py-[78px] mx-auto min-h-screen h-full px-4">
            <h1 className="text-[#fff] md:text-white text-center font-bold text-lg lg:text-[80px] leading-8 lg:leading-[72px] letter md:tracking-tighter header-mob uppercase md:normal-case">
              Request A City
            </h1>

            <div className="flex flex-col md:flex-row justify-center gap-5 w-full pt-5 md:pt-12">
              <div className="pt-3 md:pt-8">
                <p className="font-semibold text-base w-4/5 md:w-full italic md:text-2xl text-center mb-4 text-[#fff] max-w-[400px] mx-auto">
                  What City would you like to see Happy Hour Deals?
                </p>
                <form
                  action=""
                  onSubmit={handleSubmit}
                  className="min-w-full lg:min-w-[500px] py-5 flex gap-y-6 flex-col justify-center items-center  md:items-start text-white"
                >
                  {formFields.map((field, index) => {
                    return (
                      <div
                        className="flex gap-4 items-center box w-full"
                        key={field.name}
                      >
                        <div className="w-11 h-11 rounded-full bg-main-orange items-center justify-center text-bg-main font-semibold text-base mt-[30px] hidden lg:flex">
                          {index + 1}
                        </div>
                        <div className="flex flex-col gap-2 w-full">
                          <label htmlFor={field.name}>{field.label}</label>
                          {field.name === "state" ? (
                            <div className="relative w-full md:w-96">
                              <div
                                onClick={() => setDropdownOpen(!dropdownOpen)}
                                className={`cursor-pointer bg-white text-[#141414] md:text-white  rounded md:bg-transparent border-white border md:rounded-full h-14 w-full md:w-96 pl-5 flex items-center justify-between ${selectedState === "" ? " " : "active-input"
                                  }`}
                              >
                                {selectedState.label || "Select State"}
                                <div
                                  className={`w-[43px] h-[43px] me-[5px] bg-btn-main rounded-full flex items-center justify-center ${dropdownOpen ? "rotate-180" : "rotate-0"
                                    }`}
                                >
                                  <img src={arrowIcon} alt="arrow" />
                                </div>
                              </div>
                              {dropdownOpen && (
                                <ul className="h-60 customize_scroll overflow-y-scroll overflow-x-hidden absolute left-0 mt-2 w-full bg-darkgreen border rounded-2xl shadow-lg py-3 ">
                                  {state?.map((option, index) => (
                                    <li
                                      key={index}
                                      onClick={() => {
                                        setSelectedState({
                                          ["label"]: option.label,
                                          id: [option.id],
                                        });
                                        setDropdownOpen(false);
                                      }}
                                      className="cursor-pointer px-4 py-2 hover:text-main-orange"
                                    >
                                      {option.label}
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </div>
                          ) : (
                            <Input
                              placeholder={field.placeholder}
                              name={field.name}
                              className={`w-full md:w-96 focus:bg-bg-main !text-[#141414] md:!text-white !bg-white md:!bg-transparent !rounded ${formValue[field.name] === ""
                                  ? " "
                                  : "active-input"
                                }`}
                              handleInputChange={handleInputChange}
                              value={formValue[field.name]}
                            />
                          )}
                        </div>
                      </div>
                    );
                  })}

                  <button
                    type="submit"
                    className="px-6 h-[56px] bg-main-orange-50 text-[#141414] md:bg-transparent border rounded-full md:text-white text-lg  leading-10 font-bold w-full md:min-w-96 md:ms-14 flex items-center justify-center md:hover:bg-white hover:bg-main-orange-300 hover:text-bg-main transition-all"
                  >
                    Submit
                  </button>
                  <div
                    className={`text-white text-center w-full mx-auto ${!showSuccess ? "hidden" : ""
                      }`}
                  >
                    <h6 className="ml-4 font-bold text-lg">
                      Thank you for your request!
                    </h6>
                    <span className="ml-4 text-lg font-normal">
                      {" "}
                      We look forward having happy hours for{" "}
                      {`${selectedCity || "McCall"}`} Soon!
                    </span>
                  </div>
                </form>
              </div>

              <img src={img} alt="image" className="object-contain hidden md:block" />
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
export default RequestCity;
