import { currentTimeInMinutes, timeToMinutes } from ".";
import { generateDateTimeFromDateAndTime, isSameDay } from "../utils";


export const getCurrentDayIndex = () => {
    const date = new Date();
    const day = date.getDay();
    return day === 0 ? 6 : day - 1;
};

export const filterLiveEstablishments = (establishments) => {
    const currentTime = new Date();
  
    return establishments.filter((establishment) =>
      establishment.happyHours?.some(({ date, startTime, endTime }) => {
        const promoDate = new Date(date);
        const startDateTime = generateDateTimeFromDateAndTime(date, startTime);
        const endDateTime = generateDateTimeFromDateAndTime(date, endTime);
        return (
          currentTime >= startDateTime &&
          currentTime <= endDateTime &&
          isSameDay(promoDate, currentTime)
        );
      })
    );
  };

// export const filterEstablishmentsData = (groupedByCity, selectedFilters, searchQuery, isLive) => {
//     let filteredResults = groupedByCity;

//     // Apply filters
//     if (selectedFilters) {
//         filteredResults = filteredResults.filter((group) => {
//             return group.establishments.some((establishment) => {
//                 let matchesFilters = true;

//                 // Check each selected filter
//                 Object.keys(selectedFilters).forEach((filterKey) => {
//                     if (selectedFilters[filterKey].length > 0) {
//                         if (!selectedFilters[filterKey].includes(establishment.fields[filterKey])) {
//                             matchesFilters = false;
//                         }
//                     }
//                 });

//                 return matchesFilters;
//             });
//         });
//     }

//     // Apply search query
//     if (searchQuery) {
//         filteredResults = filteredResults.map((group) => {
//             return {
//                 ...group,
//                 establishments: group.establishments.filter((establishment) =>
//                     establishment.fields.Name.toLowerCase().includes(searchQuery.toLowerCase())
//                 ),
//             };
//         });
//     }

//     // Apply isLive filter
//     if (isLive) {
//         filteredResults = filteredResults.map((group) => {
//             return {
//                 ...group,
//                 establishments: group.establishments.filter((establishment) =>
//                     establishment.happyHours.some((happyHour) => {
//                         const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
//                         const date = new Date();
//                         const dayOfWeek = weekdays[date.getDay()];
//                         // Check if current time falls within this happy hour
//                         const isCurrentTimeWithinHappyHour =
//                             currentTimeInMinutes() >= timeToMinutes(happyHour.fields['Start Time']) &&
//                             currentTimeInMinutes() <= timeToMinutes(happyHour.fields['End Time']) &&
//                             happyHour.fields['Happy Hour Day'].includes(dayOfWeek)
//                         return isCurrentTimeWithinHappyHour
//                     })
//                 ),
//             };
//         });
//     }

//     return filteredResults;
// };

// Helper function to check if an establishment matches any of the selected filters
const matchesAnyFilter = (establishment, selectedFilters, searchQuery) => {
    const { category, Name, "street 1": street, Amenities } = establishment.fields;
    const { city } = establishment;
    const { crossStreets = [] } = establishment;
// console.log(establishment,'establishment');
    const matchesCityFilter = !selectedFilters.City.length || selectedFilters.City.includes(city);
    const matchesStreetsFilter = !selectedFilters.Streets.length || crossStreets.some(street => selectedFilters.Streets.includes(street));
    const matchesCategoryFilter = !selectedFilters.Category.length || selectedFilters.Category.some(cat => category.includes(cat));
    const matchesAmenitiesFilter = !selectedFilters.Amenities.length || (Amenities && selectedFilters.Amenities.some(amenity => Amenities.includes(amenity)));
    const matchesSearchQuery = !searchQuery || [Name, street, ...category].some(text => text.toLowerCase().includes(searchQuery.toLowerCase()));

    return matchesCityFilter && matchesCategoryFilter && matchesStreetsFilter && matchesAmenitiesFilter && matchesSearchQuery;
};

// Helper function to apply the isLive filter
const applyIsLiveFilter = (establishments, isLive) => {
    if (!isLive) return establishments;

    return establishments.filter(establishment =>
        establishment.happyHours.some(happyHour => {
            const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
            const date = new Date();
            const dayOfWeek = weekdays[date.getDay()];
            const isCurrentTimeWithinHappyHour =
                currentTimeInMinutes() >= timeToMinutes(happyHour?.fields['Start Time']) &&
                currentTimeInMinutes() <= timeToMinutes(happyHour?.fields['End Time']) &&
                happyHour?.fields['Happy Hour Day']?.includes(dayOfWeek);

            return isCurrentTimeWithinHappyHour;
        })
    );
};

// Main function to filter establishments
export const filterEstablishments = (groupedByCity, selectedFilters, searchQuery, isLive) => {
    return groupedByCity
        .map(cityGroup => ({
            ...cityGroup,
            establishments: cityGroup.establishments
                .filter(establishment => matchesAnyFilter(establishment, selectedFilters, searchQuery))
                .filter(establishment => applyIsLiveFilter([establishment], isLive)[0])
        }))
        .filter(cityGroup => cityGroup.establishments.length > 0);
};

export const isFiltersEmpty = (selectedFilters, searchQuery, isLive) => {
    return (
        selectedFilters?.City.length === 0 &&
        selectedFilters?.Category.length === 0 &&
        selectedFilters?.Amenities.length === 0 &&
        selectedFilters?.Streets.length === 0 &&
        !isLive &&
        searchQuery === ""
    );
};



