import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from "react";
import Establishment from "../Establishment";
import Time from "../Time";
import Date from "../Date";
import PromationSelector from "../PromationSelector";
import CarouselButton from "../CarouselButton";
import Button from "../Button";
import HighLightText from "../HighLightText";
import ImageContainer from "../ImageContainer";
import SkeletonUI from "../SkeletonUI";
import OfferDetailsOverlay, {
  BostedHappyHourOverlay,
  OfferDetailsModal,
} from "../OfferDetailsOverlay";
import alertIcon from "../../assest/icons/alert.svg";
import uber from "../../assest/icons/uber.svg";
import gift from "../../assest/icons/gift.svg";

const PromotionCarousel = ({
  promotionsData,
  establishment,
  loading,
  city,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselRef = useRef();
  const childRef = useRef();
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [offerDetailsVisible, setOfferDetailsVisible] = useState(false);
  const [boostedOfferVisible, setBoostedOfferVisible] = useState(false);

  const promotions = useMemo(() => {
    if (!establishment || !promotionsData) return [];
    const initEstablisment = {
      createdTime: "2024-01-11T18:41:38.000Z",
      fields: { Name: "", "street  1": "", zip: "" },
      id: "rec2iTTNgfBfem7nR",
      "profile-image": [{ id: "1", url: "" }],
      menu: [{ id: "" }],
    };
    return promotionsData.map((promo) => {
      const matchingEstablishment = establishment?.find((establishment) => {
        return (
          establishment.fields.Promotions &&
          establishment.fields.Promotions.length > 0 &&
          establishment.fields.Promotions[0] === promo.id
        );
      });
      return matchingEstablishment
        ? { ...promo, establishment: matchingEstablishment }
        : { ...promo, establishment: initEstablisment };
    });
  }, [promotionsData, establishment]);

  useEffect(() => {
    if (promotions.length && !lightboxOpen && !offerDetailsVisible) {
      const slideInterval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % promotions.length);
      }, 5000); // Change slide every 5 seconds

      return () => clearInterval(slideInterval);
    }
  }, [promotions.length, lightboxOpen, offerDetailsVisible]);

  useEffect(() => {
    if (carouselRef.current && promotions.length) {
      const activeItem = carouselRef.current.children[currentIndex];
      carouselRef.current.style.height = `${activeItem.offsetHeight}px !important`;
    }
  }, [currentIndex]);

  useEffect(() => {
    if (childRef.current && promotions.length) {
      const childHeight = childRef.current.offsetHeight;
      const parentElements =
        document.getElementsByClassName("promotion-carousel");
      Array.from(parentElements).forEach((parentElement) => {
        if (childHeight) {
          parentElement.style.height = `${childHeight}px`;
        }
      });
    }
  }, [currentIndex]);

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % promotions.length);
  };

  const handlePrevClick = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + promotions.length) % promotions.length
    );
  };

  const orderUber = useCallback(() => {
    return () => {
      const uberURL = "https://www.uber.com";
      window.open(uberURL, "_blank");
    };
  }, []);

  if (loading) {
    return <SkeletonUI />;
  }

  return (
    <div className="relative mb-10">
      <div
        className="promotion-carousel rounded-[15px] relative h-screen min-h-[864px] my-4 md:mb-10"
        ref={carouselRef}
      >
        {promotions.map((promotion, index) => {
          const {
            imageUrl,
            highLightColor,
            highLightText,
            establishment,
            description,
          } = promotion;
          const imgUrl = imageUrl.url;
          let happyHourLocation = {
            name: establishment?.fields.Name,
            zip: establishment?.fields.zip,
            street: establishment?.fields["street 1"],
            profile: establishment?.fields["profile-image"],
            state: establishment?.state?.fields?.State,
            city: city,
            menu: establishment?.fields.menu,
          };
          return (
            <div
              ref={childRef}
              key={index}
              className={`promotion-carousel-item ${
                index === currentIndex ? "active" : ""
              }`}
            >
              <div className="bg-darkgreen rounded-[15px] mx-[15px] xs:mx-2">
                <div className="max-w-full md:max-w-[1360px] md:mt-[41px] mb-5 py-5 md:py-[78px] mx-auto relative">
                  <Alert
                    setBoostedOfferVisible={setBoostedOfferVisible}
                    showBoostedOffer={boostedOfferVisible}
                  />

                  <div className="grid grid-cols-1 md:grid-cols-2  justify-between">
                    <div>
                      <div className="flex items-center justify-between gap-x-[5px] md:gap-[25px] w-full pl-4 pr-4 md:pl-6 md:pr-6">
                        <Date date={promotion.date} />
                        <Time
                          endTime={promotion.endTime}
                          startTime={promotion.startTime}
                          date={promotion.date}
                        />
                        <div className="flex gap-1 md:gap-5">
                          {promotions.map((_, index) => (
                            <PromationSelector
                              key={index}
                              show={currentIndex === index}
                              currentIndex={currentIndex}
                              totalSlides={promotions.length}
                              value={index + 1}
                              onClick={(value) =>
                                setCurrentIndex(Number(value) - 1)
                              }
                            />
                          ))}
                        </div>
                      </div>
                      <div className="relative my-4 md:my-8 z-30 h-fit md:min-h-64">
                        <p className="title">{promotion.title}</p>
                      </div>

                      <div className="hidden pl-12 md:flex flex-col gap-y-5">
                        <Establishment
                          happyHourLocation={happyHourLocation}
                          lightboxOpen={lightboxOpen}
                          setLightboxOpen={setLightboxOpen}
                        />
                        <PromoCards />
                        <div className="flex gap-x-4">
                          <Button
                            onClick={orderUber(happyHourLocation)}
                            className={
                              "hover:bg-white hover:text-bg-main bg-transparent border border-white w-[210px] "
                            }
                          >
                            Order Uber
                          </Button>
                          <div className="relative">
                            <Button
                              onClick={() =>
                                setOfferDetailsVisible(!offerDetailsVisible)
                              }
                              className={
                                "!bg-bg-main text-white transition-all hover:border-white border-2 border-transparent w-[210px] offer-details-button"
                              }
                            >
                              Offer Details
                              <OfferDetailsOverlay
                                show={offerDetailsVisible}
                                title={promotion.title}
                                desc={description}
                                endTime={promotion.endTime}
                                startTime={promotion.startTime}
                              />
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col md:flex-row w-full relative justify-start md:justify-center h-max">
                      <div className="flex w-fit relative h-full ml-7">
                        <ImageContainer imageUrl={imgUrl} />
                        <HighLightText
                          color={highLightColor}
                          text={highLightText}
                          classname="rounded-full z-20 -right-[40px] top-10 md:-right-[70px] absolute md:top-[135px] rotate-[20deg] text-center px-10"
                        />
                      </div>
                      <div className="absolute -bottom-5 left-24 md:relative md:bottom-0 md:left-0 z-50 ml-14 mb-10 flex flex-row md:flex-col gap-2.5 md:justify-end">
                        {promotions.length > 1 && (
                          <>
                            <CarouselButton
                              onClick={handlePrevClick}
                              className={"rotate-90"}
                            />
                            <CarouselButton
                              onClick={handleNextClick}
                              className={"-rotate-90"}
                            />
                          </>
                        )}
                      </div>
                    </div>
                    <div className="md:hidden pl-3 flex flex-col xs:gap-y-3 gap-y-1 md:gap-y-[73px]">
                      <Establishment
                        happyHourLocation={happyHourLocation}
                        lightboxOpen={lightboxOpen}
                        setLightboxOpen={setLightboxOpen}
                      />
                      <PromoCards />

                      <div className="flex gap-x-2 md:gap-x-4">
                        <Button
                          onClick={orderUber(happyHourLocation)}
                          className={
                            "hover:bg-white hover:text-bg-main bg-transparent border border-white md:w-[210px] w-[180px]"
                          }
                        >
                          Order Uber
                        </Button>
                        <div className="relative">
                          <Button
                            onClick={() =>
                              setOfferDetailsVisible(!offerDetailsVisible)
                            }
                            className={
                              "!bg-bg-main text-white transition-all hover:border-white border-2 border-transparent md:w-[210px] offer-details-button w-[180px]"
                            }
                          >
                            Offer Details
                            <OfferDetailsOverlay
                              show={offerDetailsVisible}
                              title={promotion.title}
                              desc={description}
                              endTime={promotion.endTime}
                              startTime={promotion.startTime}
                              date={promotion.date}
                            />
                            <OfferDetailsModal
                              show={offerDetailsVisible}
                              title={promotion.title}
                              desc={description}
                              endTime={promotion.endTime}
                              startTime={promotion.startTime}
                              date={promotion.date}
                              setOfferDetailsVisible={setOfferDetailsVisible}
                            />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default React.memo(PromotionCarousel);

const Alert = ({ showBoostedOffer, setBoostedOfferVisible }) => {
  return null;
  <div className="hidden md:flex items-center justify-end h-16  mb-4">
    <div className={`alert-box`}>
      <img className="alert-img " src={alertIcon} alt="alert" />
      <div className={`alert-content `}>
        <div>
          <h6 className="text-sm font-bold text-[#fff]">BOOSTED HAPPY HOUR</h6>
          <p className="text-xs text-[#fff] font-normal text-center underline mt-1">
            Learn More
          </p>
        </div>
        <img className="w-10 h-10" src={alertIcon} alt="" />
      </div>
    </div>
  </div>;
};

const PromoCards = () => {
  return (
    <div className="flex xs:p-2 gap-2 md:gap-4 justify-center items-center bg-gray-800 p-6 flex-col md:flex-row">
      <div className="bg-[#fff] bg-opacity-10 rounded-lg gap-x-3 gap-y-4 px-2 w-full md:w-64 flex items-center py-4 justify-center">
        <div className="flex items-center justify-center">
          <div className="w-12 h-12 flex items-center justify-center mr-3">
            <img src={uber} alt="uber" />
          </div>
          <div className="flex flex-col items-center text-center">
            <h3 className="text-sm font-semibold text-white">
              $7 FREE UBER CREDITS
            </h3>
            <p className="text-gray-600 text-xs text-white italic">
              When you visit. <strong>3</strong> available
            </p>
            <p className="text-main-orange-50 italic text-xs underline uppercase px-4 py-1">
              GET UBER CREDITS
            </p>
          </div>
        </div>
      </div>
      <div className="h-10 w-[2px] bg-[#fff] bg-opacity-30 rounded-lg hidden md:block" />
      <div className="bg-[#fff] bg-opacity-10 rounded-lg gap-x-3 gap-y-4 px-2 w-full md:w-64 flex items-center py-4 justify-center">
        <div className="flex items-center justify-center">
          <div className="w-12 h-12 flex items-center justify-center mr-3">
            <img src={gift} alt="gift" />
          </div>
          <div className="flex flex-col items-center text-center">
            <h3 className="text-xs font-normal text-white">
              WIN A <strong>$35 TO FORK</strong> <br /> GIFT CARD
            </h3>
            <p className="text-main-orange-50 italic text-xs underline uppercase px-4 py-1">
              ENTER
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
