import React from 'react'
import DashboardAvatar from './DashboardAvatar'
import rightarrow from '../../assest/icons/rightarrow.svg'
import leftarrow from '../../assest/icons/leftarrow.svg'


const Chart = ({ chatdata, bgColor }) => {
  const { imageUrl, title, pubName, month } = chatdata;
  return (
    <div className={`rounded-[23px] ${bgColor ? bgColor : 'bg-darkgreen'}`}>
      <div className="px-5 pt-5">
        <DashboardAvatar imageUrl={imageUrl} title={title} pubName={pubName} month={month} />
        <div className="flex justify-center items-center">
          <button className="mt-[-3rem] flex justify-center items-center w-[18px] h-[18px] rounded-full bg-bg-main text-white">
            <img src={rightarrow} alt="rightarrow" />
          </button>
          <div className="flex flex-col items-center mt-5 pb-9">
            <div className="flex justify-between w-[270px]">
              <div className="flex justify-start items-center flex-col">
                <p className="text-white mb-4">75</p>
                <div className=" h-[144px] w-3 flex bg-main-orange-50 mx-auto">
                  <div className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center "></div>
                </div>
                <p className="text-[10px] font-semibold font-Metropolis text-white">
                  Views
                </p>
              </div>

              <div className="flex justify-end items-center flex-col">
                <p className="text-white mb-4">27</p>
                <div className=" h-[78px] w-3 flex bg-main-orange-50 mx-auto">
                  <div className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center "></div>
                </div>
                <p className="text-[10px] font-semibold font-Metropolis text-white text-nowrap">
                  Direction Clicks
                </p>
              </div>

              <div className="flex justify-end items-center flex-col">
                <p className="text-white mb-4">14</p>
                <div className=" h-[55px] w-3 flex bg-main-orange-50 mx-auto">
                  <div className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center "></div>
                </div>
                <p className="text-[10px] font-semibold font-Metropolis text-white text-nowrap">
                  Menu Clicks
                </p>
              </div>
            </div>
          </div>
          <button className="mt-[-3rem] w-[18px] h-[18px] flex justify-center items-center bg-bg-main rounded-full text-white">
            <img src={leftarrow} alt="leftarrow" />
          </button>
          {/* right Arrow */}
        </div>
      </div>
    </div>
  )
}

export default Chart