import React from "react";
import live from "../assest/icons/live.svg";
import wine from "../assest/icons/wine.svg";
import music from "../assest/icons/music.svg";
import cocktails from "../assest/icons/coctail-glass.svg";
import outdoor from "../assest/icons/outdoor.svg";
import craft from "../assest/icons/craft.svg";
import yardGames from "../assest/icons/yard-games.svg"; // Add the icon for Yard Games
import tv from "../assest/icons/tv.svg"; // Add the icon for TV's
import greenCheck from "../assest/icons/greencheck.svg"; // Import the green check icon
import { useDispatch, useSelector } from "react-redux";
import { setIsLive } from "../redux/slices/EstablishmentsListingSlices";

const FeaturedFilters = ({ updateSelectedFilters, selectedFilters }) => {
  const dispatch = useDispatch();
  const isLive = useSelector((state) => state.happyHours.isLive);

  const features = [
    { id: 1, value: "Live Now!", displayText: "Live Now!", icon: live, alt: "Live Now!" },
    { id: 2, value: "Live Music", displayText: "Live Music", icon: music, alt: "Live Music" },
    { id: 5, value: "Outdoor Seating", displayText: "Outdoor", icon: outdoor, alt: "Outdoor Seating" }, // Changed display text to "Outdoor"
    { id: 6, value: "Cocktails", displayText: "Cocktails", icon: cocktails, alt: "Cocktails" },
    { id: 3, value: "Wine", displayText: "Wine", icon: wine, alt: "Wine" },
    { id: 4, value: "Craft Beer", displayText: "Craft Beer", icon: craft, alt: "Craft Beer" },
    { id: 7, value: "Yard Games", displayText: "Yard Games", icon: yardGames, alt: "Yard Games" }, // Added Yard Games
    { id: 8, value: "TV's", displayText: "TV's", icon: tv, alt: "TV's" }, // Added TV's
  ];

  const handleCheckboxChange = (filterKey, selectedValue, event) => {
    const isChecked = event.target.checked;

    if (selectedValue === "Live Now!") {
      dispatch(setIsLive(isChecked));
    } else {
      updateSelectedFilters(filterKey, selectedValue, isChecked);
    }
  };

  const isChecked = (value) => {
    return value === "Live Now!" ? isLive : selectedFilters["Amenities"]?.includes(value);
  };

  return (
    <div className="flex overflow-x-auto items-center mt-4 md:mt-0 gap-8 md:gap-8 mb-4 scrollbar-hide">
      {features.map((feature) => (
        <label
          key={feature.id}
          className={`relative flex flex-col items-center text-center cursor-pointer ${
            isChecked(feature.value) ? "opacity-100" : "opacity-100"
          }`}
        >
          <img
            src={feature.icon}
            alt={feature.alt}
            className="w-6 h-6 md:w-8 md:h-8 mb-4 transition-transform duration-200 hover:scale-110"
            style={{ filter: 'none', fill: '#000' }} 
          />
          {isChecked(feature.value) && (
            <img
              src={greenCheck}
              alt="Selected"
              className="absolute top-0 right-0 w-5 h-5"
            />
          )}
          <input
            type="checkbox"
            value={feature.value}
            className="hidden"
            checked={isChecked(feature.value)}
            onChange={(event) => handleCheckboxChange("Amenities", feature.value, event)}
          />
          <span className="text-xs text-white hover:text-white transition-colors duration-200 whitespace-nowrap">
            {feature.displayText}
          </span>
        </label>
      ))}
    </div>
  );
};

export default FeaturedFilters;
