import axios from "axios";
import { isEmpty } from "../utils";

import { FetchRequestCity, requestCity, fetchCityData } from "./city";
import { getStreets, getStreetsById, deleteStreet, createStreet } from "./streets";
import { getClaimListings, createClaimListing, deleteClaimListing, getStreeClaimListingById } from "./claimListing";
import { PostFeedback } from "./feedback";
import { fetchAllEstablishments, fetchEstablishmentsDataByCity, getEstablishments, createEstablishment, updateEstablishment, deleteEstablishment, getEstablishmentUser, CreateEstablishmentUser, deleteEstablishmentUser } from "./establishment";
import { fetchHappyHoursData, createHappyHour, deleteHappyHour, updateHappyHour, getHappyHours, fetchHappyHourById } from "./happyhours";
import { fetchPromotionsData } from "./promotions";
import { fetchStateData } from "./state";
import { UserSignUpToNewsletter } from "./users";
import { baseId, tableIds } from "../constant";

export const apiKey =
  "patL4TMwWflCfy7Gs.21fc6f543622c2d6a4ad40808a44f9528e650fae4f178346e770a603042fa736";


const apiEstablishmentsUrl = `https://api.airtable.com/v0/${baseId}/${tableIds.establishmentsTableId}`;
const apiPollUrl = `https://api.airtable.com/v0/${baseId}/${tableIds.pollTableId}`;


export const config = {
  headers: {
    Authorization: `Bearer ${apiKey}`,
    "Content-Type": "application/json",
  },
};

const fetchCategories = async () => {
  try {
    const response = await axios.get(apiEstablishmentsUrl, config);
    const establishments = response.data.records;
    const categories = establishments.map(
      (establishment) => establishment.fields.category
    );

    // Flatten the array of categories and remove duplicates
    const uniqueCategories = [...new Set(categories.flat())];

    return uniqueCategories;
  } catch (error) {
    console.error("Error fetching categories from Airtable:", error);
    throw error; // Re-throw the error to be handled by the calling function
  }
};

const fetchAmenities = async () => {
  try {
    const response = await axios.get(apiEstablishmentsUrl, config);
    const establishments = response.data.records;
    // Filter out records with undefined or null values in the Amentities field
    const amenities = establishments.filter(establishment => establishment.fields.Amenities !== undefined && establishment.fields.Amenities !== null)
      .map(
        (establishment) => establishment.fields.Amenities
      );

    // Flatten the array of amenities and remove duplicates
    const uniqueAmenities = [...new Set(amenities.flat())];

    return uniqueAmenities;
  } catch (error) {
    console.error("Error fetching categories from Airtable:", error);
    throw error; // Re-throw the error to be handled by the calling function
  }
};

const getUsers = async () => {
  try {
    const response = await axios.get(`https://api.airtable.com/v0/${baseId}/${tableIds.usersTableId}`, config);
    return response.data.records;
  } catch (error) {
    console.error('Error fetching Happy Hours:', error);
    throw error;
  }
};
export const deleteUsers = async (id) => {
  try {
    await axios.delete(`https://api.airtable.com/v0/${baseId}/${tableIds.usersTableId}/${id}`, config);
    return { success: true };
  } catch (error) {
    console.error('Error deleting Happy Hour:', error);
    throw error;
  }
};
export const getUserRoles = async () => {
  try {
    const response = await axios.get(`https://api.airtable.com/v0/meta/bases/${baseId}/tables`, config);
    return response.data
  } catch (error) {
    console.error("Error verifying user:", error);
    throw error;
  }
};

export const getRatingByName = async (name) => {
  try {
    const response = await axios.get(
      `https://api.airtable.com/v0/${baseId}/${tableIds.ratingTableId}`,
      {
        ...config,
        params: {
          filterByFormula: `Establishment="${name}"`,
        },
      }
    );
    const records = response.data.records;
    if (records.length > 0) {
      return records[0].fields;
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error fetching rating:', error);
    throw error;
  }
};

export const updateHappyHourPoll = async (pollData) => {
  try {
    const payload = {
      fields: pollData,
      typecast: true,
    };
    const response = await axios.patch(apiPollUrl, payload, config);
    return response.data; // Return the ID of the created establishment
  } catch (error) {
    console.error("Error creating establishment:", error);
    throw error; // Re-throw the error to be handled by the calling function
  }
};

export {
  FetchRequestCity,
  requestCity,
  fetchCityData,

  PostFeedback,
  fetchStateData,
  fetchPromotionsData,

  UserSignUpToNewsletter,
  fetchCategories,
  fetchAmenities,

  CreateEstablishmentUser,
  getEstablishmentUser,
  deleteEstablishmentUser,

  fetchAllEstablishments,
  fetchEstablishmentsDataByCity,
  createEstablishment,
  deleteEstablishment,
  getEstablishments,
  updateEstablishment,

  fetchHappyHoursData,
  fetchHappyHourById,
  createHappyHour,
  updateHappyHour,
  deleteHappyHour,
  getHappyHours,

  getStreets,
  createStreet,
  deleteStreet,
  getStreetsById,

  getClaimListings,
  createClaimListing,
  deleteClaimListing,
  getUsers,
};
