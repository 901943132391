import React, { useEffect, useState } from "react";
import TimePicker from "./Timepicker";

function AddHappyHourTime({ newOffer, setNewOffer, isEdit }) {
  const [startTime, setStartTime] = useState({
    hour: "03",
    minute: "00",
    amPm: "PM",
  });
  const [endTime, setEndTime] = useState({
    hour: "05",
    minute: "30",
    amPm: "PM",
  });

  // Update local state based on newOffer prop
  useEffect(() => {
    const [startHour, startMinute, startAmPm] = newOffer?.start?.split(/[: ]/);
    const [endHour, endMinute, endAmPm] = newOffer?.end?.split(/[: ]/);

    setStartTime({
      hour: startHour,
      minute: startMinute,
      amPm: startAmPm,
    });

    setEndTime({
      hour: endHour,
      minute: endMinute,
      amPm: endAmPm,
    });
  }, [newOffer]);

  const updateHappyHourTimes = () => {
    setNewOffer((prev) => ({
      ...prev,
      start: `${startTime.hour}:${startTime.minute} ${startTime.amPm}`,
      end: `${endTime.hour}:${endTime.minute} ${endTime.amPm}`,
    }));
  };

  return (
    <div className="flex flex-col space-y-4 w-full">
      <p className="text-2xl font-semibold">
        What time does the Happy Hour Run?
      </p>
      <div className="grid grid-cols-2 space-x-6 items-start w-full">
        <TimePicker
          label="START"
          setTime={setStartTime}
          time={startTime}
          updateHappyHourTimes={updateHappyHourTimes}
          isEdit={isEdit}
        />
        <TimePicker
          label="END"
          setTime={setEndTime}
          time={endTime}
          updateHappyHourTimes={updateHappyHourTimes}
          isEdit={isEdit}
        />
      </div>
    </div>
  );
}

export default AddHappyHourTime;
