import React, { useEffect, useState } from 'react';
import { DashboardLayout } from '../../components/Layout';
import { LoadingButton } from '../../components/Button';
import Navbar from '../../components/dashboardUIs/Navbar';
import logo from '../../assest/Logo.svg';
import editicon from '../../assest/icons/editicon.svg';
import { useSelector } from 'react-redux';
import { fetchEstablishmentUser, updateEstablishmentUser } from '../../api/users';
import { getUserRoles } from '../../api';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { storage } from '../../firebase'; // Import your firebase storage config

const initialState = {
    name: '',
    email: '',
    role: '',
    avatar: '', // Add avatar to the initial state
};

const formFields = [
    {
        name: "name",
        placeholder: "Your Name",
        type: 'text',
    },
    {
        name: "email",
        placeholder: "Your Email",
        type: 'text',
    },
    {
        name: "role",
        placeholder: "Role In Company",
        type: 'text',
    },
];

function Settings() {
    const [formValue, setFormValue] = useState(initialState);
    const [roles, setRoles] = useState([]);
    const [error, setError] = useState('');
    const [success, setSuccessMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [showGif, setShowGif] = useState(false);
    const [data, setData] = useState(null)
    const establishment = useSelector((state) => state.establishments.userEstablishment);
    const { list: cities } = useSelector((state) => state.cities);

    const handleInputChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setFormValue({ ...formValue, [name]: value });
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (!file) return;
        setShowGif(true);
        setUploadProgress(0);

        const storageRef = ref(storage, `avatars/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setUploadProgress(progress);
            },
            (error) => {
                console.error("Upload failed:", error);
                setShowGif(false);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                    setFormValue((prevData) => ({
                        ...prevData,
                        avatar: url,
                    }));
                    setShowGif(false);
                });
            }
        );
    };

    const handleFetchEstablishmentUser = async (establishment) => {
        try {
            const userId = establishment.fields.Users[0];
            const data = await fetchEstablishmentUser(userId);
            const { Email, Name, Role } = data.fields;
            setFormValue({ name: Name, email: Email, role: Role });
        } catch (error) {
            console.error("Error fetching establishment:", error);
        }
    };

    const validateForm = (formValue) => {
        const { name, email, role } = formValue;
        if (!name || !email || !role) {
            setError('Please fill in all fields.');
            return false;
        }
        return true;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!validateForm(formValue)) {
            return;
        }
        setIsLoading(true);
        try {
            const formData = {
                Name: formValue.name,
                Email: formValue.email,
                Role: formValue.role,
                Avatar: formValue.avatar, // Include avatar in the formData
            };
            await updateEstablishmentUser(establishment.fields.Users[0], formData);
            setSuccessMessage('Update successful');
        } catch (error) {
            console.error("Error updating establishment:", error);
            setError('Update failed');
        } finally {
            setIsLoading(false);
        }
    };

    const LoadUserRoles = async () => {
        try {
            const data = await getUserRoles();
            const establishmentUsers = data.tables.find(table => table.name === "EstablishmentUsers");
            if (establishmentUsers) {
                const roleField = establishmentUsers.fields.find(field => field.name === "Role");
                if (roleField && roleField.options && roleField.options.choices) {
                    const roleOptions = roleField.options.choices.map(choice => ({
                        value: choice.name,
                        label: choice.name,
                    }));
                    setRoles(roleOptions);
                } else {
                    console.error("Role field or its options not found in the response.");
                }
            } else {
                console.error("EstablishmentUsers not found in the response.");
            }
        } catch (error) {
            console.error("Error fetching user roles:", error);
        }
    };

    useEffect(() => {
        if (establishment) {
            LoadUserRoles();
            const extractDetails = {
                name: establishment.fields.Name,
                category: establishment.fields.category,
                coverphoto: establishment.fields['cover-image'][0]?.url || '',
                avatar: establishment.fields['profile-image'][0]?.url || '',
                website: establishment.fields.Website || '',
                address: establishment.fields['street 1'],
                phone: establishment.fields['Phone Number'] || '',
                crossstreet: establishment.crossStreets,
                happyHours: establishment.happyHours,
                menu: establishment.fields.menu[0]?.url || '',
                zip: establishment.fields.zip || '',
                state: establishment.fields['State (from state)'][0] || '',
                city: establishment.fields.city,
            };
            setData(extractDetails);
            handleFetchEstablishmentUser(establishment);
        }
    }, [establishment]);


    return (
        <DashboardLayout>
            <div className="container mx-auto px-[50px] pt-[33px] mb-2 h-screen">
                <Navbar cities={cities} establishmentDetail={data} title="Settings" />
                <form onSubmit={handleSubmit}>
                    <div className="flex flex-col pt-8 pb-8 gap-[20px] max-w-[80%]">
                        <UploadAvatar handleFileUpload={handleFileUpload} formValue={formValue} showGif={showGif} uploadProgress={uploadProgress} />
                        {formFields.map((field) => (
                            <div className="flex flex-col" key={field.name}>
                                {field.name !== 'role' && (
                                    <>
                                        <label className='text-white text-base font-normal leading-6 mb-2' htmlFor={field.name}>{field.placeholder}</label>
                                        <input
                                            type={field.type}
                                            name={field.name}
                                            value={formValue[field.name]}
                                            placeholder={field.placeholder}
                                            onChange={handleInputChange}
                                            className={`bg-white border-white border rounded-lg h-14 w-full max-w-60 pl-5`}
                                        />
                                    </>
                                )}
                                {field.name === 'role' && (
                                    <div className='max-w-60'>
                                        <Dropdown
                                            options={roles}
                                            setSelectedValue={(value) => handleInputChange({ target: { name: "role", value } })}
                                            selectedValue={formValue.role}
                                            placeholder={"Select your role"}
                                        />
                                    </div>
                                )}
                            </div>
                        ))}
                        <LoadingButton onClick={handleSubmit} className={'!rounded-md !max-w-60 !ml-0'} isLoading={isLoading}>
                            SAVE CHANGES
                        </LoadingButton>
                    </div>
                </form>
            </div>
        </DashboardLayout>
    );
}

export default Settings;

export const UploadAvatar = ({ handleFileUpload, formValue, showGif, uploadProgress }) => {
    return (
        <div className="flex gap-y-4 max-w-60">
            <div className="flex gap-x-4 justify-center items-center gap-y-5 relative">
                <div className="rounded-2xl flex items-center justify-center bg-darkgreen w-24 h-24 relative">
                    <img className={`${formValue.avatar ? 'w-full object-cover rounded-lg h-full' : 'w-12 object-fit'}`} src={formValue.avatar || logo} alt="avatar" />
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleFileUpload}
                        className="opacity-0 absolute cursor-pointer z-50"
                        style={{ width: "100%", height: "100%" }}
                    />
                    {showGif && (
                        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                            <div className="bg-white p-2 rounded-full">
                                <svg className="animate-spin h-5 w-5 text-gray-800" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            </div>
                        </div>
                    )}
                    <img className='w-8 object-fill absolute bottom-1 right-1 shadow' src={editicon} alt="edit" />
                </div>
                <p className='text-white font-semibold text-base lg:text-xl'>Avatar</p>
            </div>
        </div>
    );
};

const Dropdown = ({
    options,
    selectedValue,
    setSelectedValue,
    placeholder,
}) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    return (
        <div className="relative border rounded-lg w-full">
            <div
                onClick={() => setDropdownOpen(!dropdownOpen)}
                className={`cursor-pointer bg-white border-white border h-14 w-full pl-5 flex items-center justify-between text-bg-main rounded-lg`}
            >
                {selectedValue || placeholder}
                <div className={`w-[43px] h-[43px] me-[5px] flex items-center justify-center ${dropdownOpen ? "rotate-180" : "rotate-0"}`}>
                    <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect y="2.13477" width="3" height="8" transform="rotate(-45 0 2.13477)" fill="#000" />
                        <rect x="6.40088" y="7.07104" width="3" height="7" transform="rotate(-135 6.40088 7.07104)" fill="#000" />
                    </svg>
                </div>
            </div>
            {dropdownOpen && (
                <ul className="h-fit customize_scroll overflow-y-scroll overflow-x-hidden absolute left-0 mt-2 w-full bg-white border shadow-lg py-3 z-50 text-bg-main">
                    {options.length > 0 ? options.map((option, index) => (
                        <li
                            key={index}
                            onClick={() => {
                                setSelectedValue(option.value);
                                setDropdownOpen(false);
                            }}
                            className="cursor-pointer px-4 py-2 hover:text-main-orange"
                        >
                            {option.label}
                        </li>
                    )) : (
                        <li className="cursor-pointer px-4 py-2 hover:text-main-orange">Loading...</li>
                    )}
                </ul>
            )}
        </div>
    );
};
