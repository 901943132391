import axios from 'axios'
import { config, fetchEstablishmentsDataByCity } from "..";
import { baseId, tableIds } from "../../constant";

export const apiHappyHoursUrl = `https://api.airtable.com/v0/${baseId}/${tableIds.happyHoursId}`;

export const fetchHappyHoursData = async () => {
    try {
        const response = await axios.get(apiHappyHoursUrl, config);
        const happyHours = response.data.records;

        // Extract establishment IDs from happy hours records
        const establishmentIds = happyHours.flatMap(
            (hh) => hh.fields.Establishments
        );

        // Fetch establishment data using the extracted IDs
        const establishmentData = await fetchEstablishmentsDataByCity(
            establishmentIds
        );

        // Combine happy hours and establishment data
        const combinedData = happyHours.map((hh) => ({
            ...hh,
            establishments: establishmentData.find(
                (est) => est.id === hh.fields.Establishments[0]
            ),
        }));

        return combinedData;
    } catch (error) {
        console.error("Error fetching happy hours data from Airtable:", error);
    }
};

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const fetchHappyHourById = async (id, retries = 5, backoff = 300) => {
    try {
        const response = await axios.get(`${apiHappyHoursUrl}/${id}`, config);
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 429 && retries > 0) {
            console.warn(`Retrying fetchHappyHourById after ${backoff} ms...`);
            await delay(backoff);
            return fetchHappyHourById(id, retries - 1, backoff * 2);
        } else {
            console.error(`Error fetching happy hours by ID ${id}:`, error);
            throw error;
        }
    }
};
// Function to fetch happy hours in batch and filter by provided IDs
export const fetchHappyHours = async (ids) => {
    try {
        // First, fetch all happy hours
        const allHappyHoursResponse = await axios.get(apiHappyHoursUrl, config);
        const allHappyHours = allHappyHoursResponse.data.records;

        // Filter the fetched happy hours based on the provided IDs
        const filteredHappyHours = allHappyHours.filter(hh => ids.includes(hh.id));

        return filteredHappyHours;
    } catch (error) {
        console.error("Error fetching happy hours:", error);
    }
};

// HAPPY HOURS

// GET all Happy Hours
export const getHappyHours = async () => {
    try {
        const response = await axios.get(apiHappyHoursUrl, config);
        return response.data.records;
    } catch (error) {
        console.error('Error fetching Happy Hours:', error);
        throw error;
    }
};

// POST a new Happy Hour
export const createHappyHour = async (happyHour) => {
    try {
        const response = await axios.post(apiHappyHoursUrl, { fields: happyHour }, config);
        return response.data;
    } catch (error) {
        console.error('Error creating Happy Hour:', error);
        throw error;
    }
};

// PUT (update) a Happy Hour
export const updateHappyHour = async (id, updatedHappyHour) => {
    try {
        const response = await axios.patch(`${apiHappyHoursUrl}/${id}`, { fields: updatedHappyHour }, config);
        return response.data;
    } catch (error) {
        console.error('Error updating Happy Hour:', error);
        throw error;
    }
};

// DELETE a Happy Hour
export const deleteHappyHour = async (id) => {
    try {
        await axios.delete(`${apiHappyHoursUrl}/${id}`, config);
        return { success: true };
    } catch (error) {
        console.error('Error deleting Happy Hour:', error);
        throw error;
    }
};
