import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setCloseAd,
  setLoading,
  setGroupedByCity,
  setEstablishmentFiltered,
  setCategories,
  setSearchQuery,
  setIsFilterOpen,
  resetFilters,
  updateSelectedFilters,
  setIsLive,
} from "../../redux/slices/EstablishmentsListingSlices.js";
import {
  filterEstablishments,
  isFiltersEmpty,
} from "../../utils/happyHoursUtils.js";
import HappyHoursCard from "../HappyHoursCard.jsx";
import AdContainer from "../AdContainer.jsx";
import FilterContainer from "../FilterContainer.jsx";
import HappyHourOffer from "../HappyHourOffer.jsx";
import FilterCard from "../FilterCard.jsx";
import { CardSkeleton } from "../SkeletonUI.jsx";
import useFixedOnScroll, { useIsMobile } from "../../hooks/index.js";
import filterIcon from "../../assest/icons/filter.svg";
import liveIcon from "../../assest/icons/live.svg";
import image1 from "../../assest/images/happyCard1.png";
import SearchInput from "../search.jsx";
import FeaturedFilters from "../FeaturedFilters.jsx"; // Import the new component

const HappyHoursListing = ({
  cardLoading,
  data,
  cities,
  selectedCity,
  hasPromos,
}) => {
  const dispatch = useDispatch();
  const {
    closeAd,
    loading,
    groupedByCity,
    establishmentsFiltered,
    categories,
    CrossStreetData,
    searchQuery,
    days,
    isFilterOpen,
    selectedFilters,
    isLive,
  } = useSelector((state) => state.happyHours);

  const filterContainerRef = useRef();
  const isScrollInView = useFixedOnScroll(filterContainerRef);
  const isMobileView = useIsMobile();

  const processAndGroupData = (data, CrossStreetData, selectedCity) => {
    dispatch(setLoading(true));

    const processedData = data
      .map((establishment) => {
        const crossStreets = establishment?.fields?.crossStreets
          ?.map(
            (id) => CrossStreetData.find((cs) => cs.id === id)?.fields?.Name
          )
          .filter(Boolean);

        return { ...establishment, crossStreets };
      })
      .filter(Boolean);

    const groupedByCity = Object.entries(
      processedData.reduce((acc, est) => {
        acc[est.city] = acc[est.city] || [];
        acc[est.city].push(est);
        return acc;
      }, {})
    ).map(([city, establishments]) => ({ city, establishments }));

    const selectedCityGroup = groupedByCity.find(
      (group) => group.city === selectedCity
    );
    if (selectedCityGroup) {
      groupedByCity.splice(groupedByCity.indexOf(selectedCityGroup), 1);
      groupedByCity.unshift(selectedCityGroup);
    }

    const uniqueCategories = Array.from(
      new Set(processedData.flatMap((est) => est.fields.category))
    );
    const formattedCategories = uniqueCategories.map((category) => ({
      value: category,
    }));

    dispatch(setCategories(formattedCategories));
    dispatch(setGroupedByCity(groupedByCity));
    dispatch(setLoading(false));
  };

  const handleAdClose = () => dispatch(setCloseAd(false));
  const handleHourFilter = () => dispatch(setIsFilterOpen(!isFilterOpen));
  const handleSearch = (e) => dispatch(setSearchQuery(e.target.value));
  const handleLiveFilter = (isLive) => dispatch(setIsLive(isLive));

  const selectedCityGroup = groupedByCity.find(
    (group) => group.city === selectedCity
  );

  const getTotalEstablishments = () =>
    establishmentsFiltered?.reduce(
      (total, cityGroup) => total + cityGroup.establishments.length,
      0
    );

  const checkedCount =
    Object.values(selectedFilters).reduce(
      (count, filterValues) => count + filterValues.length,
      0
    ) + (isLive ? 1 : 0);

  const itemsFound = isFiltersEmpty(selectedFilters, searchQuery, isLive)
    ? selectedCityGroup?.establishments?.length
    : getTotalEstablishments();

  useEffect(() => {
    dispatch(setLoading(cardLoading));
    if (groupedByCity.length === 0) {
      dispatch(setLoading(true));
    }
  }, [cardLoading, groupedByCity]);

  useEffect(() => {
    if (data && CrossStreetData) {
      processAndGroupData(data, CrossStreetData, selectedCity);
    }
  }, [data, CrossStreetData, selectedCity]);

  useEffect(() => {
    let filteredResults = filterEstablishments(
      groupedByCity,
      selectedFilters,
      searchQuery,
      isLive
    );
    const selectedCityGroup = filteredResults.find(
      (group) => group.city === selectedCity
    );
    if (selectedCityGroup) {
      filteredResults = filteredResults.filter(
        (group) => group.city !== selectedCity
      );
      filteredResults.unshift(selectedCityGroup);
    }
    dispatch(setEstablishmentFiltered(filteredResults));
  }, [selectedFilters, selectedCity, searchQuery, isLive, dispatch]);

  return (
    <div
      ref={filterContainerRef}
      className="text-white md:pt-8 container w-full relative hoursListContainer"
    >
      <div className="flex justify-between px-5 md:px-10 items-center flex-col md:flex-row my-4">
        <div className="flex items-center gap-4 w-full md:w-fit">
          <h2 className="text-xl lg:text-3xl font-semibold">
            {isLive ? "Find Live Happy Hours Near You" : "Find Your Perfect Happy Hour"}
          </h2>
        </div>
        {closeAd && (
          <AdContainer handleAdClose={handleAdClose} cities={cities} />
        )}
      </div>
      <div
        id="hours-listing"
        className="flex px-4 md:pl-10 gap-10 mt-8 md:mt-5 flex-col md:flex-row"
      >
        <div 
          className={`transition-all delay-75 flex gap-2 z-40 flex-col ${
            isScrollInView && !isMobileView && "fixed top-48"
          } ${isMobileView ? "hidden" : ""} ${!hasPromos && "!top-0"}`}
        >
          <HappyHoursLive
            handleLiveFilter={handleLiveFilter}
            isLive={isLive}
            className="!w-[200px] flex-col justify-center items-center !rounded-2xl"
          />
          <FilterContainer
            resetFilters={() => {
              dispatch(resetFilters());
              dispatch(setIsLive(false));
            }}
            setHandleFilter={handleHourFilter}
            selectedFilterCount={checkedCount}
          />
          <FilterCard
            isFilterOpen={isFilterOpen}
            closeFilter={() => dispatch(setIsFilterOpen(false))}
            cities={cities}
            categories={categories}
            streets={CrossStreetData}
            updateSelectedFilters={(filterKey, selectedValue, isChecked) =>
              dispatch(
                updateSelectedFilters({ filterKey, selectedValue, isChecked })
              )
            }
            selectedFilters={selectedFilters}
            filteredItems={getTotalEstablishments()}
            resetFilters={() => {
              dispatch(resetFilters());
              dispatch(setIsLive(false));
            }}
          />
        </div>
        <div className="md:w-[78%] w-full mx-auto mr-12">
          <div className="flex mb-6 items-center justify-between gap-x-3">
            <SearchInput value={searchQuery} onChange={handleSearch} />
            <HappyHourOffer
  title={
    isFiltersEmpty(selectedFilters, searchQuery, isLive) ? (
      <span className="text-[#e8e8e8]">
        {`${itemsFound || 0} Happy Hours for `}
        <span style={{ color: '#FBBC05' }}>{selectedCity}</span> {/* Styled selected city */}
      </span>
    ) : (
      <span className={itemsFound === 0 ? 'text-[#FBBC05]' : 'text-[#fff]'}>
        {`${itemsFound} Happy Hour Match Found`}
      </span>
    )
  }
/>
            <button
              onClick={() => dispatch(setIsFilterOpen(true))}
              className="border rounded-full h-10 w-10 flex justify-center items-center p-1 md:hidden"
            >
              {checkedCount === 0 ? (
                <img src={filterIcon} alt="filter" />
              ) : (
                <span>{checkedCount}</span>
              )}
            </button>
          </div>
          <div className="justify-start">
          <FeaturedFilters
              updateSelectedFilters={(filterKey, selectedValue, isChecked) =>
                dispatch(
                  updateSelectedFilters({ filterKey, selectedValue, isChecked })
                )
              }
              selectedFilters={selectedFilters}
            />
          </div>

          {/* Ensure that establishmentsFiltered is defined and correctly passed */}
   

          <HappyHoursLive
            handleLiveFilter={handleLiveFilter}
            isLive={isLive}
            className="md:hidden !mb-10"
          />
          <HappyHourLists
            loading={loading}
            groupedByCity={groupedByCity}
            establishmentsFiltered={establishmentsFiltered}
            days={days}
            selectedCity={selectedCity}
            CrossStreetData={CrossStreetData}
          />
        </div>
      </div>
    </div>
  );
};

export default HappyHoursListing;


const HappyHourLists = ({
  loading,
  establishmentsFiltered,
  groupedByCity,
  days,
  selectedCity,
  CrossStreetData,
}) => {
  return (
    <div className="w-full">
      {!loading ? (
        establishmentsFiltered?.length > 0 ? (
          establishmentsFiltered?.map((cityGroup, index) => (
            <EstablishmentList
              key={index}
              establishments={cityGroup.establishments}
              item={cityGroup}
              days={days}
              selectedCity={selectedCity}
              CrossStreetData={CrossStreetData}
            />
          ))
        ) : (
          groupedByCity.map((cityGroup, index) => (
            <EstablishmentList
              key={index}
              establishments={cityGroup.establishments}
              item={cityGroup}
              days={days}
              selectedCity={selectedCity}
              CrossStreetData={CrossStreetData}
            />
          ))
        )
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 pb-10">
          {[1, 2, 3, 4].map((index) => (
            <CardSkeleton key={index} />
          ))}
        </div>
      )}
    </div>
  );
};

const EstablishmentList = ({
  establishments,
  selectedCity,
  item,
  days,
  CrossStreetData,
}) => {
  return (
    <div className="py-2">
      {selectedCity !== item.city && (
        <div
          className={`mb-10 md:mb-20 ${
            establishments.length === 0 ? "hidden" : ""
          }`}
        >
          <div className="border-t border-white pt-8 opacity-80" />
          <h1 className="text-2xl font-bold text-left">
            {item.city} Happy Hours{" "}
            <span className="text-lg font-normal opacity-50">
              ( {establishments.length} Happy Hours for {item.city} )
            </span>
          </h1>
        </div>
      )}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10 gap-y-14 pb-10">
        {establishments.map((establishment, index) => (
          <HappyHoursCard
            id={establishment.id}
            key={index}
            imageUrl={establishment.fields["cover-image"][0]?.url || image1}
            name={establishment.fields.Name}
            time={"3:00 pm - 6:00 pm"}
            days={days}
            daysBg={"bg-[#497986]"}
            category={establishment.fields.category}
            street={establishment.fields["street 1"]}
            zip={establishment.fields.zip}
            state={establishment.fields["State (from state)"]}
            topOffer={establishment.fields["profilePromoText"]}
            offerColor={establishment.fields["profilePromoColor"] || "#D2463D"}
            happyHours={establishment.happyHours}
            city={establishment.city}
            menu={establishment.fields.menu[0]}
            crossStreet={establishment.crossStreets}
            CrossStreetData={CrossStreetData}
            carouselImages={[
              establishment.fields["cover-image"]?.[0]?.url,
              establishment.fields?.AdditionalImage2?.[0]?.url,
              establishment.fields?.AdditionalImage1?.[0]?.url,
            ].filter(Boolean)}
            phoneNumber={establishment.fields["Phone Number"]}
          />
        ))}
      </div>
    </div>
  );
};

const HappyHoursLive = ({ className, handleLiveFilter, isLive }) => {
  return (
    <div
      className={`hidden md:hidden bg-[#D9D9D91A] border border-white flex w-full rounded-lg my-4 justify-between py-4 px-2
    ${className}`}
    >
      <div className="hidden md:flex gap-2 w-full justify-end gap-x-4 mb-2">
        <img className="w-10 h-10 md:w-12 md:h-12" src={liveIcon} alt="live" />
        <SliderCheckbox
          handleLiveFilter={handleLiveFilter}
          isLive={isLive}
          className="hidden md:flex"
        />
      </div>
      <div className="flex flex-col items-center md:hidden mb-0">
        <img
          className="w-10 h-10 ml-2 mt-1 md:ml-0 md:mt-0 md:w-12 md:h-12"
          src={liveIcon}
          alt="live"
        />
      </div>
      <div className="text-left md:text-center">
        <h6 className="font-semibold text-base">Happy Hours Now!</h6>
        <span className="text-xs font-thin">Find Your Perfect Spot Now!</span>
      </div>
      <SliderCheckbox
        handleLiveFilter={handleLiveFilter}
        isLive={isLive}
        className="md:hidden"
      />
    </div>
  );
};

const SliderCheckbox = ({ className, handleLiveFilter, isLive }) => {
  const toggleCheckbox = () => {
    handleLiveFilter(!isLive);
  };

  return (
    <div className={`flex items-center ${className} cursor-pointer`}>
      <div
        className={`relative w-16 h-9 border rounded-full transition duration-300 ${
          !isLive ? "bg-transparent border" : ""
        }`}
        onClick={toggleCheckbox}
      >
        <div
          className={`absolute w-7 h-7 top-1/2 -translate-y-1/2 rounded-full shadow-md transform transition duration-300 ${
            isLive ? "translate-x-7 bg-white" : "translate-x-0.5 bg-white z-1"
          }`}
        >
          <svg
            className={
              !isLive
                ? "hidden"
                : "absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-[15px] h-[15px] "
            }
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.54622 8L2.50222 7.9175C1.83004 6.65746 0.0438096 3.98346 0.0257668 3.9566L0 3.91809L0.608558 3.25639L2.53492 4.73633C3.74781 3.00468 4.87936 1.81531 5.61746 1.12076C6.42488 0.360994 6.95046 0.011223 6.95576 0.00787125L6.96771 0H8L7.90139 0.0966169C5.36541 2.5818 2.61667 7.86381 2.58929 7.91688L2.54622 8Z"
              fill={"#000"}
            />
          </svg>
        </div>
      </div>
    </div>
  );
};
