// StepperIndicator.jsx
import React, { useState } from "react";
import checkIcon from "../../assest/icons/check.svg";

const StepperIndicator = ({ steps, currentStep, setCurrentStep, children }) => {
  return (
    <>
      <div className="flex justify-between items-center mt-10 mb-20 w-full">
        <div
          className={`flex ${
            steps.length < 7 ? "space-x-8" : "space-x-6"
          } lg:space-x-12 w-fit md:w-fit mx-auto`}
        >
          {steps.map((step) => (
            <div className="flex items-center relative" key={step}>
              <div
                onClick={() => setCurrentStep(step)}
                className={`cursor-pointer w-6 h-6 rounded-full flex items-center justify-center ${
                  currentStep >= step
                    ? "bg-main-orange text-white"
                    : "bg-bg-main text-gray-500"
                }`}
              >
                {step < currentStep && (
                  <img className="w-3 h-3" src={checkIcon} alt="icon" />
                )}
              </div>
              {step !== steps.length && (
                <div
                  className={`stepper-line ${
                    currentStep === step || step < currentStep
                      ? "bg-main-orange "
                      : "bg-bg-main"
                  }
                  ${step < currentStep && "stepper-line-before-none"}`}
                />
              )}
            </div>
          ))}
        </div>
      </div>
      {/* {children} */}
    </>
  );
};

export default StepperIndicator;
