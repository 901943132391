import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { DashboardLayout } from '../../components/Layout';
import Navbar from '../../components/dashboardUIs/Navbar';
import AddHappyHoursInfo from '../../components/sections/AddHappyHoursInfo';
import Loader from '../../components/Loader';
import Modal from 'react-modal';
import { createHappyHour, updateHappyHour } from '../../api';
import { fetchEstablishmentDetailById } from '../../api/establishment';

function HappyHoursInfo() {
    const [showNextStep, setShowNextStep] = useState(2);
    const [state, setState] = useState({
        updatedHappyHours: null,
        isDataFetching: true,
        openConfirmationModal: false,
        isUpdatingHappyHoursDone: false,
        data: null,
        userDetail: localStorage.getItem("selectedEstablishment"),
    });

    const establishment = useSelector((state) => state.establishments.userEstablishment);
    const { list: cities } = useSelector((state) => state.cities);

    const handleHappyHoursSubmit = useCallback(async (happyHours) => {
        setState((prevState) => ({ ...prevState, openConfirmationModal: true }));
        let success = true; // Track if all API calls are successful

        for (const day in happyHours) {
            for (const entry of happyHours[day]) {
                const { hhId, ...happyHour } = entry;
                const payload = {
                    "Happy Hour Offer": happyHour.offer,
                    "Happy Hour Day": [day],
                    "Start Time": happyHour.start,
                    "End Time": happyHour.end,
                    'Establishments': [state.data.id],
                };

                try {
                    if (hhId) {
                        await updateHappyHour(hhId, payload);
                    } else {
                        await createHappyHour(payload);
                    }
                } catch (error) {
                    success = false; // Mark as false if any API call fails
                    console.error("Error saving Happy Hour:", error);
                }
            }
        }

        setState((prevState) => ({ ...prevState, isUpdatingHappyHoursDone: success }));

        if (success) {
            window.location.reload(); // Reload the page if all API calls are successful
        }
    }, [state.data]);

    const fetchData = useCallback(async (estid) => {
        try {
            if (!estid) {
                console.error("Please select an establishment to update.");
                return;
            }
            const data = await fetchEstablishmentDetailById(estid);
            const extractDetails = extractEstablishmentDetails(data);
            setState((prevState) => ({
                ...prevState,
                data: { ...data, extractDetails },
                isDataFetching: false,
            }));
        } catch (error) {
            setState((prevState) => ({ ...prevState, isDataFetching: false }));
            console.error("Error fetching establishment:", error);
        }
    }, []);

    useEffect(() => {
        if (establishment) {
            const extractDetails = extractEstablishmentDetails(establishment);
            setState((prevState) => ({
                ...prevState,
                data: { ...establishment, extractDetails },
                isDataFetching: false,
            }));
        } else {
            fetchData(state.userDetail);
        }
    }, [establishment, fetchData, state.userDetail]);

    return (
        <DashboardLayout>
            <div className="container mx-auto px-5 2xl:px-[50px] pt-[33px] mb-2">
                {state.isDataFetching ? (
                    <Loader />
                ) : (
                    <>
                        <Navbar cities={cities} establishmentDetail={state.data?.extractDetails} title="Happy Hours" />
                        <AddHappyHoursInfo
                            happyHoursData={state.data?.happyHours || []}
                            setShowNextStep={setShowNextStep}
                            onSubmit={handleHappyHoursSubmit}
                        />
                        <ConfirmationModal
                            open={state.openConfirmationModal}
                            onClose={() => setState((prevState) => ({ ...prevState, openConfirmationModal: false }))}
                            isdone={state.isUpdatingHappyHoursDone}
                            establishmentName={state.data?.extractDetails?.name || '-'}
                        />
                    </>
                )}
            </div>
        </DashboardLayout>
    );
}

function extractEstablishmentDetails(data) {
    return {
        name: data?.fields.Name,
        category: data?.fields.category.map((cat) => ({ value: cat, label: cat })),
        coverphoto: data.fields['cover-image'][0]?.url || '',
        avatar: data.fields['profile-image'][0]?.url || '',
        website: data?.fields.Website || '',
        address: data.fields['street 1'],
        phone: data.fields['Phone Number'] || '',
        crossstreet: data.crossStreets,
        happyHours: data.happyHours,
        menu: data?.fields.menu[0]?.url || '',
        zip: data?.fields.zip || '',
        state: data.fields['State (from state)'][0] || '',
        city: data?.fields.city,
    };
}

function ConfirmationModal({ open, onClose, isdone, establishmentName }) {
    return (
        <Modal
            isOpen={open}
            onRequestClose={onClose}
            overlayClassName="delete-overlay"
            className="max-w-[440px] h-[300px] bg-[#fff] mx-auto translate-y-1/2 rounded p-8 flex items-center justify-center"
        >
            <div className="flex flex-col justify-center items-center space-y-6 relative">
                <div className="flex flex-col space-y-1 text-center">
                    {!isdone ? (
                        <>
                            <h6 className="font-medium text-xl text-[#212B36]">
                                Updating the <strong>Happy Hours</strong> offers for <strong className="underline underline-offset-4">{establishmentName}</strong>
                            </h6>
                            <span className="text-sm font-light text-main-orange-50">Please wait for a while...</span>
                        </>
                    ) : (
                        <h6 className="font-medium text-xl text-[#212B36]">
                            Successfully Updated the <strong>Happy Hours</strong> offers for <strong className="underline underline-offset-4">{establishmentName}</strong>
                        </h6>
                    )}
                </div>
                {isdone && (
                    <button onClick={onClose} className="h-12 w-44 bg-red text-white border border-transparent rounded">
                        Done
                    </button>
                )}
            </div>
        </Modal>
    );
}

export default HappyHoursInfo;
