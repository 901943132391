import React, { useEffect, useState } from "react";

const useFixedOnScroll = (elementRef) => {
  const [isFixed, setIsFixed] = useState(false);

  const handleScroll = () => {
    const element = elementRef.current;
    if (!element) return;

    const { top } = element.getBoundingClientRect();
    setIsFixed(top <= 0);
  };

  useEffect(() => {
    const element = elementRef.current;
    if (!element) return;

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [elementRef]);

  return isFixed;
};


export default useFixedOnScroll;

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isMobile;
};

