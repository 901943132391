import React from "react";
import detailsvg from "../../assest/icons/dashboarscout.svg";
const ScoutDetails = () => {
  const scoutdata = [
    "Over 900 people have viewed Happy Hours in Boise so far this year",
    "Over 500 people requested to get directions in Boise so far this year",
    "373 people viewed menus so far this year",
    "Promoted Happy Hours saw a 5x increase in traffic over regular listed Happy Hours",
    "4700 people have looked for Happy Hours this year",
  ];
  return (
    <div className="w-[706px] rounded-[20px] bg-navy-blue px-7 pt-5 pb-5">
      <div className="flex justify-between">
        <div className="flex gap-[15px] items-center">
          <div className="bg-bg-main w-12 h-12 rounded-full flex justify-center items-center">
            <img src={detailsvg} />
          </div>
          <div>
            <p className="text-[10px] font-medium font-Metropolis text-dark-silver">
              NEWS
            </p>
            <h2 className="font-bold text-xl font-Metropolis text-white">
              SCOUT & SIP DETAILS
            </h2>
          </div>
        </div>
        <p className="text-dark-silver text-sm font-normal font-Mtropolis mt-[-3px]">
          Updated twice a month
        </p>
      </div>
      <div className="pt-[37px] ml-2 gap-2">
      {scoutdata.map((scoutdata)=>(
        <li className="font-medium text-base text-white font-Metropolis pt-3">
          {scoutdata}
        </li>
      ))}
      </div>
    </div>
  );
};

export default ScoutDetails;
