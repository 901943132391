import React from 'react';
import Layout from "../components/Layout";

const GiftCard = () => {
    return (
    <>
    <Layout>
    <div className="bg-bg-main min-h-screen flex items-center justify-center p-4">
      <div className="bg-bg-main-100 p-6 rounded-lg shadow-lg w-full max-w-xs">
        <div className="bg-main-orange-50 text-white text-center py-2 px-4 rounded-t-lg">
          <p className="text-2xl">$35</p>
        </div>
        <div className="text-center py-4">
          <h2 className="text-white text-xl font-bold">WIN A $35 GIFT CARD</h2>
          <p className="text-off-gray">TO MATADOR BOISE</p>
        </div>
        <form className="space-y-4">
          <div>
            <label className="block text-off-gray">What city do you live in?</label>
            <input type="text" className="w-full p-2 rounded bg-light-gray text-white" placeholder="Select City" />
          </div>
          <div>
            <label className="block text-off-gray">What is your name?</label>
            <input type="text" className="w-full p-2 rounded bg-light-gray text-white" placeholder="John Doe" />
          </div>
          <div>
            <label className="block text-off-gray">What is your email?</label>
            <input type="email" className="w-full p-2 rounded bg-light-gray text-white" placeholder="your@email.com" />
          </div>
          <div>
            <label className="block text-off-gray">Have you been to Matador before?</label>
            <input type="text" className="w-full p-2 rounded bg-light-gray text-white" placeholder="Yes or no" />
          </div>
          <div>
            <label className="block text-off-gray">Favorite food or dish?</label>
            <input type="text" className="w-full p-2 rounded bg-light-gray text-white" placeholder="Ramen with" />
          </div>
          <div>
            <label className="block text-off-gray">Favorite drink?</label>
            <input type="text" className="w-full p-2 rounded bg-light-gray text-white" placeholder="Old Fashioned" />
          </div>
          <button className="w-full bg-main-orange-100 text-white py-2 rounded">ENTER</button>
          <p className="text-off-gray text-xs text-center mt-4">
            *WINNER WILL BE SELECTED RANDOMLY ON APRIL 17 VIA EMAIL
          </p>
        </form>
      </div>
    </div>
    </Layout>
    </>
  );
};

export default GiftCard;
