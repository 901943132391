import React from "react";
import estlive from "../assest/icons/estliveicon.svg";
import { generateDateTimeFromDateAndTime, isSameDay } from "../utils";

function Time({ date, startTime, endTime, className = "" }) {
  const currentTime = new Date();

  const promoDate = new Date(date);
  const startDateTime = generateDateTimeFromDateAndTime(date, startTime);
  const endDateTime = generateDateTimeFromDateAndTime(date, endTime);

  const isLive = currentTime >= startDateTime && currentTime <= endDateTime;
  const isToday = isSameDay(promoDate, currentTime);

  return (
    <div
      className={` md:w-[320px] h-10 md:h-[65px] relative text-white bg-bg-time rounded-[10px] flex items-center justify-center px-4 md:px-6 whitespace-nowrap ${
        !isLive ? "md:!w-[250px] !w-[200px]" : ""
      } ${className}`}
    >
      {/* <div
        className={` md:w-[320px] h-10 md:h-[65px] left-0 top-0 absolute bg-bg-time rounded-[10px]  ${
          !isLive ? "md:!w-[250px] !w-[180px]" : ""
        }`}
      /> */}
      <div className=" text-center  text-base md:text-xl font-semibold md:leading-10 flex justify-evenly items-center w-full md:w-[unset] text-inherit">
        {isLive && isToday ? (
          <div className="flex items-center justify-center mr-2">
            <img
              src={estlive}
              alt="live"
              className="w-6 md:w-8 mr-1.5 md:mr-2"
            />
            <span className=" text-xs md:text-xl font-semibold">Live Now!</span>
          </div>
        ) : (
          startTime
        )}
        - {endTime}
      </div>
    </div>
  );
}

export default Time;

export const HappyHourTime = ({
  isCurrentTimeWithinHappyHour = false,
  startTime,
  endTime,
  className,
  isunderline = false,
}) => (
  <div className={`flex items-center gap-2 mb-2 relative ${className}`}>
    {isCurrentTimeWithinHappyHour ? (
      <div className="flex items-center text-white py-0.5 rounded">
        <img src={estlive} alt="Live" className="w-10 h-10" />
      </div>
    ) : (
      <span className="text-[#C5C4C2] font-normal">{startTime}</span>
    )}
    <span
      className={`${
        isCurrentTimeWithinHappyHour
          ? "text-white text-lg"
          : "text-[#C5C4C2] font-normal"
      }`}
    >
      -
    </span>
    <span 
      className={`${
        isCurrentTimeWithinHappyHour
          ? "font-semibold text-white text-lg"
          : "text-[#C5C4C2] font-normal"
      }`}
    >
      {endTime}
    </span>
    {/* {isunderline && (
      <hr className="absolute bottom-0 w-full border border-gray max-w-[32%]" />
    )} */}
  </div>
);
