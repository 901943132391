import React, { useState, useEffect } from "react";

const ScrollProgressIndicator = ({
  currentIndex,
  totalSlides,
  children,
  show,
}) => {
  const [percent, setPercent] = useState(0);
  const circumference = 2 * Math.PI * 30; // Assuming the radius is 30

  useEffect(() => {
    const timePerIncrement = 5000 / 50; // Update every 50ms for smoother animation
    const increment = 50 / (5000 / timePerIncrement); // Calculate increment value to reach 100 in 5 seconds
    if (!show) {
      setPercent(0);
      return;
    }

    const progressInterval = setInterval(() => {
      setPercent((prevPercent) => {
        if (prevPercent >= 100) {
          clearInterval(progressInterval);
          return 0; // Reset to 0 to start over for the next slide
        }
        return prevPercent + increment;
      });
    }, timePerIncrement);

    return () => clearInterval(progressInterval); // Cleanup interval on component unmount
  }, [currentIndex, show, totalSlides]); // Only reset progress when the slide changes

  return (
    <div className="relative inline-flex items-center justify-center overflow-hidden rounded-full">
      <svg className="w-[30px] h-[30px]">
        <circle
          className=""
          strokeWidth="6"
          stroke="rgba(78, 54, 18, 0.7)"
          fill="transparent"
          r="15"
          cx="15"
          cy="15"
        />
        <circle
          className="text-main-orange"
          strokeWidth="6"
          strokeDasharray={circumference}
          // strokeDashoffset={5}
          strokeDashoffset={circumference - (percent / 100) * circumference}
          strokeLinecap="round"
          stroke="currentColor"
          fill="transparent"
          r="15"
          cx="15"
          cy="15"
        />
      </svg>
      {children}
    </div>
  );
};

export default ScrollProgressIndicator;
