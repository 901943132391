import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getStreets } from '../../api/index.js';

// Initial state
const initialState = {
  closeAd: true,
  loading: false,
  groupedByCity: [],
  establishmentsFiltered: [],
  categories: [],
  CrossStreetData: [],
  searchQuery: '',
  days: [
    { value: 'Sun', active: false },
    { value: 'Mon', active: false },
    { value: 'Tue', active: false },
    { value: 'Wed', active: false },
    { value: 'Thu', active: false },
    { value: 'Fri', active: false },
    { value: 'Sat', active: false },
  ],
  isFilterOpen: false,
  selectedFilters: {
    City: [],
    Category: [],
    Streets: [],
    Amenities: [],
    isLive: [],
  },
  error: null,
  liveHappyHours:[],
  isLive:false,
};

// Async thunk for loading cross streets data
export const loadCrossStreets = createAsyncThunk(
  'happyHours/loadCrossStreets',
  async (_, { rejectWithValue }) => {
    try {
      const data = await getStreets();
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Slice
const happyHoursSlice = createSlice({
  name: 'happyHours',
  initialState,
  reducers: {
    setCloseAd: (state, action) => {
      state.closeAd = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setGroupedByCity: (state, action) => {
      state.groupedByCity = action.payload;
    },
    setEstablishmentFiltered: (state, action) => {
      state.establishmentsFiltered = action.payload;
    },
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
    setDays: (state, action) => {
      state.days = action.payload;
    },
    setIsLive: (state, action) => {
      state.isLive = action.payload;
    },
    setLiveHappyHours: (state, action) => {
      state.liveHappyHours = action.payload;
    },
    setIsFilterOpen: (state, action) => {
      state.isFilterOpen = action.payload;
    },
    setSelectedFilters: (state, action) => {
      state.selectedFilters = action.payload;
    },
    resetFilters: (state) => {
      state.selectedFilters = { City: [], Category: [], Streets: [], Amenities: [] };
    },
    updateSelectedFilters: (state, action) => {
      const { filterKey, selectedValue, isChecked } = action.payload;
      if (!state.selectedFilters[filterKey]) {
        state.selectedFilters[filterKey] = [];
      }
      if (isChecked) {
        if (!state.selectedFilters[filterKey].includes(selectedValue)) {
          state.selectedFilters[filterKey].push(selectedValue);
        }
      } else {
        state.selectedFilters[filterKey] = state.selectedFilters[filterKey].filter(
          (value) => value !== selectedValue
        );
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadCrossStreets.pending, (state) => {
        state.loading = true;
      })
      .addCase(loadCrossStreets.fulfilled, (state, action) => {
        state.CrossStreetData = action.payload;
        state.loading = false;
      })
      .addCase(loadCrossStreets.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {
  setCloseAd,
  setLoading,
  setGroupedByCity,
  setEstablishmentFiltered,
  setCategories,
  setSearchQuery,
  setDays,
  setIsFilterOpen,
  setSelectedFilters,
  resetFilters,
  updateSelectedFilters,
  setIsLive,
  setLiveHappyHours
} = happyHoursSlice.actions;

export default happyHoursSlice.reducer;
