import axios from "axios";
import { config, getStreetsById } from "..";
import { fetchCities } from "../city";
import { fetchPromotions } from "../promotions";
import { baseId, tableIds } from "../../constant";
import { fetchHappyHourById, fetchHappyHours } from "../happyhours";

const apiEstablishmentsUrl = `https://api.airtable.com/v0/${baseId}/${tableIds.establishmentsTableId}`;
const apiEstablishmentsUserUrl = `https://api.airtable.com/v0/${baseId}/${tableIds.establishmentUserId}`;
const apiStateUrl = `https://api.airtable.com/v0/${baseId}/${tableIds.stateTableId}`;

// export const fetchEstablishmentsDataByCity = async (establishmentIds) => {
//     try {
//         // Construct a filter formula to match any of the provided establishment IDs
//         const filterByEstablishmentIds = `OR(${establishmentIds
//             .map((id) => `RECORD_ID()="${id}"`)
//             .join(",")})`;
//         const urlWithFilter = `${apiEstablishmentsUrl}?filterByFormula=${encodeURIComponent(
//             filterByEstablishmentIds
//         )}`;

//         const response = await axios.get(urlWithFilter, config);
//         const establishments = response.data.records;

//         // Fetch state data for each establishment
//         const stateResponses = await Promise.all(
//             establishments.map(async (establishment) => {
//                 const stateId = establishment.fields.state[0];
//                 const stateUrlWithFilter = `${apiStateUrl}?filterByFormula=${encodeURIComponent(
//                     `RECORD_ID()="${stateId}"`
//                 )}`;
//                 const stateResponse = await axios.get(stateUrlWithFilter, config);
//                 return stateResponse.data.records[0];
//             })
//         );

//         // Combine establishment and state data
//         const combinedData = establishments.map((establishment, index) => ({
//             ...establishment,
//             state: stateResponses[index],
//         }));
//         return combinedData;
//     } catch (error) {
//         console.error("Error fetching establishments data from Airtable:", error);
//     }
// };

export const fetchEstablishmentsDataByCity = async (establishmentIds) => {
    try {
        // Fetch all establishments
        const allEstablishments = await fetchAllEstablishments();

        // Filter establishments based on the provided IDs
        const establishments = allEstablishments.filter(establishment =>
            establishmentIds.includes(establishment.id)
        );

        // Extract unique state IDs from the establishments
        const stateIds = [...new Set(establishments.map(establishment => establishment.fields.state[0]))];

        // Fetch all states
        const statesResponse = await axios.get(apiStateUrl, config);
        const allStates = statesResponse.data.records;

        // Filter states based on the extracted state IDs
        const states = allStates.filter(state => stateIds.includes(state.id));

        // Combine establishment and state data
        const combinedData = establishments.map(establishment => {
            const stateId = establishment.fields.state[0];
            const state = states.find(state => state.id === stateId);
            return {
                ...establishment,
                state: state,
            };
        });

        return combinedData;
    } catch (error) {
        console.error("Error fetching establishments data from Airtable:", error);
    }
};


export const fetchEstablishmentDetailByName = async (name, email) => {
    try {
        const response = await axios.get(`${apiEstablishmentsUrl}?filterByFormula=AND({Name} = '${name}', {Email} = '${email}')`, config);
        const establishments = response.data.records;

        if (establishments.length === 0) {
            throw new Error('Establishment was not found associated with that name or email. Please go to the business listing page and create the establishment.');
        }

        const establishment = establishments[0];

        // Fetching additional details if needed
        const happyHoursIds = establishment.fields['Happy Hours'];
        const crossStreetsIds = establishment.fields.crossStreets;

        let happyHoursDetails;
        if (happyHoursIds) {
            const happyHoursPromises = happyHoursIds.map(id => fetchHappyHours(id));
            happyHoursDetails = await Promise.all(happyHoursPromises);
        }

        let crossStreetsDetails;
        if (crossStreetsIds) {
            const crossStreetsPromises = crossStreetsIds.map(id => getStreetsById(id));
            crossStreetsDetails = await Promise.all(crossStreetsPromises);
        }

        return {
            ...establishment,
            happyHours: happyHoursDetails,
            crossStreets: crossStreetsDetails,
        };
    } catch (error) {
        throw new Error(error.message || "Error fetching establishment details");
    }
};


export const fetchEstablishmentDetailById = async (id) => {
    try {
        const response = await axios.get(`${apiEstablishmentsUrl}/${id}`, config);
        const establishment = response.data;

        // Assuming you have similar functions for State and Cross Streets
        const happyHoursIds = establishment.fields['Happy Hours'];
        const crossStreetsIds = establishment.fields.crossStreets;

        // Fetching Happy Hours details
        let happyHoursDetails;
        let happyHoursPromises;

        if (happyHoursIds) {
            happyHoursPromises = happyHoursIds?.map(id => fetchHappyHourById(id));
            happyHoursDetails = await Promise.all(happyHoursPromises);
        }

        // // Fetching Cross Streets details
        let crossStreetsPromises;
        let crossStreetsDetails;

        if (crossStreetsIds) {
            crossStreetsPromises = crossStreetsIds.map(id => getStreetsById(id)); // Assuming this function exists
            crossStreetsDetails = await Promise.all(crossStreetsPromises);
        }

        // Combining all fetched details
        const establishmentDetails = {
            ...establishment,
            happyHours: happyHoursDetails,
            crossStreets: crossStreetsDetails,
        };
        return establishmentDetails;
    } catch (error) {
        console.error("Error fetching establishment details:", error);
    }
};

export const fetchAllEstablishments = async () => {
    try {
        let allEstablishments = [];
        let offset = null;

        // Fetch all establishments with pagination
        do {
            const response = await axios.get(apiEstablishmentsUrl, { ...config, params: { offset } });
            const establishments = response.data.records;
            allEstablishments = [...allEstablishments, ...establishments];
            offset = response.data.offset;
        } while (offset);

        // Fetch all cities
        const cities = await fetchCities();
        const cityMap = new Map(cities.map(city => [city.id, city.fields.Name]));

        // Collect all happy hour IDs and promotion IDs
        const happyHourIds = [];
        const promotionIds = [];
        allEstablishments.forEach(establishment => {
            if (establishment.fields["Happy Hours"]) {
                happyHourIds.push(...establishment.fields["Happy Hours"]);
            }
            if (establishment.fields.Promotions) {
                promotionIds.push(...establishment.fields.Promotions);
            }
        });

        // Fetch all happy hours and promotions in batch
        const happyHours = await fetchHappyHours(happyHourIds);
        const promotions = await fetchPromotions(promotionIds);
        const happyHourMap = new Map(happyHours.map(hh => [hh.id, hh]));
        const promotionMap = new Map(promotions.map(promo => [promo.id, promo]));

        // Enrich establishments with the pre-fetched data
        const enrichedEstablishments = allEstablishments.map(establishment => {
            const cityDetails = cityMap.get(establishment.fields.city[0]);
            const happyHourDetails = establishment.fields["Happy Hours"]
                ? establishment.fields["Happy Hours"].map(id => happyHourMap.get(id))
                : [];
            const promotionDetails = establishment.fields.Promotions
                ? establishment.fields.Promotions.map(id => promotionMap.get(id))
                : [];

            return {
                ...establishment,
                city: cityDetails || [],
                happyHours: happyHourDetails,
                promotions: promotionDetails,
            };
        });

        return enrichedEstablishments;
    } catch (error) {
        console.error("Error fetching establishments data from Airtable:", error);
        throw error;
    }
};


// export const fetchAllEstablishments = async (limit = 10, page = 1) => {
//     try {
//         // Calculate the offset based on the current page and the number of records per page
//         const offset = (page - 1) * limit;

//         // Construct the API URL with query parameters for limit and offset
//         const urlWithPagination = `${apiEstablishmentsUrl}?limit=${limit}&offset=${offset}`;

//         const response = await axios.get(urlWithPagination, config);
//         const establishments = response.data.records;

//         // Fetch related data for each establishment
//         const enrichedEstablishments = await Promise.all(
//             establishments.map(async (establishment) => {
//                 // Fetch city details
//                 const cityDetails = await fetchCityById(establishment.fields.city[0]);
//                 // Check if Happy Hours field exists and is not empty
//                 const happyHours = establishment.fields["Happy Hours"];
//                 const happyHourDetails =
//                     happyHours && happyHours.length > 0
//                         ? await Promise.all(happyHours.map(fetchHappyHourById))
//                         : [];
//                 // Check if Promotions field exists and is not empty
//                 const promotions = establishment.fields.Promotions;
//                 const promotionDetails =
//                     promotions && promotions.length > 0
//                         ? await Promise.all(promotions.map(fetchPromotionById))
//                         : [];

//                 return {
//                     ...establishment,
//                     city: cityDetails.fields.Name,
//                     happyHours: happyHourDetails,
//                     promotions: promotionDetails,
//                 };
//             })
//         );

//         // Assuming the API returns the total number of records in the response
//         // You might need to adjust this based on your actual API implementation
//         const totalRecords = response.data.totalRecords || establishments.length;

//         return { data: enrichedEstablishments, page, totalRecords };
//     } catch (error) {
//         console.error("Error fetching establishments data from Airtable:", error);
//     }
// };

export const createEstablishment = async (establishmentData) => {
    try {
        const payload = {
            fields: establishmentData,
            typecast: true,
        };
        const response = await axios.post(apiEstablishmentsUrl, payload, config);
        return response.data; // Return the ID of the created establishment
    } catch (error) {
        console.error("Error creating establishment:", error);
        throw error; // Re-throw the error to be handled by the calling function
    }
};

export const getEstablishments = async () => {
    try {
        const response = await axios.get(apiEstablishmentsUrl, config);
        const establishments = response.data.records;
        return establishments;
    } catch (error) {
        console.error("Error fetching all establishments:", error);
        throw error; // Re-throw the error to be handled by the calling function
    }
};

export const updateEstablishment = async (id, establishmentData) => {
    try {
        const payload = {
            fields: establishmentData,
            typecast: true,
        };
        const response = await axios.patch(`${apiEstablishmentsUrl}/${id}`, payload, config);
        return response.data;
    } catch (error) {
        console.error(`Error updating establishment with ID: ${id}`, error);
        throw error; // Re-throw the error to be handled by the calling function
    }
};

export const deleteEstablishment = async (id) => {
    try {
        await axios.delete(`${apiEstablishmentsUrl}/${id}`, config);
        return { success: true };
    } catch (error) {
        console.error(`Error deleting establishment with ID: ${id}`, error);
        throw error; // Re-throw the error to be handled by the calling function
    }
};


export const CreateEstablishmentUser = async (data) => {
    try {
        const payload = {
            fields: data,
            // typecast: true,
        };
        const response = await axios.post(
            apiEstablishmentsUserUrl,
            payload,
            config
        );
        return response;
    } catch (error) {
        console.error(`Error updating Establishment User with ID:`, error);
        throw error; // Re-throw the error to be handled by the calling function
    }
};

export const getEstablishmentUser = async (data) => {
    try {

        const response = await axios.get(
            apiEstablishmentsUserUrl,
            config
        );
        return response.data.records;
    } catch (error) {
        console.error(`Error updating Establishment User with ID:`, error);
        throw error; // Re-throw the error to be handled by the calling function
    }
};

export const deleteEstablishmentUser = async (id) => {
    try {
        await axios.delete(`${apiEstablishmentsUserUrl}/${id}`, config);
        return { success: true };
    } catch (error) {
        console.error(`Error deleting establishment with ID: ${id}`, error);
        throw error; // Re-throw the error to be handled by the calling function
    }
};


export const verifyEstablishmentBelongsToTheUser = async (name, email) => {
    try {
        const response = await axios.get(`${apiEstablishmentsUrl}?filterByFormula=AND({Name} = '${name}', {Email} = '${email}')`, config);
        const establishments = response.data.records;
        if (establishments.length === 0) {
            // If no establishments are found for the given email, return false
            return false;
        } else {
            // If establishments are found, return true
            return true;
        }
    } catch (error) {
        // Handle errors during the fetch operation
        throw new Error(error.message || "Error fetching establishment details");
    }
};