import React, { useEffect, useRef, useState } from "react";
import ColorThief from "colorthief";
import bgImg1 from "../assest/images/bg-img-1.png";
import bgImg2 from "../assest/images/bg-img-2.png";
import { rgbToHex } from "../utils";

const ImageContainer = ({ imageUrl }) => {
  const imageRef = useRef(null);
  const [dominantColor, setDominantColor] = useState(null);
  const [palette, setPalette] = useState([]);

  const extractPalette = () => {
    const colorThief = new ColorThief();
    const colors = colorThief.getPalette(imageRef.current, 2); // 2nd argument is the number of colors you want
    setPalette(colors.map(rgbToHex)); // Convert each color to hex before setting the state
  };

  useEffect(() => {
    const colorThief = new ColorThief();

    const handleImageLoad = () => {
      const dominantColor = colorThief.getColor(imageRef.current);
      setDominantColor(`rgb(${dominantColor.join(", ")})`);
      extractPalette();
    };

    // Load the image and trigger color extraction when it's loaded
    const image = new Image();
    image.src = imageUrl;
    image.crossOrigin = "Anonymous";
    image.onload = handleImageLoad;

    return () => {
      // Cleanup event listeners
      image.onload = null;
    };
  }, [imageUrl]);

  return (
    <div className="flex relative max-h-fit">
      <div
        className="overflow-hidden w-full z-10 min-w-[313px] md:min-w-[490px] max-h-[650px] h-[285px] md:h-[650px] img-container shadow-lg relative bg-no-repeat bg-center bg-cover "
        style={{ backgroundImage: `url(${imageUrl})` }}
      >
        <img
          ref={imageRef}
          crossOrigin="anonymous"
          src={imageUrl}
          alt="Image"
          style={{ display: "none" }}
        />
      </div>
      <div className="max-h-[620px] absolute w-full h-full">
        {/* <img className="max-h-[590px] h-full w-full relative top-[36px] -right-[26px] opacity-90" src={bgImg2} alt="bg-img" />
        <img className=" h-full w-full absolute top-[18px] -right-[15px]" src={bgImg1} alt="bg-img" /> */}
        <div
          className="h-[98%] md:h-full w-full absolute top-[2px] md:top-[18px] -right-[15px] img-container opacity-80 md:opacity-100"
          style={{ background: dominantColor }}
        />
        <div
          className="h-[98%] md:max-h-[590px] md:h-full w-full relative top-[8px] md:top-[36px] -right-[26px] opacity-70 md:opacity-100 img-container"
          style={{ background: dominantColor }}
        />
      </div>
    </div>
  );
};

export default ImageContainer;
