// src/components/NoMobileScreen.jsx
import React, { useState } from "react";
import mobResponsive from "../assest/icons/mob-responsive.svg";

function NoMobileScreen() {
  return (
    <div
      className="bg-black flex flex-col justify-center items-center pb-8"
      style={{ height: "calc(100vh - 50px)" }}
    >
      <img
        src={mobResponsive}
        alt="Mobile Responsive Icon"
        className="w-32 h-32 mb-4"
      />
      <div className="max-w-[70%] text-center mx-auto border">
        <p className="font-bold text-base text-[#fff] mb-2">
          Your screen is too small
        </p>
        <p className="font-light text-[#fff] text-base mb-2">
          Please access{" "}
          <span className="italic font-semibold">
            “Establishments” on Scout & Sip
          </span>{" "}
          from a desktop device.
        </p>
      </div>
        <p className="italic font-light text-[#fff] text-base mb-2">
          We will be working on the mobile version
        </p>
    </div>
  );
}

export default NoMobileScreen;
