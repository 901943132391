import React, { useEffect, useState } from "react";

const Input = ({
  placeholder,
  type = "text",
  name,
  value,
  className = "",
  handleInputChange,
}) => {
  return (
    <input
      type={type}
      name={name}
      value={value}
      placeholder={placeholder}
      onChange={handleInputChange}
      className={`bg-transparent border-white border rounded-full h-14 w-72 pl-5 ${className}`}
    />
  );
};

export default Input;


export const InputWithIcon = ({
  placeholder,
  type = "text",
  name,
  value,
  className = "",
  handleInputChange,
  icon,
}) => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (value && value !== "") {
      setIsActive(true);
    }else{
      setIsActive(false);
    }
  }, [value]);

  return (
    <div className={`relative w-full input-w-icon ${isActive ? "active" : ""}`}>
      <input
        type={type}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={handleInputChange}
        className={`bg-white border border-white rounded h-14 w-full text-bg-main-100 px-5 ${className}`}
        // onFocus={() => setIsActive(true)}
        // onBlur={() => setIsActive(false)}
      />
      {icon && (
        <div className="absolute right-4 top-4">
          {React.cloneElement(icon, { activeColor: isActive ? "#41A702" : "" })}
        </div>
      )}
    </div>
  );
};
