import React from "react";
import ScrollProgressIndicator from "./Progressbar";

function PromationSelector({
  show,
  currentIndex,
  value,
  totalSlides,
  onClick,
}) {
  return (
    <div
      className=" cursor-pointer relative mt-2"
      onClick={() => onClick(value)}
    >
      <ScrollProgressIndicator
        show={show}
        totalSlides={totalSlides}
        currentIndex={currentIndex}
      />
      <div className="w-6 h-6 absolute bg-bg-main rounded-full top-[3px] left-[3px] -z-0">
        <div
          className={`text-center h-full text-[#fff] text-sm  flex items-center justify-center ${
            show ? "font-semibold" : "font-light"
          }`}
        >
          {value}
        </div>
      </div>
    </div>
  );
}

export default PromationSelector;
