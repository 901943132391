import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchEstablishmentsDataByCity, } from '../../api';
import { fetchAllEstablishments as fetchAllEstablishmentsAPI } from '../../api';
import { fetchEstablishmentDetailByName } from '../../api/establishment';

// Async thunk for fetching establishments by city IDs
export const fetchEstablishmentsByCity = createAsyncThunk(
   'establishments/fetchEstablishmentsByCity',
   async (establishmentIds, thunkAPI) => {
      const response = await fetchEstablishmentsDataByCity(establishmentIds);
      return response;
   }
);

// Async thunk for fetching all establishments
export const fetchAllEstablishments = createAsyncThunk(
   'establishments/fetchAllEstablishments',
   async (_, thunkAPI) => {
      const response = await fetchAllEstablishmentsAPI();
      return response;
   }
);

// Async thunk for fetching all establishments
export const fetchAllEstablishmentsOptions = createAsyncThunk(
   'establishments/fetchEstablishmentsDropdown',
   async (_, thunkAPI) => {
      try {
         const response = await fetchAllEstablishmentsAPI();
         // Map the response to include only the necessary fields
         const mappedResponse = response.map(establishment => ({
            id: establishment.id,
            value: establishment.fields.Name,
            label: establishment.fields.Name,
         }));
         return mappedResponse;
      } catch (error) {
         return thunkAPI.rejectWithValue(error.message);
      }
   }
);

// Async thunk for fetching establishment by user
export const fetchEstablishmentByUser = createAsyncThunk(
   'establishments/fetchEstablishmentByUser',
   async (userDetail, thunkAPI) => {
      try {
         const response = await fetchEstablishmentDetailByName(userDetail.fields.Establishment, userDetail.fields.Email);
         return response;
      } catch (error) {
         return thunkAPI.rejectWithValue(error.message);
      }
   }
);

const establishmentsSlice = createSlice({
   name: 'establishments',
   initialState: {
      byCity: [],
      all: [],
      userEstablishment: null,
      status: 'idle',
      error: null,
      establishmentOption: []
   },
   reducers: {
      setUserEstablishment: (state, action) => {
         state.userEstablishment = action.payload;
      },
   },
   extraReducers: (builder) => {
      builder
         .addCase(fetchEstablishmentsByCity.fulfilled, (state, action) => {
            state.byCity = action.payload;
         })
         .addCase(fetchAllEstablishments.fulfilled, (state, action) => {
            state.all = action.payload;
         })
         .addCase(fetchAllEstablishmentsOptions.fulfilled, (state, action) => {
            state.establishmentOption = action.payload;
         })
         .addCase(fetchEstablishmentByUser.pending, (state) => {
            state.status = 'loading';
         })
         .addCase(fetchEstablishmentByUser.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.userEstablishment = action.payload;
         })
         .addCase(fetchEstablishmentByUser.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
         });
   },
});

export const { setUserEstablishment } = establishmentsSlice.actions;

export default establishmentsSlice.reducer;
