
import { Link } from "react-router-dom";
// import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import promoExample from "../../../assest/images/promo-example.png";
import establishment from "../../../assest/images/establishment.png";
import menuExample from "../../../assest/images/menu.png";
import upload from "../../../assest/icons/upload.svg";
import loading from "../../../assest/Loading.gif";
// import { Document, Page } from "react-pdf";
import done from "../../../assest/icons/done.svg";
import { CircularLoader } from "../../Loaders";
import happyhourdone from "../../../assest/images/happyhour-done.png";
import { LoadingButton } from "../../Button";

export const UploadProfile = ({
  formData,
  setFormData,
  handleNext,
  isLoading,
  handleFileUpload,
  uploadedImage,
  uploadProgress,
  showGif,
}) => {
  return (
    <div className="flex gap-y-4 flex-col md:flex-row">
      <div className="flex flex-col justify-center items-center gap-y-5 w-full md:min-w-[400px] relative">
        <div className="rounded-full flex items-center justify-center bg-white w-24 h-24 relative">
          <input
            type="file"
            accept="image/*"
            onChange={handleFileUpload}
            className="opacity-0 absolute cursor-pointer z-50"
            style={{ width: "100%", height: "100%" }}
          />

          {uploadedImage && (
            <div className="relative">
              <img
                src={uploadedImage || upload}
                alt="upload"
                className="cursor-pointer rounded-full w-[100px] h-[100px] object-center object-cover"
              />
              <img
                src={done}
                alt="check"
                className="w-8 h-8 absolute -top-1 -right-1"
              />
            </div>
          )}
          {!uploadedImage && uploadProgress === 0 && !showGif && (
            <img
              src={upload}
              alt="upload"
              className="cursor-pointer rounded-full"
            />
          )}
          {!uploadedImage && showGif && (
            <div
              className=" bg-bg-main rounded-full h-[100px] relative"
              style={{ width: "120px", height: "120px", margin: "auto" }}
            >
              <img
                src={loading}
                alt="loading"
                className="absolute top-0 left-3  h-full w-full max-w-24 max-h-24  object-scale-down"
              />
              <CircularLoader progress={uploadProgress} />
            </div>
          )}
        </div>
        <p className="font-semibold text-2xl leading-8 mt-4 flex">
          Upload Business Avatar
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="ml-2"
          >
            <path
              d="M8 0.425049L9.04512 5.5536L13.1423 2.29669L10.6463 6.89718L15.8785 7.03586L11.0093 8.95567L14.9282 12.425L9.96418 10.7659L10.7362 15.9426L8 11.4808L5.26384 15.9426L6.03582 10.7659L1.0718 12.425L4.99069 8.95567L0.121538 7.03586L5.35366 6.89718L2.8577 2.29669L6.95488 5.5536L8 0.425049Z"
              fill="#EB4335"
            />
          </svg>
        </p>
        {/* {!isLoading && <WaterWave />} */}
        <button
          disabled={!uploadedImage}
          onClick={handleNext}
          className={`orange-btn px-4 py-2 rounded ${
            uploadedImage ? "opacity-100" : "opacity-50 cursor-not-allowed"
          }`}
        >
          Next
        </button>
      </div>
      <div className=" text-center w-full max-w-[736px]">
        <p className="font-medium text-base mb-2">Boosted Happy Hour Example</p>
        <img src={promoExample} alt="" />
      </div>
    </div>
  );
};

export const UploadCoverPhoto = ({
  formData,
  setFormData,
  handleNext,
  handleFileUpload,
  uploadedImage,
  uploadProgress,
  showGif,
}) => {
  return (
    <div className="flex gap-y-4 flex-col md:flex-row">
      <div className="flex flex-col justify-start items-center gap-y-5 w-full lg:min-w-[400px] relative">
        <div
          className="div-progress rounded-[20px]  w-full md:w-[360px] h-[200px] z-0 top-[36px] !bg-transparent"
          style={{
            backgroundImage: `conic-gradient(
        orange,
        orange ${uploadProgress}deg,
        transparent ${uploadProgress}deg)`,
          }}
        />
        <div className="absolute top-[38.5px] text-center flex gap-y-4 flex-col items-center w-full">
          <div className="rounded-[20px] flex items-center justify-center bg-white w-[calc(100%-4px)] md:w-[355px] h-[194.5px] relative">
            <input
              type="file"
              accept="image/*"
              onChange={handleFileUpload}
              className="opacity-0 absolute cursor-pointer"
              style={{ width: "100%", height: "100%" }}
            />
            {uploadedImage && (
              <div className="relative w-full h-full rounded-[20px]">
                <img
                  src={uploadedImage || upload}
                  alt="upload"
                  className="cursor-pointer w-full h-full object-center object-cover rounded-[20px]"
                />
                <img
                  src={done}
                  alt="check"
                  className="w-8 h-8 absolute -top-1 -right-1"
                />
              </div>
            )}

            {!uploadedImage && uploadProgress === 0 && !showGif && (
              <img
                src={upload}
                alt="upload"
                className="cursor-pointer rounded-full"
              />
            )}
            {!uploadedImage && showGif && (
              <div className=" bg-bg-main  w-full h-full flex items-center justify-center rounded-[20px]">
                <img
                  src={loading}
                  alt="loading"
                  className=" max-h-full object-contain"
                />
                {/* <CircularLoader progress={uploadProgress} /> */}
              </div>
            )}
          </div>

          <p className="font-semibold text-2xl leading-8 flex">
            Upload Cover Photo
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="ml-2"
            >
              <path
                d="M8 0.425049L9.04512 5.5536L13.1423 2.29669L10.6463 6.89718L15.8785 7.03586L11.0093 8.95567L14.9282 12.425L9.96418 10.7659L10.7362 15.9426L8 11.4808L5.26384 15.9426L6.03582 10.7659L1.0718 12.425L4.99069 8.95567L0.121538 7.03586L5.35366 6.89718L2.8577 2.29669L6.95488 5.5536L8 0.425049Z"
                fill="#EB4335"
              />
            </svg>
          </p>
          <button
            disabled={!uploadedImage}
            onClick={handleNext}
            className={`orange-btn text-white px-4 py-2 rounded max-w-fit ${
              uploadedImage ? "opacity-100" : "opacity-50 cursor-not-allowed"
            }`}
          >
            Next
          </button>
        </div>
      </div>
      <div className=" text-center w-full mt-36 md:mt-0 lg:min-w-[736px]">
        {/* <p className="font-medium text-base mb-2">Boosted Happy Hour Example</p> */}
        <img className="max-h-[600px] mx-auto" src={establishment} alt="" />
      </div>
    </div>
  );
};

export const UploadMenu = ({
  formData,
  setFormData,
  handleNext,
  handleFileUpload,
  previewUrl,
  previewType,
  uploadProgress,
  showGif,
}) => {
  return (
    <div className="flex gap-y-4 flex-col md:flex-row">
      <div className="flex flex-col justify-center items-center gap-y-5 w-full lg:min-w-[400px]">
        <label className="rounded-full flex items-center justify-center bg-white w-24 h-24 cursor-pointer relative">
          <input
            type="file"
            accept="image/*,application/pdf"
            onChange={handleFileUpload}
            className="opacity-0 absolute cursor-pointer"
            style={{ width: "100%", height: "100%" }}
          />
          {previewType === "application/pdf" ? (
            // <Document file={previewUrl} style={{ width: "100%", height: "100%" }}>
            //   <Page pageNumber={1} />
            // </Document>
            <div className="relative">
              <embed
                src={previewUrl}
                type="application/pdf"
                width="100%"
                height="100%"
                className="cursor-pointer rounded-full w-[100px] h-[100px] object-center object-cover"
              />
              <img
                src={done}
                alt="check"
                className="w-8 h-8 absolute -top-1 -right-1"
              />
            </div>
          ) : (
            // <img src={previewUrl || upload} alt="upload" className="h-full w-full object-center object-cover cursor-pointer rounded-full" />

            <>
              {previewUrl && (
                <div className="relative">
                  <img
                    src={previewUrl}
                    alt="upload"
                    className="cursor-pointer rounded-full w-[100px] h-[100px] object-center object-cover"
                  />
                  <img
                    src={done}
                    alt="check"
                    className="w-8 h-8 absolute -top-1 -right-1"
                  />
                </div>
              )}
              {!previewUrl && uploadProgress === 0 && !showGif && (
                <img
                  src={upload}
                  alt="upload"
                  className="cursor-pointer rounded-full"
                />
              )}
              {!previewUrl && showGif && (
                <div
                  className=" bg-bg-main rounded-full h-[100px] relative"
                  style={{ width: "120px", height: "120px", margin: "auto" }}
                >
                  <img
                    src={loading}
                    alt="loading"
                    className="absolute top-0 left-3  h-full w-full max-w-24 max-h-24 object-scale-down"
                  />
                  <CircularLoader progress={uploadProgress} />
                </div>
              )}
            </>
          )}
          {/* {!previewUrl && (
          <div
            className="absolute z-0"
            style={{ width: "100px", margin: "auto" }}
          >
            <CircularProgressbar
              value={uploadProgress}
              // text={`${uploadProgress.toFixed(2)}%`}
              styles={buildStyles({
                strokeLinecap: "butt",
                pathColor: `rgba(251, 188, 5, ${uploadProgress / 100})`,
                textColor: "#f88",
                trailColor: "#1C454F",
                backgroundColor: "#1C454F",
              })}
            />
          </div>
        )} */}
        </label>

        <div className="text-center mt-4">
          <p className="font-semibold text-2xl leading-8 flex">
            Upload your Menu
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="ml-2"
            >
              <path
                d="M8 0.425049L9.04512 5.5536L13.1423 2.29669L10.6463 6.89718L15.8785 7.03586L11.0093 8.95567L14.9282 12.425L9.96418 10.7659L10.7362 15.9426L8 11.4808L5.26384 15.9426L6.03582 10.7659L1.0718 12.425L4.99069 8.95567L0.121538 7.03586L5.35366 6.89718L2.8577 2.29669L6.95488 5.5536L8 0.425049Z"
                fill="#EB4335"
              />
            </svg>
          </p>
          <span className="font-medium">
            (PDF Preferred, <span className="font-light">jpg & png fine</span> )
          </span>
        </div>
        <button
          disabled={!previewUrl}
          onClick={handleNext}
          className={`orange-btn text-white px-4 py-2 rounded ${
            previewUrl ? "opacity-100" : "opacity-50 cursor-not-allowed"
          }`}
        >
          Next
        </button>
      </div>
      <div className=" text-center w-full lg:min-w-[506px]">
        <p className="font-medium text-base mb-2">Boosted Happy Hour Example</p>
        <img src={menuExample} alt="" />
      </div>
    </div>
  );
};

export const Step4 = ({ formData, handleNext, handleInputChange, error }) => {
  return (
    <div className="flex flex-col gap-y-4 w-full">
      <h2 className="text-base md:text-3xl font-semibold">Do you have a website?</h2>
      <input
        className="w-full lg:min-w-[600px] bg-[#fff] h-14 text-[#141414] font-normal text-xl px-5 rounded"
        type="text"
        placeholder="yourwebsite.com"
        value={formData.website}
        onChange={(e) => handleInputChange(e, "step4", "website")}
      />
      <p className="text-center text-[#fff]">If not, no worries</p>
      <h2 className="text-base md:text-3xl font-semibold flex">
        Phone Number?
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="ml-2"
        >
          <path
            d="M8 0.425049L9.04512 5.5536L13.1423 2.29669L10.6463 6.89718L15.8785 7.03586L11.0093 8.95567L14.9282 12.425L9.96418 10.7659L10.7362 15.9426L8 11.4808L5.26384 15.9426L6.03582 10.7659L1.0718 12.425L4.99069 8.95567L0.121538 7.03586L5.35366 6.89718L2.8577 2.29669L6.95488 5.5536L8 0.425049Z"
            fill="#EB4335"
          />
        </svg>
      </h2>
      {error && (
        <p className="text-red italic text-lg font-semibold my-0 text-center">
          This field is required!
        </p>
      )}
      <input
        className="w-full lg:min-w-[600px] bg-[#fff] h-14 text-[#141414] font-normal text-xl px-5 rounded"
        type="text"
        placeholder="208-284-2841"
        value={formData.phoneNumber}
        onChange={(e) => handleInputChange(e, "step4", "phoneNumber")}
      />
      <button
        onClick={handleNext}
        className="orange-btn px-4 py-2 rounded max-w-[100px] mx-auto"
      >
        Next
      </button>
    </div>
  );
};


export const GetStartedScreen = ({ setGetStarted }) => (
  <div className="flex flex-col gap-y-10 text-white">
    <img
      src={happyhourdone}
      className="w-20 h-20 md:w-48 md:h-48 mx-auto"
      alt=""
    />
    <h2 className="text-xl md:text-3xl font-medium text-center">
      Now Lets Finalize Your Profile
    </h2>
    <button
      onClick={() => setGetStarted(true)}
      className="orange-btn w-60 h-16 font-bold mx-auto text-white px-4 py-2 rounded"
    >
      LETS DO IT!
    </button>
  </div>
);

export const Done = ({
  cityName,
  isCity = true,
  handleSubmit,
  disableButton,
  loading,
}) => (
  <div className="flex flex-col gap-y-8 text-white items-center h-full justify-center mt-20">
    <img src={done} className="w-[100px] h-[100px] mx-auto" alt="" />
    {isCity ? (
      <h2 className="text-3xl font-normal text-center">
        Your Business Is Now On <br />
        <strong> Scout & Sip!</strong>
      </h2>
    ) : (
      <h2 className="text-3xl font-normal text-center leading-[54px]">
        Thank you for Listing <br /> your business on <br />
        <strong> Scout & Sip!</strong>
      </h2>
    )}
    {!isCity && (
      <p className="font-medium text-2xl text-center text-white">
        We’re currently not serving {cityName ? `${cityName}` : `[city]`} at
        this time. <br /> We will send you an email when we do.
      </p>
    )}

    {isCity ? (
      // <Link to={"/"}>
      <LoadingButton
        className="!bg-main-orange orange-btn !w-60 !h-16 font-bold mx-auto !text-white px-4 py-2 !rounded cursor-pointer
      hover:!bg-main-orange-100"
        isLoading={loading}
        disabled={disableButton}
        onClick={handleSubmit}
      >
        View Listing
      </LoadingButton>
    ) : (
      // </Link>
      <Link to={"/request_city"}>
        <button
          // onClick={() => setGetStarted(true)}
          className="orange-btn w-60 h-16 font-bold mx-auto text-white px-4 py-2 rounded"
        >
          Request A City
        </button>
      </Link>
    )}
  </div>
);